import { useState, useEffect } from "react";
import { useUser } from "../../contexts/userContext";
import { useNavigate } from "react-router-dom";

import { login } from "../../services/auth";
import { LockSolidClosedIcon, ArrowLongRightIcon } from "../../icons";
import logo from "../../icons/gdf192.png";

import { Suspense } from 'react';
import { useTranslation} from 'react-i18next';
import Cookies from "js-cookie";

import { Icon } from 'react-icons-kit';
import { eyeOff } from 'react-icons-kit/feather/eyeOff';
import { eye } from 'react-icons-kit/feather/eye'

import { useA2HS } from '../a2hsContext';

// Landing Auth component - Login Dialog
const Landing = ({ onChangeDialog }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [type, setType] = useState('password');
  const [icon, setIcon] = useState(eyeOff);
  const [authError, setAuthError] = useState("");
  const { setUser } = useUser();
  const navigate = useNavigate();
  const { deferredPrompt, setDeferredPrompt, showInstallPrompt, setShowInstallPrompt } = useA2HS();


  // --------------------- start language matter --------------------------
  // for language
  const { t, i18n } = useTranslation();

  // Languages for the dropdown 
  // Emoji flag symbols https://apps.timwhitlock.info/emoji/tables/iso3166
  const languages = [
      { name: " \uD83C\uDDEC\uD83C\uDDE7 English", code: "en" },
      { name: "\uD83C\uDDF9\uD83C\uDDFF Kiswahili", code: "sw" },
  ];

  // for language 
  // read a cookie from the storage and accepts the name of the cookie
  const currentLocale = Cookies.get("gdf_i18next") || "en";

  // define a language variable and pass in the current locale via the useState Hook.
  const [language, setLanguage] = useState(currentLocale);

  // handleChangeLocale handler to trigger language update.
  const handleChangeLocale = (e) => {
      const lang = e.target.value;
 
      // Check if the selected language is different from the current language
      if (lang !== language) {

        // Update the cookie
        Cookies.set('gdf_i18next', lang, { 
          expires: 365,      // expires in 365 days, adjust as needed
          sameSite: 'None',  // Set SameSite attribute to 'None'
          secure: true       // Set secure to true if your site is served over HTTPS
        });

        // Update the state
        setLanguage(lang);

        // Update the i18n language
        i18n.changeLanguage(lang);
      }
    };
  // --------------------- end language matter --------------------------

  const handleLogin = async (e) => {
    e.preventDefault();
    if (email.length < 9 || password < 5) {
      //  
      // setAuthError("Email or Password is invalid");
      setAuthError(t('error_message.wrong_email_or_password'));
    } else {
      const loginResp = await login(email, password);
      if (loginResp.success) { 
        localStorage.setItem(
          process.env.REACT_APP_AUTH_STORAGE_KEY,
          JSON.stringify(loginResp.data)
        );
        setUser(loginResp.data);
        setAuthError("");
        navigate("/fe");
      } else { 
        setUser(null);
        localStorage.removeItem(process.env.REACT_APP_AUTH_STORAGE_KEY);
        if (loginResp.status === 401) {
          //setAuthError("Wrong Email or Password");
          setAuthError(t('error_message.wrong_email_or_password'));
        } else {
          //setAuthError("Login failed. Try again later.");
          setAuthError(t('error_message.login_failed_try_again'));
          // setAuthError(loginResp.status);
        }
      }
    }
  };

  const handleEmail = (e) => {
    setEmail(e.target.value);
  };

  const handlePassword = (e) => {
    setPassword(e.target.value);
  };

  // Activate Register dialog
  const handleRegister = (e) => {
    e.preventDefault();
    onChangeDialog("register");
  };

  // Activate Forgot password dialog
  const handleForgotPass = (e) => {
    e.preventDefault();
    onChangeDialog("forgot");
  };

  // Activate Terms and Conditions
  // const handleTermsAndConditions = (e) => {
  //   e.preventDefault();
  //   onChangeDialog("termsconditions");
  // }

  // Function to open the popup
  const openPrivacyPopup = () => {
      // URL of the terms and conditions page
      let termsURL = "https://www.gdf.co.tz"; // Default URL

      // Adjust the URL based on the language
      if (i18n.language === 'en') {
          // termsURL = "https://www.gdf.co.tz/terms/en.html"; // English terms and conditions page URL
          termsURL = "https://www.gdf.co.tz/privacy/en.html"; // English privacy policy page URL
      } else if (i18n.language === 'sw') {
          // termsURL = "https://www.gdf.co.tz/terms/sw.html"; // Swahili terms and conditions page URL
          termsURL = "https://www.gdf.co.tz/privacy/sw.html"; // Swahili privacy policy page URL
      }
      
      // Open a new popup window with the terms and conditions page
      window.open(termsURL, "_blank", "width=400,height=900");
  };

  const handleToggle = () => {
    if (type==='password'){
       setIcon(eye);
       setType('text')
    } else {
       setIcon(eyeOff)
       setType('password')
    }
  }

  // Add to Home Screen using pop using Swal
  const handleAddToHomeScreen = async () => {
    if (deferredPrompt) {
      deferredPrompt.prompt();
      const choiceResult = await deferredPrompt.userChoice;
      if (choiceResult.outcome === 'accepted') {
        // console.log('User accepted the A2HS prompt');
      } else {
        // console.log('User dismissed the A2HS prompt');
      }
      setDeferredPrompt(null);
      setShowInstallPrompt(false);
    }
  };

  useEffect(() => {
    const beforeInstallPromptHandler = (e) => {
      e.preventDefault();
      setDeferredPrompt(e);

      // Show the button 5 seconds after the `beforeinstallprompt` event
      setTimeout(() => {
        setShowInstallPrompt(true);
      }, 5000);
    };

    window.addEventListener('beforeinstallprompt', beforeInstallPromptHandler);

    return () => window.removeEventListener('beforeinstallprompt', beforeInstallPromptHandler);
  }, [setDeferredPrompt, setShowInstallPrompt]);

  return (
    <>
      {/* Language  */}
      <div className="w-full flex justify-center px-4 sm:px-6 lg:px-8">
        <div className="w-full max-w-md">
          <div className="flex justify-end pt-6">
            {/** start using dropdown */}
            <div>
                {/* Language switch dropdown here */}
                <label htmlFor="lang">
                  { /** Select Language */}
                </label>
                <select 
                  className="block border-gray-300 shadow-sm focus:border-slate-300 focus:ring-slate-300 sm:text-sm"
                  id="lang"
                  name="lang"
                  onChange={handleChangeLocale} 
                  value={language}
                >
                  {languages.map(({ name, code }) => (
                    <option key={code} value={code}>
                      {name}
                    </option>
                  ))}
                </select>
            </div>
            {/** end using dropdown */}
          </div>
        </div>
      </div>
      <div className="flex min-h-full items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
        <div className="w-full max-w-md space-y-8">
          {/* Header */}
          <div>
            {/* Logo */}
            <div className="flex justify-center">
              <img
                src={logo}
                width="80"
                height="80"
                className="w-[80px] h-auto"
                alt="GDF 512x512 sized logo"
              />
            </div>
            {/* Notification messages - if any */}
            <h2 className="mt-2 text-center text-3xl tracking-tight text-gray-400">
              {authError ? (
                <span className="text-red-400 text-lg">{authError}</span>
              ) : (
                <span className="text-gray-600 text-lg">
                  {/*Login if you already have an account*/}
                  {t('login_register_page.login_already_have_account')}
                </span>
              )}
            </h2>
          </div>
          {/* Form */}
          <form className="mt-8 space-y-6" action="#" method="POST">
            <input type="hidden" name="remember" defaultValue="true" />
            <div>
              <div className="flex flex-col space-y-4 rounded-md shadow-sm text-lg">
                <div>
                  <label htmlFor="email-address" className="sr-only">
                    {t('forgot_password_page.email_address')}
                  </label>
                  <input
                    id="email-address"
                    name="email"
                    type="email"
                    onChange={handleEmail}
                    autoComplete="email"
                    required
                    className="relative block w-full appearance-none rounded-none rounded-t-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-[#24478f]/30 focus:outline-none focus:ring-[#24478f]/30"
                    placeholder={t('login_register_page.enter_email_address')}
                  />
                </div>
                <div>
                  <label htmlFor="password" className="sr-only">
                    {t('manage_profile_page.password_update')}
                  </label>    
                  <div className="mb-4 flex">
                      <input
                          id="password"
                          type={type}
                          name="password"
                          placeholder={t('login_register_page.enter_password')}
                          value={password}
                          onChange={handlePassword}
                          autoComplete="current-password"
                          required
                          className="block w-full appearance-none rounded-none rounded-b-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:border-[#24478f]/30 focus:outline-none focus:ring-[#24478f]/30"
                      />
                      <span className="flex justify-around items-center" onClick={handleToggle}>
                            <Icon className="absolute mr-10" icon={icon} size={25}/>
                      </span>
                  </div>
                </div>
              </div>
              {/* Forgot password */}
              <div className="text-right pt-3 pr-[2px]">
                <span
                  onClick={handleForgotPass}
                  className="text-blue-600 font-bold block cursor-pointer"
                >
                  {/*Forgot Password?*/}
                  {t('login_register_page.forgot_password')}

                </span>
              </div>
              {/* Login Button */}
              <div className="pt-2">
                <button
                  type="submit"
                  onClick={handleLogin}
                  className="group relative flex w-full justify-center rounded-md border border-transparent bg-slate-500 py-2 px-4 text-sm font-medium text-white hover:bg-slate-600 focus:outline-none focus:ring-2 focus:ring-slate-500 focus:ring-offset-2 uppercase"
                >
                  <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                    <LockSolidClosedIcon
                      className="h-5 w-5 text-gray-500 group-hover:text-indigo-400"
                      aria-hidden="true"
                    />
                  </span>
                  {/*Login*/}
                  {t('buttons_navigation.button_login')}
                </button>
              </div>
            </div>
            <div className="space-y-2">
              <div className="p-2">
                {/*If you manage a diary farm and don't have a farm account, start
                your farm account by clicking the button below*/}
                {t('login_register_page.register_header_note')}
              </div>
              {/* Register Button */}
              <div className="pt-0">
                <button
                  type="button"
                  onClick={handleRegister}
                  className="group relative flex w-full justify-center rounded-md border border-transparent bg-slate-500 py-2 px-4 text-sm font-medium text-white hover:bg-slate-600 focus:outline-none focus:ring-2 focus:ring-slate-500 focus:ring-offset-2 uppercase"
                >
                  <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                    <ArrowLongRightIcon
                      className="h-5 w-5 text-white group-hover:text-indigo-400"
                      aria-hidden="true"
                    />
                  </span>
                  {/*Start a farm account*/}
                  {t('buttons_navigation.button_nav_register_farm')}
                </button>
              </div>
            </div>
          </form>
          {showInstallPrompt && deferredPrompt && (
              <div className="pt-0">
                  <div className="text-gray-600  text-s p-0">
                   {t('user_handle_notice.a2hs_notice')}
                  </div>
                  <button
                      type="button"
                      onClick={handleAddToHomeScreen}
                      className="group relative flex w-full justify-center rounded-md border border-transparent bg-slate-500 py-2 px-4 text-sm font-medium text-white hover:bg-slate-600 focus:outline-none focus:ring-2 focus:ring-slate-500 focus:ring-offset-2 uppercase"
                  >
                      <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                          <ArrowLongRightIcon
                              className="h-5 w-5 text-white group-hover:text-indigo-400"
                              aria-hidden="true"
                          />
                      </span>
                      {t('user_handle_notice.a2hs_add_home_screen_button')}
                  </button>
              </div>
          )}
          <div className="text-gray-600  text-xs">
            {t('terms_and_conditions.login_page_footer')}{' '}
            <span
              onClick={openPrivacyPopup}
              className="text-blue-600 font-bold cursor-pointer text-xs"
            >
              {t('terms_and_conditions.privacy_policy')}
            </span>
          </div>
        </div>
      </div>
    </>
  );
};


export default function WrappedApp() {
  return (
    <Suspense fallback="...loading">
      <Landing />
    </Suspense>
  )
}

export { Landing };
