import React from "react";

const TabNavItem = ({ id, title, activeTab, setActiveTab, handleChange }) => {
  const handleClick = () => {
    setActiveTab(id);
    handleChange(title, id); // You might want to pass the id to the handleChange function
  };

  return (
    <li onClick={handleClick} className={activeTab === id ? "active" : ""}>
      {title}
    </li>
  );
};

export default TabNavItem;


