import { Link } from "react-router-dom";

export const MenuItem = (props) => {

    return (
        <Link to={props.urlSegment}>
            <div className="bg-blue-200 rounded-sm h-[100px] w-[172px] md:h-28 md:w-48 flex flex-col justify-center items-center shadow-lg">
                {props.children}
                <span className="text-sm uppercase mt-2">{props.itemTitle}</span>
            </div>
        </Link>
    );
}