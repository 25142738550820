export const getCategoryMapping = (t) => ({
    pregnant: t('market_place_page.pregnant_cows_category'),
    openHeifer: t('market_place_page.open_heifer_category'),
    cowAndCalve: t('market_place_page.cow_calve_category'),
    calve: t('market_place_page.calve_category'),
    bull: t('market_place_page.bull_category'),
    slaughterCattle: t('market_place_page.slaughter_cattle_category'),
    storeCattle: t('market_place_page.store_cattle_category')
});


export const getBreedMapping = (t) => ({
    friesian:   t('market_place_page.mapping_friesian'),
    jersey:     t('market_place_page.mapping_jersey'),
    hybrid:     t('market_place_page.mapping_hybrid'),
    ayrshire:   t('market_place_page.mapping_ayrshire'),
    fleckvieh:  t('market_place_page.mapping_fleckvieh'),
    brownSwiss: t('market_place_page.mapping_brown_swiss'),
    guernsey:   t('market_place_page.mapping_guernsey') 
});


export const getCurrencyMapping = (t) => ({
    tshs: t('market_place_page.mapping_tshs'),
    ushs: t('market_place_page.mapping_ushs'),
    kshs: t('market_place_page.mapping_kshs'),
    zar:  t('market_place_page.mapping_rand'),
    usd:  t('market_place_page.mapping_usd'),
    zmw:  t('market_place_page.mapping_kwacha'),
});

export const getVaccinationHistoryMapping = (t) => ({
    "1": t('market_place_page.advertise_face_available'),
    '0': t('market_place_page.advertise_face_unavailable')
});

export const getCategoryMappingRequests = (t) => ({
    machine:    t('market_place_page.mapping_machine'),
    medicine:   t('market_place_page.mapping_medicine'),
    fodder:     t('market_place_page.mapping_fodder'),
    veterinary: t('market_place_page.mapping_veterinary'),
    cattle:     t('market_place_page.mapping_cattle'),
    seed:       t('market_place_page.mapping_seed'),
    worker:     t('market_place_page.mapping_worker'),
    other:      t('market_place_page.mapping_other')
});



