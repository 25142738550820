import { useRef } from "react";

export const useEnaDisableStyle = () => {

  // Doesnt seem possible to dynamically change parts of a class e.g replacing text-sm with text-lg
  const inputStyle = useRef({
      enabledInput: "mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-slate-500 focus:ring-slate-500 sm:text-sm",
      disabledInput: "mt-1 block w-full rounded-md border-gray-200 shadow-sm sm:text-sm text-gray-400"
  });
    
  return inputStyle;
}

