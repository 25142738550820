// Generic page not found
import { Link } from "react-router-dom";
import { HomeIcon, InfoTriangleIcon } from "../icons";

import { useTranslation } from "react-i18next";

export const NotFound = () => {

    // for language translation
    const { t } = useTranslation();

    return (
        <div className="mt-[100px] mb-[100px]">
            <div className="h-[300px] w-full flex flex-col justify-center items-center">
                <InfoTriangleIcon />
                <span className="text-lg text-gray-700">{t('error_message.oops_embarassing')}</span>
                <span className="text-bold text-gray-400">{t('error_message.resource_not_found')}</span>
                <div className="mt-4 text-slate-500">
                    <Link to="/fe">
                        <HomeIcon />
                    </Link>
                </div>
            </div>
        </div>
    );
}