import { TextInput, InputLabel, SelectInput, TextareaInput, Footer, NumberInput } from '../components';
import { useEnaDisableStyle, useErrorParser, useEvents, useHandleSubmit } from '../hooks';
import { TRANS_TYPE_LIST_CATTLE_4_SALE } from '../constants';
import { toast, ToastContainer } from 'react-toastify';
import { createResource } from '../services/farmApi';
import React, { useState, useEffect } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { useTranslation } from "react-i18next";
import { useUser, useEnv } from '../contexts';

const SaleForm = () => {

    // for language translation
    const { t } = useTranslation();
    const {envState, setEnvState } = useEnv(); // Get Environment context

    const { user } = useUser();  // Get currently loggedIn user
    const textInputStyle = useEnaDisableStyle();
    const showFieldErrors = useErrorParser(); // Takes in array off validation errors and returns jsx.
    const [responseErrors, setResponseErrors] = useState({});
    const [createNotice, setCreateNotice] = useState({showNotice: false, succ: false, msg: ""});
    const { publish } = useEvents(); // publish - dispatches events
    const { handleSubmit } = useHandleSubmit(); // Shared submit handler

    // const [fileBytesSize, setFileBytesSize] = useState(0);
    // const [fileName, setFileName] = useState('');
    // const [fileType, setFileType] = useState('');
    const [file, setFile] = useState(null);
    const [loading, setLoading] = useState(false); // Loading state
    const [errorMessage, setErrorMessage] = useState(null); // Error message state



    // ------------------ start refactor -------------------------
    const [initFormState, setInitFormState] = useState(null);
    const resetFormState = () => { setFormState(initFormState) }

    // Setting initial form state in useEffect
    useEffect(() => {
        const initFormState = {
            trans_type: TRANS_TYPE_LIST_CATTLE_4_SALE, // listing
            category: '',
            cattleAge: '',
            breed: '',
            currency: 'tshs',
            price: '',
            region: '',
            vaccinationRecord: '',
            averageMilk: '',
            calvingNumber: '',
            pregnancyAge: '',
            description: '',
            image_base: null,
            media_url: process.env.REACT_APP_MEDIA_UPLOAD,
            media_file_name: '',
            media_file_type: '',
            media_bytes_size: 0,
            media_type: '',
            file_format: '',
            user_id: user.id, // assuming user is defined in the scope
        };
        setInitFormState(initFormState);
        setFormState(initFormState); // Set formState when initFormState is set
    }, []);

    const [formState, setFormState] = useState({
        trans_type: TRANS_TYPE_LIST_CATTLE_4_SALE, // listing
        category: '',
        cattleAge: '',
        breed: '',
        currency: 'tshs',
        price: '',
        region: '',
        vaccinationRecord: '',
        averageMilk: '',
        calvingNumber: '',
        pregnancyAge: '',
        description: '',
        image_base: null,
        media_url: process.env.REACT_APP_MEDIA_UPLOAD,
        media_file_name: '',
        media_file_type: '',
        media_bytes_size: 0,
        media_type: '',
        file_format: '',
        user_id: user.id,  // Assuming `user.id` is defined
    });

    const handleChange = (inputKey, inputValue) => {

        if (inputKey === 'image') {
            const selectedFile = inputValue; // Safely get the first file
    
            if (selectedFile) {
                // Set file details in the form state
                setFile(selectedFile);

                const mediaFileName = selectedFile.name;
                const mediaFileType = selectedFile.type;    // e.g., 'image/jpeg'
                const mediaFileBytesSize = selectedFile.size;

                // Split fileType into mediaType and fileFormat
                const [mediaType, fileFormat] = mediaFileType.split('/'); // 'image', 'jpeg'


                // Update the formState immediately with file details
                setFormState((prevState) => ({
                    ...prevState,
                    media_file_name: mediaFileName,       // Set file name in form state
                    media_file_type: mediaFileType,       // Set file type in form state
                    media_bytes_size: mediaFileBytesSize, // Set file size in form state
                    media_type: mediaType,           // Set media type (e.g., 'image')
                    file_format: fileFormat,         // Set file format (e.g., 'jpeg')
                }));

    
                // Convert the file to base64
                const reader = new FileReader();
                reader.onloadend = () => {
                    const base64Image = reader.result; // Get base64 image/video
    
                    // Update formState with base64 image/video and set image selected status
                    setFormState((prevState) => ({
                        ...prevState,
                        image_base: base64Image,  // Store base64 string in formState
                        image: true,               // Indicate an image has been selected
                    }));
                };
                reader.readAsDataURL(selectedFile);  // Trigger base64 conversion

            } else {
                // No file selected; update the state accordingly
                setFormState((prevState) => ({
                    ...prevState,
                    image_base: '',  // Clear base64 string if no file selected
                    image: false,    // Indicate no image is selected
                }));
            }
        } else {
            // For non-file inputs, keep the current behavior
            setFormState((prevState) => ({
                ...prevState,
                [inputKey]: inputValue,
            }));
    
            // Clear the corresponding error message if the user is typing again
            setResponseErrors((prevErrors) => ({
                ...prevErrors,
                [inputKey]: [], // Clear the specific error when the user changes the input
            }));
        }
    };
    
    // this does not fire and therefore formState is not set when I select image
    useEffect(() => {
    }, [formState]); // This will log formState whenever it changes
    

    //------------------ end refactor -----------------------------
 
    // handleSubmit for backend persistent storage
    const submitListForm = async (e) => {
        e.preventDefault();

        // Clear previous errors before validation
        setResponseErrors({});
    
        // Validation Logic

        // Validate cattle age (should not be empty and must be <= 20)
        if (formState.cattleAge === '' || formState.cattleAge > 20) {
            setResponseErrors({ cattleAge: [t('market_place_page.invalid_cattle_age')] });
            return;
        }

        // Validate breed (should not be empty)
        if (formState.breed === '') {
            setResponseErrors({ breed: [t('market_place_page.select_breed')] });
            return;
        }

        // Validate price (should not be empty, zero, or exceed 20,000,000)
        if (formState.price === '' || formState.price <= 0 || formState.price > 20000000) {
            setResponseErrors({ price: [t('market_place_page.invalid_price')]  });
            return;
        }

        // Validate region (should not be empty)
        if (formState.region === '') {
            setResponseErrors({ region: [t('market_place_page.select_region')] });
            return;
        }

        // Validate vaccination record (should not be empty)
        if (formState.vaccinationRecord === '') {
            setResponseErrors({ vaccinationRecord: [t('market_place_page.input_vaccination_status')] });
            return;
        }

        // Only validate `averageMilk` if category is 'pregnant', 'openHeifer', or 'cowAndCalve'
        if (['pregnant', 'openHeifer', 'cowAndCalve'].includes(formState.category)) {
            if (formState.averageMilk === '' || formState.averageMilk < 0 || formState.averageMilk > 100) {
                setResponseErrors({ averageMilk: [t('market_place_page.invalid_average_milk')] });
                return;
            }
        }

        // Only validate `calvingNumber` if category is 'pregnant', 'openHeifer', or 'cowAndCalve'
        if (['pregnant', 'openHeifer', 'cowAndCalve'].includes(formState.category)) {
            if (formState.calvingNumber === '') {
                setResponseErrors({ calvingNumber: [t('market_place_page.input_calving_number')] });
                return;
            }
        }

        // Only validate `pregnancyAge` if category is 'pregnant'
        if (formState.category === 'pregnant') {
            if (formState.pregnancyAge === '' || formState.pregnancyAge > 10) {
                setResponseErrors({ pregnancyAge: [t('market_place_page.input_pregnancy_age')] });
                return;
            }
        }

        // Validate image (should not be empty, file must be selected)
        if (!formState.image) { // This should reflect the image selection status
            setResponseErrors({ image: [t('market_place_page.select_image_video')] });
            return;
        }

        // Validate image (base64) in the formState
        const { image_base } = formState;
        if (!image_base) {
            toast.error(t('market_place_page.cattle_picture_video'));
            return;
        }

        submitForm(e, formState);
   
    };

    // Handle media upload
    // const handleMediaUpload = async () => {
    //     if (file) {
    //         // setStatus('uploading');
    //         setLoading(true);  // Start loading

    //         const formData = new FormData();
    //         formData.append('file', file);  

    //         try {
    //             // Upload to your server
    //             const result = await fetch(formState.media_url, {
    //                 method: 'POST',
    //                 body: formData,
    //             });

    //             const data = await result.json();
    //             // setStatus('success');
    //             setLoading(false); // Stop loading

    //             return true;  // Return success
    //         } catch (error) {
    //             // console.error(error);
    //             // setStatus('fail');
    //             setLoading(false);
    //             setErrorMessage(t('market_place_page.failed_upload'));
    //             return false;  // Return failure
    //         }
    //     }else {
    //         // Set status to initial if no file is present
    //         // setStatus('initial');
    //         return false; // No file to upload
    //     }
    // };

    // Submit form with necessary parameters
    const submitForm = (e, formState) => {
        e.preventDefault(); // Prevent form from reloading the page

        // Submit form using handleSubmit, passing formState and necessary parameters
        handleSubmit(
            e,
            formState,  // pass formState with base64 and media details
            createResource,
            envState.online,
            process.env.REACT_APP_MARKET_PLACE,
            setResponseErrors,
            setCreateNotice,
            resetFormState,
            publish,
            t
        );

        // Display success message and reset form
        toast.success(t('market_place_page.item_for_sale_added'));
    };
        

    return (
        <div className="container mt-2">
            <div className="mb-2"></div>
            <div>Add cattle you want to list for sale</div>

            {/* <form onSubmit={handleSubmit}> */}
            <form onSubmit={submitListForm} noValidate>
                {/* Category - Select */}
                <div className="col-span-6 sm:col-span-3 lg:col-span-2 mt-2">
                    <InputLabel
                        inputId="category"
                        inputStyle="block text-sm font-medium text-gray-700"
                        labelText={t('market_place_page.advertise_face_select_category')}
                    />
                    <SelectInput
                        focus={false}
                        inputName="category"
                        inputId="category"
                        inputStyle={textInputStyle.current.enabledInput}
                        inputValue={formState?.category}
                        handleChange={handleChange}

                        choices={[
                            {key: '', label: t('market_place_page.advertise_face_select_category')},
                            {key: 'pregnant', label: t('market_place_page.pregnant_cows_category') },
                            {key: 'openHeifer', label: t('market_place_page.open_heifer_category') },
                            {key: 'cowAndCalve', label: t('market_place_page.cow_calve_category') },
                            {key: 'calve', label: t('market_place_page.calve_category') },
                            {key: 'bull', label:  t('market_place_page.bull_category') },
                            {key: 'slaughterCattle', label: t('market_place_page.slaughter_cattle_category') },
                            {key: 'storeCattle', label: t('market_place_page.store_cattle__category') },
                        ]}
                    />
                    {showFieldErrors(responseErrors.category)}
                </div>

                {/* Only show remaining fields when category is selected */}
                {formState?.category && (
                    <>
                        {/* Cattle Age Input */}
                        <div className="col-span-6 sm:col-span-3 lg:col-span-2 mt-2">
                            <InputLabel
                                inputId="cattleAge"
                                inputStyle="block text-sm font-medium text-gray-700"
                                labelText={t('market_place_page.advertise_face_cattle_age')}
                            />
                            <NumberInput
                                focus={false}
                                inputName="cattleAge"
                                inputId="cattleAge"
                                hint="1.5"
                                // No validation here, allow empty input or any value
                                isDisabled={false}
                                inputStyle={textInputStyle.current.enabledInput}
                                inputValue={formState?.cattleAge}
                                inputStep="1"
                                handleChange={setFormState}
                            />
                            {showFieldErrors(responseErrors.cattleAge)}
                        </div>

                        {/* Breed - Select */}
                        <div className="col-span-6 sm:col-span-3 lg:col-span-2 mt-2">
                            <InputLabel
                                inputId="breed"
                                inputStyle="block text-sm font-medium text-gray-700"
                                labelText={t('market_place_page.advertise_face_specify_breed')}
                            />
                            <SelectInput
                                focus={false}
                                inputName="breed"
                                inputId="breed"
                                inputValue={formState?.breed}
                                inputStyle={textInputStyle.current.enabledInput}
                                handleChange={handleChange}

                                choices={[
                                    {key: '', label: t('market_place_page.advertise_face_specify_breed') },
                                    {key: 'friesian', label: t('market_place_page.mapping_friesian') },
                                    {key: 'jersey', label: t('market_place_page.mapping_jersey') },
                                    {key: 'hybrid', label: t('market_place_page.mapping_hybrid') },
                                    {key: 'ayrshire', label: t('market_place_page.mapping_ayrshire') },
                                    {key: 'fleckvieh', label: t('market_place_page.mapping_fleckvieh') },
                                    {key: 'brownSwiss', label: t('market_place_page.mapping_brown_swiss') },
                                    {key: 'guernsey', label: t('market_place_page.mapping_guernsey') },
                                ]}
                            />
                            {showFieldErrors(responseErrors.breed)}
                        </div>

                        {(formState?.category === 'pregnant' || formState?.category === 'openHeifer' || formState?.category === 'cowAndCalve') && (
                            <>
                                {/* Average milk Input */}
                                <div className="col-span-6 sm:col-span-3 lg:col-span-2 mt-2">
                                {/* <div className="flex space-x-4 mb-4" role="group" aria-label="averageMilk"> */}
                                    <InputLabel
                                        inputId="averageMilk"
                                        inputStyle="block text-sm font-medium text-gray-700"
                                        labelText={t('market_place_page.advertise_face_average_milk_litres')}
                                    />
                                    <NumberInput
                                        focus={false}
                                        inputName="averageMilk"
                                        inputId="averageMilk"
                                        hint="15.5"
                                        // isRequired={false}
                                        isDisabled={false}
                                        inputStyle={textInputStyle.current.enabledInput}
                                        inputValue={formState?.averageMilk}
                                        inputStep="0.01"
                                        handleChange={setFormState}
                                    />
                                    {showFieldErrors(responseErrors.averageMilk)}
                                </div>

                                {/* Calving number Input */}
                                <div className="col-span-6 sm:col-span-3 lg:col-span-2 mt-2">
                                {/* <div className="flex space-x-4 mb-4" role="group" aria-label="calvingNumber">    */}
                                    <InputLabel
                                        inputId="calvingNumber"
                                        inputStyle="block text-sm font-medium text-gray-700"
                                        labelText={t('market_place_page.advertise_face_calving_number')}
                                    />
                                    <NumberInput
                                        focus={false}
                                        inputName="calvingNumber"
                                        inputId="calvingNumber"
                                        hint="2"
                                        // isRequired={false}
                                        isDisabled={false}
                                        inputStyle={textInputStyle.current.enabledInput}
                                        inputValue={formState?.calvingNumber}
                                        inputStep="1"
                                        handleChange={setFormState}
                                    />
                                    {showFieldErrors(responseErrors.calvingNumber)}
                                </div>
                            </>
                        )}

                        {formState?.category === 'pregnant' && (
                            <>
                                {/* Pregnancy Age Input */}
                                <div className="col-span-6 sm:col-span-3 lg:col-span-2 mt-2">
                                    <InputLabel
                                        inputId="pregnancyAge"
                                        inputStyle="block text-sm font-medium text-gray-700"
                                        labelText={t('market_place_page.advertise_face_pregnancy_age')}
                                    />
                                    <NumberInput
                                        focus={false}
                                        inputName="pregnancyAge"
                                        inputId="pregnancyAge"
                                        hint="5"
                                        // isRequired={false}
                                        isDisabled={false}
                                        inputStyle={textInputStyle.current.enabledInput}
                                        inputValue={formState?.pregnancyAge}
                                        inputStep="1"
                                        handleChange={setFormState}
                                    />
                                    {showFieldErrors(responseErrors.pregnancyAge)}
                                </div>
                            </>
                        )}
                        {/* Currency Select */}
                        <div className="col-span-6 sm:col-span-3 lg:col-span-2 mt-2">
                            <InputLabel
                                inputId="currency"
                                inputStyle="block text-sm font-medium text-gray-700"
                                labelText={t('market_place_page.advertise_face_select_currency')}
                            />
                            <SelectInput
                                focus={false}
                                inputName="currency"
                                inputId="currency"
                                inputValue={formState?.currency}
                                inputStyle={textInputStyle.current.enabledInput}
                                handleChange={handleChange}

                                choices={[
                                    {key: 'tshs', label: t('market_place_page.mapping_tshs') },
                                    {key: 'ushs', label: t('market_place_page.mapping_ushs') },
                                    {key: 'kshs', label: t('market_place_page.mapping_kshs') },
                                    {key: 'zar', label: t('market_place_page.mapping_rand') },
                                    {key: 'usd', label: t('market_place_page.mapping_usd') },
                                    {key: 'zmw', label: t('market_place_page.mapping_kwacha') },
                                ]}
                            />
                            {showFieldErrors(responseErrors.currency)}
                        </div>            

                        {/* Price Input */}
                        <div className="col-span-6 sm:col-span-3 lg:col-span-2 mt-2">
                        {/* <div className="flex space-x-4 mb-4" role="group" aria-label="price"> */}
                            <InputLabel
                                inputId="price"
                                inputStyle="block text-sm font-medium text-gray-700"
                                labelText={t('market_place_page.advertise_face_cattle_price')}
                            />
                            <NumberInput
                                focus={false}
                                inputName="price"
                                inputId="price"
                                hint="1200000"
                                // isRequired={false}
                                isDisabled={false}
                                inputStyle={textInputStyle.current.enabledInput}
                                inputValue={formState?.price}
                                inputStep="0"
                                handleChange={setFormState}
                            />
                            {showFieldErrors(responseErrors.price)}
                        </div>

                        {/* Region name Input */}
                        <div className="col-span-6 sm:col-span-3 lg:col-span-2 mt-2">
                            <InputLabel
                                inputId="region"
                                inputStyle="block text-sm font-medium text-gray-700"
                                labelText={t('market_place_page.advertise_face_location')}
                            />
                            <TextInput
                                focus={true}
                                inputType="text"
                                inputName="region"
                                inputId="region"
                                hint={t('market_place_page.hint_enter_location')}
                                // patternInfo={t('cattle_page.cattle_tag_pattern_info')}
                                inputStyle={textInputStyle.current.enabledInput}
                                inputValue={formState?.region}
                                handleChange={handleChange} 
                            />
                            {showFieldErrors(responseErrors.region)}
                        </div>

                        {/* Vaccination record - Select */}
                        <div className="col-span-6 sm:col-span-3 lg:col-span-2 mt-2">
                        {/* <div className="flex space-x-4 mb-4" role="group" aria-label="vaccinationRecord"> */}
                            <InputLabel
                                inputId="breed"
                                inputStyle="block text-sm font-medium text-gray-700"
                                labelText={t('market_place_page.advertise_face_vaccination_record')}
                            />                         
                            <SelectInput
                                focus={false}
                                inputName="vaccinationRecord"
                                inputId="vaccinationRecord"
                                inputValue={formState?.vaccinationRecord}
                                inputStyle={textInputStyle.current.enabledInput}
                                handleChange={handleChange}

                                choices={[
                                    {key: '', label: t('market_place_page.advertise_face_vaccination_record') },
                                    {key: '1', label: t('market_place_page.advertise_face_available') },
                                    {key: '0', label: t('market_place_page.advertise_face_unavailable') },
                                ]}
                            />
                            {showFieldErrors(responseErrors.vaccinationRecord)}
                        </div>

                        {/* Image upload */}
                        <div className="col-span-6 sm:col-span-3 lg:col-span-2 mt-2">
                            <InputLabel
                                inputId="image"
                                inputStyle="block text-sm font-medium text-gray-700"
                                labelText={t('market_place_page.advertise_face_picture_upload_title')}
                            />
                            <TextInput
                                focus={false}
                                inputType="file"
                                inputName="image"
                                inputId="image"
                                accept="image/*,video/*"
                                inputStyle={textInputStyle.current.enabledInput}
                                handleChange={handleChange} // Use handleChange for form state management
                            />
                            {showFieldErrors(responseErrors.image)}
                        </div>

                        {/* Indicate the media upload status */}
                        {/* <div><Result status={status} /></div> */}
                        {loading && <div>{t('validation_messages.loading_products')}</div>}
                        {errorMessage && <div className="text-red-500">{errorMessage}</div>}




                        {/* Description input */}
                        <div className="col-span-6 mt-2">
                        {/* <div className="flex space-x-4 mb-4" role="group" aria-label="description"> */}
                            <InputLabel
                                inputId="description"
                                inputStyle="block text-sm font-medium text-gray-700"
                                labelText={t('market_place_page.advertise_face_description')}
                            />
                            <TextareaInput
                                focus={false}
                                inputName="description"
                                inputId="description"
                                hint={t('market_place_page.advertise_face_description_tip')} 
                                isRequired={false}
                                inputRows={4}
                                inputStyle={textInputStyle.current.enabledInput}
                                inputValue={formState?.description}
                                maxLength={512}
                                handleChange={handleChange}
                            />
                            {showFieldErrors(responseErrors.description)}
                        </div> 

                        {/* <button type="submit" className="btn btn-primary mt-3">Submit</button> */}
                        {/* <Footer respNotice={createNotice} setRespNotice={setCreateNotice} footerType="create"/> */}
                        <Footer respNotice={''} setRespNotice={''} footerType="submit"/>
                    </>
                )}

            </form>

            <ToastContainer />
        </div>
    );

    };

export { SaleForm };

