import { useUser } from "../contexts/userContext";
import { Login } from "./auth/Login";

const ProtectRoute = ({ children }) => {
    const { user } = useUser();
    if (user) {
        return children;
    }
    return <Login />
}

export { ProtectRoute };