import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useUser, useEnv } from '../contexts';
import { useTranslation } from "react-i18next";
import { QUERY_FILTER_BOARD_STATUS } from '../constants';
import { getResource } from '../services/farmApi';
import { useHandleSubmit } from '../hooks';
import { ConsoleLogEvent } from '../utils';

const ProductNavbar = () => {

    const { t } = useTranslation();
    const { user } = useUser();  // Get currently loggedIn user
    const { envState, setEnvState } = useEnv(); // Get Environment context
    const [loading, setLoading] = useState(false); // Loading state
    const [reportData, setReportData] = useState([]);
    const [createNotice, setCreateNotice] = useState({ showNotice: false, succ: false, msg: "" });
    const [errorMessage, setErrorMessage] = useState(null); // Error message state
    const { handleGetResource } = useHandleSubmit(); // Shared submit handler
    const [boardStatus, setBoardStatus] = useState(false);

    const navigate = useNavigate(); // Initialize useNavigate hook
    const reloadMarketPlace = () => {
        navigate('', { replace: true }); // Navigate to 'marketplace' without nesting
    };

    // Check whether to show Board button on not.
    // Board button is shown only when a user has listed cattle or item for sale 
    // The purpose of the board is to show user people interested in buying or supplying 
    // User can then navigate to the board and directly contact those individuals as may want.
    
    // Fetch data and update reportData
    useEffect(() => {
        setBoardStatus(false);
        getBoardStatus();
    }, [envState.online]);

    const getBoardStatus = async () => {
        setLoading(true);  // Start loading

        const reportQuery = "/?filter=" + QUERY_FILTER_BOARD_STATUS;

        try {
            handleGetResource(
                null,
                getResource,
                envState.online,
                process.env.REACT_APP_MARKET_PLACE + reportQuery,
                setCreateNotice,
                null,
                setReportData, // This will update reportData
                null,
                false
            );
            setErrorMessage(null); // Clear any previous errors
        } catch (error) {
            // console.error('Error fetching report data:', error);
            setErrorMessage(t('error_message.failed_to_fetch'));
        } finally {
            setLoading(false); // Stop loading
        }
    };

    useEffect(() => {
        ConsoleLogEvent('info', 'report data to give me board status:', reportData);
    
        const returnedRecords = Array.isArray(reportData.returned_records) ? reportData.returned_records : [];
        setBoardStatus(returnedRecords[0] === '1'); // Set boardStatus based on first record
    
    }, [reportData]);
    
    return (
        <nav>
            {/* Container for all buttons */}

            <div className="overflow-hidden sm:rounded-md">
                <div className="col-span-6 lg:col-span-3">
                    <div className="w-full flex justify-center">

                        <div className="flex flex-wrap justify-start mb-2">

                            <button
                                className="max-w-[94px] mb-2 mr-2 inline-flex justify-center rounded-md border border-transparent bg-slate-600 py-2 px-4 text-xs font-semibold text-gray-200 shadow-sm hover:bg-slate-500 focus:outline-none focus:ring-2 focus:ring-slate-500 focus:ring-offset-2 uppercase whitespace-nowrap"
                                onClick={reloadMarketPlace}
                            >
                                {t('market_place_page.for_sale')}  {/* Show cattle that are for sale */}
                            </button>

                            <button
                                className="max-w-[94px] mb-2 mr-2 inline-flex justify-center rounded-md border border-transparent bg-slate-600 py-2 px-4 text-xs font-semibold text-gray-200 shadow-sm hover:bg-slate-500 focus:outline-none focus:ring-2 focus:ring-slate-500 focus:ring-offset-2 uppercase whitespace-nowrap"
                                onClick={() => navigate('supply-item')}
                            >
                                {t('market_place_page.needed')}   {/* Check things listed as wanted for you to supply */}
                            </button>  

                            <button
                                className="max-w-[94px] mb-2  mr-2 inline-flex justify-center rounded-md border border-transparent bg-slate-600 py-2 px-4 text-xs font-semibold text-gray-200 shadow-sm hover:bg-slate-500 focus:outline-none focus:ring-2 focus:ring-slate-500 focus:ring-offset-2 uppercase whitespace-nowrap"
                                onClick={() => navigate('close-deal')}
                            >
                                {t('market_place_page.manage')}  {/* View your own cattle or an item you listed to remove from listing - provide some information about the buyer or supplier */}
                            </button>                                                  

                            {boardStatus && (
                                <button
                                    className="max-w-[94px] mb-2 mr-2 inline-flex justify-center rounded-md border border-transparent bg-slate-600 py-2 px-4 text-xs font-semibold text-gray-200 shadow-sm hover:bg-slate-500 focus:outline-none focus:ring-2 focus:ring-slate-500 focus:ring-offset-2 uppercase whitespace-nowrap"
                                    onClick={() => navigate('open-board')} // Adjust navigation path as needed
                                >
                                    {t('market_place_page.board')}
                                </button>
                            )}

                            <button
                                className="max-w-[94px] mb-2 mr-2 inline-flex justify-center rounded-md border border-transparent bg-slate-600 py-2 px-4 text-xs font-semibold text-gray-200 shadow-sm hover:bg-slate-500 focus:outline-none focus:ring-2 focus:ring-slate-500 focus:ring-offset-2 uppercase whitespace-nowrap"
                                onClick={() => navigate('add-product')}
                            >
                                {t('market_place_page.advertise')}  {/* Add cattle that you want to be listed for sale */}
                            </button>                

                            <button
                                className="max-w-[94px] mb-2 mr-2 inline-flex justify-center rounded-md border border-transparent bg-slate-600 py-2 px-4 text-xs font-semibold text-gray-200 shadow-sm hover:bg-slate-500 focus:outline-none focus:ring-2 focus:ring-slate-500 focus:ring-offset-2 uppercase whitespace-nowrap"
                                onClick={() => navigate('request-buy')}
                            >
                                {t('market_place_page.request')}  {/* Add an item description + pic if available of what you are looking for */}
                            </button>

                        </div>

                    </div>
                </div>
            </div>

        </nav>
    );
    
};

export { ProductNavbar };