// This is a generic form select input
// The idea is to use this wherever a select input is required
// V2 uses choice keys as values
export const SelectInputV2 = (props) => {
    
    const options = props.choices.map(choice => <option key={choice[0]} value={choice[0]} >{choice[1]}</option>);
    
    return (
        <select
            autoFocus={props.focus}
            name={props.inputName}
            id={props.inputId}
            autoComplete="off"
            className={props.inputStyle}
            value={props.inputValue}
            required={props.isRequired ?? true}
            disabled={props.isDisabled ?? false}
            onChange={(e) => { props.handleChange(props.inputName, e.target.value) }}
        >
        {options}

      </select>
    );
}