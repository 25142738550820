// User registration or Edit page
import { TextInput, RadioInput, InputLabel, Footer } from '../components'
import { useErrorParser, useEvents, useHandleSubmit, useEnaDisableStyle } from '../hooks';
import { useState, useRef, useLayoutEffect, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { useUser, useEnv } from '../contexts';
import { register } from '../services/auth';

import PhoneInput from 'react-phone-number-input';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import 'react-phone-number-input/style.css';

import { Icon } from 'react-icons-kit';
import { eye } from 'react-icons-kit/feather/eye';
import { eyeOff } from 'react-icons-kit/feather/eyeOff';

export const UserForm = (props) => {

    // for language translation
    const { t } = useTranslation();

    const { user } = useUser();  // Get currently loggedIn user
    const { envState, setEnvState } = useEnv(); // Get Environment context
    const { publish } = useEvents(); // publish - dispatches events
    const { handleSubmit } = useHandleSubmit(); // Shared submit handler
    const [phoneValue, setPhoneValue] = useState("");
    const [countryCode, setCountryCode] = useState("");
    const [countryName, setCountryName] = useState("");
    const [phoneError, setPhoneError] = useState(null);
    const textInputStyle = useEnaDisableStyle();

    const [ password, setPassword ] = useState("");
    const [type, setType] = useState('password');
    const [icon, setIcon] = useState(eyeOff);

    const handlePhoneChange = (value) => {

        setPhoneValue(value);
        setPhoneError(null);
        setResponseErrors('');

        if (!value) {
            setPhoneError(t('error_message.phone_number_required')); 
            return;
        }
        
        try {
            const phoneNumber = parsePhoneNumberFromString(value);
    
            // Check if the phone number is complete and valid before using it
            if (phoneNumber && phoneNumber.isPossible() && phoneNumber.isValid()) {

                setCountryCode(phoneNumber.countryCallingCode);
                setCountryName(phoneNumber.country);                

            } else {
                setPhoneError(t('error_message.incorrect_invalid_number')); 
                // reset phone value
                setPhoneValue('');
            }
        } catch (error) {
            // Handle the parsing error
            console.error("Error parsing phone number:", error.message);
        }

    };
    
// --------------------- start deciding how to handle queued record if any -------------------

    const [queuedRecord, setFarmRecord] = useState(null);
    const [initFormState, setInitFormState] = useState(null);

    useEffect(() => {
        // Retrieve record from local storage using the special key if was a failed queue
        // const queuedFarmRecord = localStorage.getItem('queuedFarmRecord');
        const queuedFarmRecord = localStorage.getItem(`queuedFarmRecord_${user.id}`);
        
        if (queuedFarmRecord) {
            const parsedFarmRecord = JSON.parse(queuedFarmRecord);
            setFarmRecord(parsedFarmRecord);

            // Delete the special key and its corresponding data from local storage
            // localStorage.removeItem('queuedFarmRecord');
            localStorage.removeItem(`queuedFarmRecord_${user.id}`);
            
        }
    }, []);


    useEffect(() => {
        if (queuedRecord) {
            // If queueRecord exists, initialize initFormState with its data
            setInitFormState({ ...queuedRecord, user_id: user.id });

        } else {
            // If queueRecord is null, initialize initFormState with default values
            setInitFormState({
              first_name: '',
              middle_name: '',
              last_name: '',
              email: '',
              password: '',
              user_contact_number: '',
              role: 'ops',
              active: 1, // 0 - disabled, 1 - enabled (default)
              lang: 1, // 0 - english, 1 - swahili
              user_id: user.id,
            });
        }
    }, [queuedRecord, user.id]);

    // ensuring initFormState is initialized before rendering a page
    useEffect(() => {
          if (initFormState !== null) {
            setFormState(initFormState);
          }
    }, [initFormState]);

    // Log the updated values after they are set
    useEffect(() => {
        if (phoneValue && countryCode && countryName) {
  
            setFormState((prevState) => ({
              ...prevState,
              user_contact_number: phoneValue,
            }));
  
        }
    }, [phoneValue, countryCode, countryName]);

    const resetFormState = () => { 
      setFormState(initFormState);
      setFarmRecord(null);
      setPhoneValue('');
    }

    const [formState, setFormState] = useState(initFormState);

// --------------------- end deciding how to handle queued record if any -------------------

    const [createNotice, setCreateNotice] = useState({showNotice: false, succ: false, msg: ""});
    const [responseErrors, setResponseErrors] = useState({});

    const language = useRef({english: 0, swahili: 1});
    const role = useRef({operations: 'ops', supervisor: 'spr'});
    const userState = useRef({active: 1, inactive: 0});

    const showFieldErrors = useErrorParser(); // Takes in array off validation errors and returns jsx.

    // Notifiy the App of this location and Header Nav requirements
    useLayoutEffect(() => {
      setEnvState(state => ({...state, whereAbout: t('navigation_element.nav_item_title_users'), backPath: "/fe/manage", homePath: "/fe"}));
      return () => {
        setEnvState(state => ({...state, whereAbout: "", backPath: "", homePath: ""}));
      }
      // eslint-disable-next-line
    }, []);

    const handleChange = (inputKey, inputValue) => {
      // setFormState({...formState, [inputKey]: inputValue});
      setPassword(inputValue);
      setFormState((prevState) => ({ ...prevState, [inputKey]: inputValue }));
    }

    if (initFormState === null) {
      return <div>{t("error_message.loading")}</div>; // or any loading indicator
    }

    const handleToggle = () => {
      if (type === 'password') {
          setIcon(eye);
          setType('text');
      } else {
          setIcon(eyeOff);
          setType('password');
      }
  }

  const isValidEmail = (email) => {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
  }; // Regular expression for validating email addresses

  const submitPasswordResetProcessor = async (e) => {
      e.preventDefault(); 
      
      if (formState.email === ''){
          setResponseErrors({email: [t('login_register_page.enter_email_address')]});   
          return;
      }
      
      if (!isValidEmail(formState.email.trim())) {
          setResponseErrors({ email: [t('login_register_page.enter_valid_email_address')] });   
          return;
      }

      if (formState.password === '') {
          setResponseErrors({password: [t('login_register_page.enter_password')]});    
          return;
      }

      if (formState.password.length < 5) {
          setResponseErrors({password: [t('login_register_page.password_length')]});    
          return;
      }      


      handleSubmit(
        e,
        formState,
        register,
        envState.online,
        process.env.REACT_APP_AUTH_REGISTER,
        setResponseErrors,
        setCreateNotice,
        resetFormState,
        publish,
      )

  }


    return (
        <div className="mt-[100px] mb-[100px]">
          <div className="md:grid md:grid-cols-2 md:gap-6">
            <div className="mt-5 md:col-span-2 md:mt-0">
            {/* <form
                onSubmit={(e) => {
                  handleSubmit(
                    e,
                    formState,
                    register,
                    envState.online,
                    process.env.REACT_APP_AUTH_REGISTER,
                    setResponseErrors,
                    setCreateNotice,
                    resetFormState,
                    publish,
                  )
                }}
                autoComplete="off"
              > */}
              <form onSubmit={submitPasswordResetProcessor} autoComplete="off" noValidate>
                {/*<form onSubmit={handleSubmit} autoComplete="off">*/}
                <div className="overflow-hidden sm:rounded-md">
                  <div className="bg-white px-4 py-5 sm:p-6">
                    <div className="grid grid-cols-6 gap-6">
                      {/* First Name - text */}
                      <div className="col-span-6 lg:col-span-2">
                        <InputLabel
                          inputId="first_name"
                          inputStyle="block text-sm font-medium text-gray-700"
                          labelText={t('manage_users_page.first_name')}
                        />
                        <TextInput
                          focus={true}
                          inputType="text"
                          inputName="first_name"
                          inputId="first_name"
                          hint={t('manage_users_page.enter_first_name')}
                          inputStyle="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-slate-500 focus:ring-slate-500 sm:text-sm"
                          inputValue={formState?.first_name}
                          handleChange={handleChange}
                        />
                        {showFieldErrors(responseErrors.first_name)}
                      </div>
                      {/* Middle Name - text */}
                      <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                        <InputLabel
                          inputId="middle_name"
                          inputStyle="block text-sm font-medium text-gray-700"
                          labelText={t('manage_users_page.middle_name')}
                        />
                        <TextInput
                          focus={false}
                          inputType="text"
                          inputName="middle_name"
                          inputId="middle_name"
                          hint={t('manage_users_page.enter_middle_name')}
                          inputStyle="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-slate-500 focus:ring-slate-500 sm:text-sm"
                          inputValue={formState?.middle_name}
                          handleChange={handleChange}
                        />
                        {showFieldErrors(responseErrors.middle_name)}
                      </div>
                      {/* Last Name - text */}
                      <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                          <InputLabel
                              inputId="last_name"
                              inputStyle="block text-sm font-medium text-gray-700"
                              labelText={t('manage_users_page.last_name')}
                            />
                            <TextInput
                              focus={false}
                              inputType="text"
                              inputName="last_name"
                              inputId="last_name"
                              hint={t('manage_users_page.enter_last_name')}
                              inputStyle="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-slate-500 focus:ring-slate-500 sm:text-sm"
                              inputValue={formState?.last_name}
                              handleChange={handleChange}
                            />
                            {showFieldErrors(responseErrors.last_name)}
                      </div>
                      {/* Email - email */}
                      <div className="col-span-6 sm:col-span-3">
                        <InputLabel
                          inputId="email"
                          inputStyle="block text-sm font-medium text-gray-700"
                          labelText={t('manage_users_page.email_address')}
                        />
                        <TextInput
                          focus={false}
                          inputType="email"
                          inputName="email"
                          inputId="email"
                          hint={t('manage_users_page.enter_email')}
                          inputStyle="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-slate-500 focus:ring-slate-500 sm:text-sm"
                          inputValue={formState?.email}
                          handleChange={handleChange}
                        />
                        {showFieldErrors(responseErrors.email)}
                      </div>
                      {/* Password - password */}
                      {/* <div className="col-span-6 sm:col-span-3">
                        <InputLabel
                          inputId="password"
                          inputStyle="block text-sm font-medium text-gray-700"
                          labelText={t('manage_users_page.password')}
                        />
                        <TextInput
                          focus={false}
                          inputType="password"
                          inputName="password"
                          inputId="password"
                          hint={t('manage_users_page.enter_password')}
                          inputStyle="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-slate-500 focus:ring-slate-500 sm:text-sm"
                          inputValue={formState?.password}
                          handleChange={handleChange}
                        />
                        {showFieldErrors(responseErrors.password)}
                      </div> */}


                                        {/* New Password */}
                                        <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                                            <InputLabel
                                              inputId="password"
                                              inputStyle="block text-sm font-medium text-gray-700"
                                              labelText={t('manage_users_page.password')}
                                            />
                                            <div className="mb-4 flex">
                                                <TextInput
                                                    focus={false}
                                                    inputType={type}
                                                    inputName="password"
                                                    inputId="password"
                                                    isAutoComplete="off"
                                                    hint="••••••••"
                                                    isRequired={false}
                                                    isDisabled={false}
                                                    inputStyle={textInputStyle.current.enabledInput}
                                                    inputValue={formState?.password}
                                                    handleChange={handleChange}
                                                />
                                                <span className="flex justify-around items-center" onClick={handleToggle}>
                                                    <Icon className="absolute mr-10" icon={icon} size={25}/>
                                                </span>
                                            </div>
                                            <div className="response-error-message">
                                                {showFieldErrors(responseErrors.password)}
                                            </div>
                                        </div>



                    </div>
                  </div>


                  <div className="bg-white px-4 py-4 sm:p-6">
                    <div className="grid grid-cols-6 gap-6">

                    {/* Personal Country and contact phone number */}
                    <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                        <InputLabel
                            inputId="country_name_and_phone_number"
                            inputStyle="block text-sm font-medium text-gray-700 text-left"
                            labelText={t('hamburger_messages.user_mobile_number')} 
                        />
                        <div>
                            <PhoneInput
                                countrySelectProps={{ unicodeFlags: true }}
                                defaultCountry="TZ"
                                placeholder={t('login_register_page.enter_phone_number')} 
                                value={phoneValue}
                                onChange={handlePhoneChange}
                            />
                            {phoneError && <p className="text-red-500 text-sm font-medium">{phoneError}</p>}
                            {showFieldErrors(responseErrors.user_contact_number)}
                        </div>
                    </div>


                      <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                        <fieldset>
                          <legend className="contents text-sm font-semibold text-gray-900">
                            {t('manage_users_page.role')}
                          </legend>
                          <p className="text-sm text-gray-500">
                            {t('manage_users_page.determines_user_role_in_the_farm')}
                          </p>
                          {/* Updated for horizontal arrangement */}
                          <div className="mt-0 flex space-x-4">  
                            {/* Operator Radio */}
                            <div className="flex items-center">
                              <RadioInput
                                focus={false}
                                inputType="radio"
                                inputName="role"
                                inputId="operations"
                                inputStyle="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                inputValue={role.current.operations}
                                inputStateValue={formState?.role}
                                handleChange={handleChange}
                              />
                              <InputLabel
                                inputId="operations"
                                inputStyle="ml-3 block text-sm font-medium text-gray-700"
                                labelText={t('manage_users_page.operator')}
                              />
                            </div>

                            {/* Supervisor Radio */}
                            <div className="flex items-center">
                              <RadioInput
                                focus={false}
                                inputType="radio"
                                inputName="role"
                                inputId="supervisor"
                                inputStyle="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                inputValue={role.current.supervisor}
                                inputStateValue={formState?.role}
                                handleChange={handleChange}
                              />
                              <InputLabel
                                inputId="supervisor"
                                inputStyle="ml-3 block text-sm font-medium text-gray-700"
                                labelText={t('manage_users_page.supervisor')}
                              />
                            </div>
                          </div>
                          {showFieldErrors(responseErrors.role)}
                        </fieldset>
                      </div>






                      {/* Language - radio */}
                      {/* <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                        <fieldset>
                          <legend className="contents text-sm font-semibold text-gray-900">{t('manage_users_page.language')}</legend>
                          <p className="text-sm text-gray-500">{t('manage_users_page.determines_app_language_for_user')}</p>
                          <div className="mt-4 space-y-4">
                            <div className="flex items-center">
                              <RadioInput
                                focus={false}
                                inputType="radio"
                                inputName="lang"
                                inputId="langSwa"
                                inputStyle="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                inputValue={language.current.swahili}
                                inputStateValue={formState?.lang}
                                handleChange={handleChange}
                              />
                              <InputLabel
                                inputValue="langSwa"
                                inputStyle="ml-3 block text-sm font-medium text-gray-700"
                                labelText={t('manage_users_page.kiswahili')}
                              />
                            </div>
                            <div className="flex items-center">
                              <RadioInput
                                focus={false}
                                inputType="radio"
                                inputName="lang"
                                inputId="langEn"
                                inputStyle="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                inputValue={language.current.english}
                                inputStateValue={formState?.lang}
                                handleChange={handleChange}
                              />
                              <InputLabel
                                inputId="langEn"
                                inputStyle="ml-3 block text-sm font-medium text-gray-700"
                                labelText={t('manage_users_page.english')}
                              />
                            </div>
                          </div>
                          {showFieldErrors(responseErrors.lang)}
                        </fieldset>
                      </div> */}





                      {/* Active - radio */}
                      {/* <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                        <fieldset>
                          <legend className="contents text-sm font-semibold text-gray-900">{t('manage_users_page.activation')}</legend>
                          <p className="text-sm text-gray-500">{t('manage_users_page.determines_user_activation_state')}</p>
                          <div className="mt-4 space-y-4">
                            <div className="flex items-center">

                              <RadioInput
                                focus={false}
                                inputType="radio"
                                inputName="active"
                                inputId="inactiveUser"
                                inputStyle="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                inputValue={userState.current.inactive}
                                inputStateValue={formState?.active}
                                handleChange={handleChange}
                              />
                              <InputLabel
                                inputId="inactiveUser"
                                inputStyle="ml-3 block text-sm font-medium text-gray-700"
                                labelText={t('manage_users_page.inactive')}
                              />

                            </div>
                            <div className="flex items-center">

                              <RadioInput
                                focus={false}
                                inputType="radio"
                                inputName="active"
                                inputId="activeUser"
                                inputStyle="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                inputValue={userState.current.active}
                                inputStateValue={formState?.active}
                                handleChange={handleChange}
                              />
                              <InputLabel
                                inputId="activeUser"
                                inputStyle="ml-3 block text-sm font-medium text-gray-700"
                                labelText={t('manage_users_page.active')}
                              />
                            </div>
                          </div>
                          {showFieldErrors(responseErrors.active)}
                        </fieldset>
                      </div> */}

                      <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                        <fieldset>
                          <legend className="contents text-sm font-semibold text-gray-900">
                            {t('manage_users_page.activation')}
                          </legend>
                          <p className="text-sm text-gray-500">
                            {t('manage_users_page.determines_user_activation_state')}
                          </p>
                          {/* Updated for horizontal arrangement */}
                          <div className="mt-4 flex space-x-4">  

                            {/* Active Radio */}
                            <div className="flex items-center">
                              <RadioInput
                                focus={false}
                                inputType="radio"
                                inputName="active"
                                inputId="activeUser"
                                inputStyle="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                inputValue={userState.current.active}
                                inputStateValue={formState?.active}
                                handleChange={handleChange}
                              />
                              <InputLabel
                                inputId="activeUser"
                                inputStyle="ml-3 block text-sm font-medium text-gray-700"
                                labelText={t('manage_users_page.active')}
                              />
                            </div>


                            {/* Inactive Radio */}
                            <div className="flex items-center">
                              <RadioInput
                                focus={false}
                                inputType="radio"
                                inputName="active"
                                inputId="inactiveUser"
                                inputStyle="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                inputValue={userState.current.inactive}
                                inputStateValue={formState?.active}
                                handleChange={handleChange}
                              />
                              <InputLabel
                                inputId="inactiveUser"
                                inputStyle="ml-3 block text-sm font-medium text-gray-700"
                                labelText={t('manage_users_page.inactive')}
                              />
                            </div>
                          </div>
                          {showFieldErrors(responseErrors.active)}
                        </fieldset>
                      </div>



                    </div>
                  </div>
                  <Footer respNotice={createNotice} setRespNotice={setCreateNotice} footerType="create" />                  
                </div>
              </form>
            </div>
          </div>
      </div>
    );
}