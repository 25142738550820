// This is a generic form Telephone input
// The idea is to use this wherever a Telephone input is required

export const TelInput = (props) => {
    return (
        <input
            autoFocus={props.focus}
            type="tel"
            name={props.inputName}
            id={props.inputId}
            placeholder={props.hint}
            autoComplete="off"
            required={props.isRequired ?? true}
            disabled={props.isDisabled ?? false}
            pattern={props.inputPattern ?? null}
            className={props.inputStyle}
            value={props.inputValue}
            minLength={9}
            maxLength={14}
            onChange={e => { props.handleChange(props.inputName, e.target.value) }}
      />
    );
}