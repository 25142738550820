import { useEnaDisableStyle, useErrorParser, useEvents, useHandleSubmit } from '../hooks';
import { TRANS_TYPE_PURCHASE_CATTLE, DEFAULT_BASE64_NO_IMAGE } from '../constants';
import { useParams, useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { TextareaInput } from '../components';
import { useUser, useEnv } from '../contexts';

import {
    createResource,
    guestCreateResource,
  } from "../services/farmApi";

import { 
    getCategoryMapping, 
    getBreedMapping, 
    getCurrencyMapping, 
    getVaccinationHistoryMapping 
} from '../components/Mappings';

function getProductById(list_id, userId) {
    // const products = JSON.parse(localStorage.getItem('products')) || []; 
    const products = JSON.parse(localStorage.getItem(`products_${userId}`)) || []; 

    return products.find(product => product.list_id === parseInt(list_id));
}

function ProductDetail() {

    const { user } = useUser();  // Get currently loggedIn user
    const textInputStyle = useEnaDisableStyle();
    const today = new Date().toISOString().split('T')[0]; // Todays' date

    // for language translation
    const { t } = useTranslation();
    // Use the mapping functions with `t` for translation
    const categoryMapping = getCategoryMapping(t);
    const breedMapping = getBreedMapping(t);
    const currencyMapping = getCurrencyMapping(t);
    const vaccinationHistoryMapping = getVaccinationHistoryMapping(t);
    
    const { productId } = useParams();
    const product = getProductById(productId, user.id);
    const [imageBase, setImageBase] = useState(''); // Initialize as an empty string

    const [showContactForm, setShowContactForm] = useState(false);

    const {envState, setEnvState } = useEnv(); // Get Environment context
    const showFieldErrors = useErrorParser(); // Takes in array off validation errors and returns jsx.
    const [responseErrors, setResponseErrors] = useState({});

    const [initFormState, setInitFormState] = useState(null);
    const resetFormState = () => { setFormState(initFormState) }

    const { publish } = useEvents(); // publish - dispatches events
    const { handleSubmit } = useHandleSubmit(); // Shared submit handler
    const [createNotice, setCreateNotice] = useState({showNotice: false, succ: false, msg: ""});

    const navigate = useNavigate(); // Initialize the navigate function

    const showToast = (message, type) => {
        toast[type](message, {
          position: "top-center", 
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
    };

    // Setting initial form state in useEffect
    useEffect(() => {
        const initialState = {
            trans_type: TRANS_TYPE_PURCHASE_CATTLE, // Requesting
            // list_id: '',
            purchaser_id: user.email,
            description: '',
            user_id: user.id, // assuming user is defined in the scope
        };

        setInitFormState(initialState);
        setFormState(initialState); // Set formState when initFormState is set
    }, []);

    const [formState, setFormState] = useState({
        trans_type: TRANS_TYPE_PURCHASE_CATTLE, // Requesting
        // list_id: '',
        purchaser_id: user.email,
        description: '',
        user_id: user.id, // assuming user is defined in the scope
    });

    // This effect will log the formState whenever it changes
    useEffect(() => {
    }, [formState]); // This will log formState whenever it changes

    const getImageBase = async () => {
        if (product?.list_id) {
            try {
                const regResp = await guestCreateResource(
                    process.env.REACT_APP_AUTH_IMAGE_BASE, // Adjust API URL as needed
                    {
                        list_id: product.list_id,
                        image_usage: 'listed_sales'
                    }
                );
    
                // Check if the response is successful and contains the 'data' field
                if (regResp.success && regResp.data?.image_base) {
                    // console.log('Image base returned successfully:', regResp.data.image_base);
                    setImageBase(regResp.data.image_base); // Set imageBase once fetched
                } else {
                    setImageBase(DEFAULT_BASE64_NO_IMAGE); // Use default image on failure
                    showToast(t('error_message.failed_to_retrieve_image'), "error"); 
                }
            } catch (error) {
                // console.error('Error fetching image base:', error);
                setImageBase(DEFAULT_BASE64_NO_IMAGE); // Use default image on error
                showToast(t('error_message.error_fetching_image'), "error"); 
            }
        } else {
            showToast(t('error_message.failed_to_retrieve_image_advert'), "error");  
            setImageBase(DEFAULT_BASE64_NO_IMAGE); // Use default image if list_id is missing
        }
    };

    useEffect(() => {
        let hasFetched = false;
    
        if (!hasFetched && productId) {
            getImageBase();
            hasFetched = true;
        }
    
        return () => {
            hasFetched = false; // Reset flag on component unmount
        };
    }, [productId]);

    useEffect(() => {
        // console.log('Updated image base:', imageBase);
    }, [imageBase]);

    // Reusable function for handling JSX handling of images and videos or other media types.
    const MediaDisplay = ({ media }) => {
        if (media.startsWith('data:image')) {
            return <img src={media} className="card-img-top w-full h-auto" alt="Media" style={{ objectFit: 'contain' }} />;
        } else if (media.startsWith('data:video')) {
            return <video src={media} className="card-video-top w-full h-auto" controls style={{ objectFit: 'contain' }} />;
        }
        return null;
    };

    const handleChange = (inputKey, inputValue) => {
        // For non-file inputs, keep the current behavior
        setFormState((prevState) => ({
            ...prevState,
            [inputKey]: inputValue,
        }));

        // Clear the corresponding error message if the user is typing again
        setResponseErrors((prevErrors) => ({
            ...prevErrors,
            [inputKey]: [], // Clear the specific error when the user changes the input
        }));
    };

    const submitToSeller = (e) => {
        e.preventDefault();

        // Clear previous errors before validation
        setResponseErrors({});

        formState.list_id = product.list_id;
        
        // Validate region (should not be empty)
        if (formState.description === '') {
            setResponseErrors({ description: [t('market_place_page.provide_message')] });
            return;
        }

        // TODO: before submitting to the seller, check if this user has
        //       phone number from migration users, if has then proceed to send
        //       if not then ask the user to input a phone number before next step
        //       note, inform user that this phone number will be used to transact on this item only
        //       with the buyer and that others will not see neither the transaction nor the phone number

        submitForm(e);
    };

    // Submit form with necessary parameters
    const submitForm = (e) => {
        e.preventDefault(); // Prevent form from reloading the page

        // Submit form using handleSubmit, passing formState and necessary parameters
        handleSubmit(
            e,
            formState,  // pass formState with base64 and media details
            createResource,
            envState.online,
            process.env.REACT_APP_MARKET_PLACE,
            setResponseErrors,
            setCreateNotice,
            resetFormState,
            publish,
            t 
        );

        // Display success message and reset form
        toast.success(t('market_place_page.item_uploaded_users_notified'));
    };    

    return (
        <div className="container mt-2">
            <div>{t('market_place_page.view_details_sale_number')} {product.list_id}</div>
            {product ? (
                <div>
                    {/* Image for the sale item */}
                    <MediaDisplay media={imageBase} altText={product.breed || 'Media'} />
                     
                    {/* sale category */}
                    <h1 style={{
                            fontFamily: 'Arial, sans-serif',
                            fontSize: '18px', fontWeight: 'bold',
                            marginBottom: '5px'
                        }}>
                        {t('market_place_page.manage_face_cattle_side_category')}: {categoryMapping[product.category] || product.category}
                    </h1>
                    {/* sale breed */}
                    <p style={{
                            fontFamily: 'Arial, sans-serif',
                            fontSize: '16px', marginBottom: '5px'
                        }}>
                        {t('market_place_page.manage_face_cattle_side_breed')}: {breedMapping[product.breed] || product.breed}
                    </p>                    
    
                    {/* sale cattle age */}
                    <p style={{
                            fontFamily: 'Arial, sans-serif',
                            fontSize: '16px', marginBottom: '5px'
                        }}>
                        {t('market_place_page.view_details_face_age')}: {product.cattle_age} {t('market_place_page.view_details_face_years')}
                    </p>
    
                    {/* sale currency and price */}
                    <p style={{
                            fontFamily: 'Arial, sans-serif',
                            fontSize: '16px', marginBottom: '5px'
                        }}>
                        {/* Price: {currencyMapping[product.currency] || product.currency} {product.price} */}
                        {t('market_place_page.view_details_face_price')}: {currencyMapping[product.currency] || product.currency} {Number(product.price).toLocaleString('en-US', {
                                                                                minimumFractionDigits: 0,
                                                                                maximumFractionDigits: 0
                                                                            })}
                    </p>
    
                    {/* sale location or region */}
                    <p style={{
                            fontFamily: 'Arial, sans-serif',
                            fontSize: '16px', marginBottom: '5px'
                        }}>
                        {t('market_place_page.view_details_face_location')}: {product.region}
                    </p>
    
                    {/* Conditionally render averageMilk and calvingNumber */}
                    {(product.category === 'pregnant' || 
                    product.category === 'openHeifer' || 
                    product.category === 'cowAndCalve') && (
                        <>
                            {/* sale average milk production */}
                            <p style={{
                                    fontFamily: 'Arial, sans-serif',
                                    fontSize: '16px', marginBottom: '5px'
                                }}>
                                {t('market_place_page.view_details_face_average_milk')}: {product.average_milk_yeald}   {t('market_place_page.view_details_face_litres_per_day')}
                            </p>
                            {/* sale calving number */}
                            <p style={{
                                    fontFamily: 'Arial, sans-serif',
                                    fontSize: '16px', marginBottom: '5px'
                                }}>
                                {t('market_place_page.view_details_face_calved_time')}: {product.number_calving}
                            </p>
                        </>
                    )}
    
                    {/* Conditionally render pregnancyAge */}
                    {product.category === 'pregnant' && (
                        <p style={{
                                fontFamily: 'Arial, sans-serif',
                                fontSize: '16px', marginBottom: '5px'
                            }}>
                            {t('market_place_page.view_details_face_pregnancy')}: {product.pregnancy_age} {t('market_place_page.view_details_face_months')}
                        </p>
                    )}
    
                    {/* sale vaccination history */}
                    <p style={{
                            fontFamily: 'Arial, sans-serif',
                            fontSize: '16px', marginBottom: '5px'
                        }}>
                        {t('market_place_page.view_details_face_vaccination_record')}: {vaccinationHistoryMapping[product.vaccination_record] || product.vaccination_record}
                    </p>
    
                    {/* sale description */}
                    <p style={{
                            fontFamily: 'Arial, sans-serif',
                            fontSize: '16px', marginBottom: '5px'
                        }}>
                        {t('market_place_page.view_details_face_notes')}: {product.description}
                    </p>
    
                    {/* Contact Seller button */}
                    {product.seller_id !== user.email && !showContactForm && (
                        <button 
                            className="btn btn-primary" 
                            onClick={() => setShowContactForm(true)}
                        >
                            <h1 
                                style={{
                                    fontFamily: 'Arial, sans-serif',
                                    fontSize: '18px',
                                    fontWeight: 'bold',
                                }}
                            >
                                <u>{t('market_place_page.view_details_face_contact_seller')}</u>
                            </h1>
                        </button>
                    )}
  
                    {/* Contact Form */}
                    {showContactForm && (
                        <div>
                            <form onSubmit={submitToSeller}>
                                <div className="form-group">
                                    <div className="col-span-6">
                                        <TextareaInput
                                            focus={false}
                                            inputName="description"
                                            inputId="description"
                                            hint={t('market_place_page.view_details_face_message_tip')}
                                            isRequired={false}
                                            inputRows={4}
                                            inputStyle={textInputStyle.current.enabledInput}
                                            inputValue={formState?.description}
                                            maxLength={512}
                                            handleChange={handleChange}
                                        />
                                        {showFieldErrors(responseErrors.description)}
                                    </div> 
                                </div>
                                <div className="mb-2"></div>
                                <button 
                                    type="submit" 
                                    className="w-[200px] inline-flex justify-center rounded-md border border-transparent bg-slate-600 py-2 px-4 text-sm font-semibold text-gray-200 shadow-sm hover:bg-slate-500 focus:outline-none focus:ring-2 focus:ring-slate-500 focus:ring-offset-2 uppercase">
                                    
                                    {t('buttons_navigation.button_submit_to_seller')} 
                                </button>
                            </form>
                        </div>
                    )}
                </div>
            ) : (
                <p>{('market_place_page.product_details_not_found')}</p> 
            )}
    
            {/* Back Button */}
            <div className="mb-4"></div>
            <button
                onClick={() => navigate(-1)} // Go back to the previous page in history
                className="w-[200px] inline-flex justify-center rounded-md border border-transparent bg-slate-600 py-2 px-4 text-sm font-semibold text-gray-200 shadow-sm hover:bg-slate-500 focus:outline-none focus:ring-2 focus:ring-slate-500 focus:ring-offset-2 uppercase">
                {t('buttons_navigation.button_go_back')} 
            </button>

            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                closeOnClick
                pauseOnHover
                draggable
                pauseOnFocusLoss
                theme="light"
            />  

        </div>
    );
}

export  { ProductDetail };
