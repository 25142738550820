// This is a generic form radio button input
// The idea is to use this wherever a radio button input is required

export const RadioInput = (props) => {
    return (
      <input
        type="radio"
        name={props.inputName}
        id={props.inputId}
        autoFocus={props.focus}
        autoComplete="off"
        required={props.isRequired ?? true}
        disabled={props.isDisabled ?? false}
        className={props.inputStyle}
        value={props.inputValue}
        checked={props.inputValue === props.inputStateValue}
        onChange={() => {
          props.handleChange(props.inputName, props.inputValue, props.inputId);
        }}
      />
    );
  };
  

