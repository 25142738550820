import { createContext, useContext, useState } from "react";
import { checkAuth } from "../services/auth";

// User context
const userContext = createContext(undefined);

// Context provider
const UserProvider = ({children}) => {
    const [user, setUser] = useState(checkAuth());
    return (
        <userContext.Provider value={{user, setUser}}>
            {children}
        </userContext.Provider>
    );
}

// User conext custom hook
const useUser = () =>  useContext(userContext);

export { UserProvider, useUser };

