import { useState, useEffect, useLayoutEffect, useRef } from 'react';

import { Footer, SelectInputV2, TableDAtaView, Spinner} from '../../components';

import { useHandleSubmit, useEnaDisableStyle } from '../../hooks';
import { useEnv } from '../../contexts';
import { getResource } from '../../services/farmApi';

import { useTranslation } from "react-i18next";

function VisitsReport() {

    // for language translation
    const { t } = useTranslation();

    const [report, setReport] = useState({filter:  t('search_page.select_report')});
    const [isSearching, setIsSearching] = useState(false);
    const [visitsData, setVisitsData] = useState([]);
    const [visitsViewData, setVisitsViewData] = useState([]);
    const [tableSettings, setTableSettings] = useState({idUsage: 1, useSerial: true}); // Influence how table is displayed
    const initNotice = useRef({
        msg: "",
        showNotice: false,
        succ: false,
        status: null,
        ver: null   
    });
    const [createNotice, setCreateNotice] = useState(initNotice.current);
 
    const { handleGetResource } = useHandleSubmit(); // Shared submit handler
    const { envState, setEnvState} = useEnv(); // Get Environment context

    // Filter Select options
    const reportOptions = useRef([
        ["default",  t('search_page.select_report')],
        ["month",  t('page_elements.30_days')],
        ["half", t('page_elements.180_days')],
    ]);

    const textInputStyle = useEnaDisableStyle();

    /**
     * Notifiy the App of this location, provide Header Nav links
     *
     */
    useEffect(() => {
        setEnvState(state => ({...state, whereAbout: t('navigation_element.nav_item_title_visitors_report'), backPath: "/fe/reports", homePath: "/fe"}));

        return () => {
            setEnvState(state => ({...state, whereAbout: "", backPath: "", homePath: ""}));
        }
        // eslint-disable-next-line
    }, []);

    // Handle Select input change
    const handleReportChange = (inputKey, inputValue) => {
        setReport({[inputKey]: inputValue});
    }

    // Fetch report
    useLayoutEffect(() => {
        // Handle report report
        const handleFilterChange = (filter, idUsage = 1, useSerial = true) => {
           
            // Query the report
            getMilkingReport("/?filter=" + filter);
            // Make any required changes
            setTableSettings({idUsage, useSerial});
        }

        // Handle clear report filter
        const handleReset = () => {
            visitsViewData.length > 0 && setVisitsViewData([]);
            visitsData.length > 0 && setVisitsData([]);
        }

        const getMilkingReport = (reportQuery) => {
            handleGetResource(
                null,
                getResource,
                envState.online,
                process.env.REACT_APP_VISITS + reportQuery,
                setCreateNotice,
                null,
                setVisitsData,
                setIsSearching,
                false // explicitly expecting a single model?
            );
        }

        switch (report.filter) {
            case reportOptions.current[1][0]:
                handleFilterChange(reportOptions.current[1][0], 0);
                break;
            case reportOptions.current[2][0]:
                handleFilterChange(reportOptions.current[2][0], 0);
                break;
            default:
                handleReset();
                break;
        }
        // eslint-disable-next-line
    }, [report]);

    // Display report
    useLayoutEffect(() => {
        if (visitsData.length > 0) {
            setVisitsViewData(visitsData);
         
        } else {
            setVisitsViewData([]);
        }
        // eslint-disable-next-line
    }, [visitsData]);

    // handle reset report selection
    useLayoutEffect(() => {
        if (createNotice.status !== 200 && createNotice.status !== 404) {
            setReport({filter: reportOptions.current[0][1]}); 
        }
    }, [createNotice.ver, createNotice.status])

    return(
            <div className="md:grid md:grid-cols-2 md:gap-6 mt-[100px] mb-[100px] overflow-x-auto">
            <div className="col-span-2 flex flex-col justify-center items-center">
                <form autoComplete="off" className="w-full">
                        <div className="py-5">
                            <div className="grid grid-cols-6 lg:grid-cols-5">
                                <div className="hidden lg:block lg:col-span-1">{/*spacer*/}</div>
                                {/* Report - select */}
                                <div className="col-span-6 lg:col-span-3 flex justify-center p-2">
                                    <div className="w-[400px]">
                                        {/* <InputLabel
                                            inputId="report"
                                            inputStyle="block text-sm font-medium text-gray-700"
                                            labelText="Select Cattle Report"
                                        /> */}
                                        <SelectInputV2
                                            focus={false}
                                            inputName="filter"
                                            inputId="report"
                                            inputStyle={textInputStyle.current.enabledInput}
                                            inputValue={report.filter}
                                            handleChange={handleReportChange}
                                            choices={reportOptions.current}      
                                        />
                                    </div>
                                </div>
                                <div className="hidden lg:block lg:col-span-1">{/*spacer*/}</div>
                            </div>

                            { isSearching &&
                                <div className="grid grid-cols-6 lg:grid-cols-5">
                                    <div className="col-span-6 lg:col-span-5 flex justify-center p-2">
                                        <Spinner /> 
                                    </div>
                                </div>
                            }   
                            {
                                createNotice.succ ?
                                    <div className="grid grid-cols-6 lg:grid-cols-5">
                                        <div className="hidden lg:block lg:col-span-1">{/*spacer*/}</div>
                                        <div className="col-span-6 lg:col-span-3">
                                        <div className="w-full flex justify-center p-2">
                                            <TableDAtaView
                                                tableData={visitsViewData}
                                                idUsage={tableSettings.idUsage}
                                                useSerial={tableSettings.useSerial}
                                                mapHash={{
                                                    "#": "#",
                                                    name: t('table_formatter.table_full_name'),
                                                    phone: t('table_formatter.table_phone'),
                                                    reason: t('table_formatter.table_reason'),
                                                    date: t('table_formatter.table_date'),
                                                }}
                                            />
                                        </div>
                                        </div>
                                        <div className="hidden lg:block lg:col-span-1">{/*spacer*/}</div>
                                    </div> :
                                createNotice.status === 404 ?
                                    <div className="grid grid-cols-6 lg:grid-cols-5">
                                        <div className="hidden lg:block lg:col-span-1">{/*spacer*/}</div>
                                        <div className="col-span-6 lg:col-span-3">
                                        <div className="w-full flex justify-center py-2">
                                        <span> {t('user_handle_notice.no_data_matching_criteria')}</span>
                                        </div>
                                        </div>
                                        <div className="hidden lg:block lg:col-span-1">{/*spacer*/}</div>
                                    </div> :
                                ""
                            }
                        </div>
                        <Footer respNotice={createNotice} setRespNotice={setCreateNotice} backLink="/fe/reports" footerType="nav" />
                </form>
            </div>
            </div>
    );
}

export { VisitsReport };