// hooks/ConsoleLogEvent.js
// a comprehensive and reusable console logging helper that supports console.log, 
// console.warn, console.error, and other logging levels, and can be easily disabled for production
// Example usages
// Import relative to your file e.g. import { ConsoleLogEvent } from './utils';
// Exmplees on how you can use it 
//  ConsoleLogEvent('log', 'Application started');
//  ConsoleLogEvent('warn', 'Deprecated method used', { methodName: 'doSomething' });
//  ConsoleLogEvent('error', 'Error during service worker registration', error);
//  ConsoleLogEvent('info', 'User successfully logged in', { userId: '12345' });

const ConsoleLogEvent = (() => {
    // Flag to enable/disable logging (set to false in production)
    const isLoggingEnabled = process.env.REACT_APP_ENABLE_LOGGING === 'true';
  
    // Helper function to handle logs
    const logEvent = (type, message, data) => {
      if (isLoggingEnabled) {
        switch (type) {
          case 'log':
            console.log(`[Log]: ${message}`, data || '');
            break;
          case 'warn':
            console.warn(`[Warn]: ${message}`, data || '');
            break;
          case 'error':
            console.error(`[Error]: ${message}`, data || '');
            break;
          case 'info':
            console.info(`[Info]: ${message}`, data || '');
            break;
          case 'debug':
            console.debug(`[Debug]: ${message}`, data || '');
            break;
          default:
            console.log(`[Log]: ${message}`, data || '');
        }
      }
    };
  
    return logEvent;
  })();

  export { ConsoleLogEvent };
  