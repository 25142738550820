// I want to persist the deferredPrompt state between page transitions
// so that I can use A2HS by sharing context to share the deferredPrompt 
// state across different components

// a2hsContext.js
import React, { createContext, useState, useContext } from 'react';

const A2HSContext = createContext();

export const A2HSProvider = ({ children }) => {
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [showInstallPrompt, setShowInstallPrompt] = useState(false);

  return (
    <A2HSContext.Provider value={{ deferredPrompt, setDeferredPrompt, showInstallPrompt, setShowInstallPrompt }}>
      {children}
    </A2HSContext.Provider>
  );
};

export const useA2HS = () => useContext(A2HSContext);
