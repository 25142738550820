import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
//import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import Cookies from 'js-cookie';

import translationEN from "./locales/en/translation.json";
import translationSW from "./locales/sw/translation.json";

const resources = {
  en: {
    translation: translationEN,
  },
  sw: {
    translation: translationSW,
  },
};

// Custom language detector
const languageDetector = {
  type: 'languageDetector',
  async: true,
  detect: cb => cb(Cookies.get('gdf_i18next') || 'en'), // Read language from cookie or default to 'en'
  init: () => {},
  cacheUserLanguage: () => {},
};

i18n
  .use(Backend)
  .use(languageDetector)
  .use(initReactI18next)
  .init({
    load: 'languageOnly',
    resources,
    debug: false,
    fallbackLng: 'en',
    detection: {
      order: ['languageDetector'],
      caches: ['cookie', 'localStorage'],
    },
    interpolation: {
      format: (value, format, lng) => {
        if (format === 'number') {
          return new Intl.NumberFormat(lng).format(value);
        }
        if (format === 'date') {
          return new Intl.DateTimeFormat(lng).format(value);
        }
        if (format === 'currency') {
          return new Intl.NumberFormat(lng, {
            style: 'currency',
            currency: 'USD',
          }).format(value);
        }
      },
      escapeValue: false,
    },
  });

export default i18n;