// This hook creates and share a Girth Length(cm) to Weight(Kg) chart
const girthChart = [
    [58, 20], [59, 21], [60, 22], [61, 24], [62, 25], [63, 26], [64, 27], [65, 28], [66, 20],
    [68, 30], [69, 31], [70, 32], [71, 34], [72, 35], [73, 36], [74, 37], [75, 38], [76, 40],
    [77, 42], [78, 43], [79, 45], [80, 46], [81, 48], [82, 50], [83, 52], [84, 54], [85, 56],
    [86, 58], [87, 60], [88, 62], [89, 65], [90, 67], [91, 69], [92, 72], [93, 74], [94, 77],
    [95, 79], [96, 82], [97, 84], [98, 87], [99, 90], [100, 93], [101, 96], [102, 98], [103, 101],
    [104, 103], [105, 106], [106, 109], [107, 112], [108, 115], [109, 118], [110, 121], [111, 124],
    [112, 127], [113, 130], [114, 133], [115, 136], [116, 140], [117, 143], [118, 147], [119, 150],
    [120, 153], [121, 157], [122, 161], [123, 164], [124, 168], [125, 172], [126, 176], [127, 180],
    [128, 184], [129, 188], [130, 192], [131, 196], [132, 201], [133, 205], [134, 210], [135, 214],
    [136, 219], [137, 223], [138, 228], [139, 232], [140, 236], [141, 241], [142, 246], [143, 250],
    [144, 255], [145, 260], [146, 265], [147, 270], [148, 275], [149, 280], [150, 285], [151, 290],
    [152, 295], [153, 300], [154, 305], [155, 310], [156, 316], [157, 321], [158, 327], [159, 332],
    [160, 338], [161, 344], [162, 351], [163, 358], [164, 366], [165, 373], [166, 381], [167, 388],
    [168, 396], [169, 403], [170, 410], [171, 418], [172, 426], [173, 433], [174, 441], [175, 448],
    [176, 456], [177, 463], [178, 471], [179, 478], [180, 485], [181, 492], [182, 500], [183, 508],
    [184, 516], [185, 524], [186, 532], [187, 540], [188, 548], [189, 556], [190, 564], [191, 572],
    [192, 580], [193, 588], [194, 596], [195, 604], [196, 613], [197, 622], [198, 631], [199, 640],
    [200, 649], [201, 658], [202, 667], [203, 676], [204, 685], [205, 694], [206, 703], [207, 712],
    [208, 722], [209, 732], [210, 742], [211, 752], [212, 762], [213, 772], [214, 782], [215, 792],
    [216, 803], [217, 814], [218, 825], [219, 836], [220, 847], [221, 858], [222, 869], [223, 880],
    [224, 891], [225, 900], [226, 910], [227, 920], [228, 930], [229, 939], [230, 949], [231, 959],
    [232, 968], [233, 978], [234, 988], [235, 998], [236, 1007], [237, 1017], [238, 1027], [239, 1036],
    [240, 1046], [241, 1056], [242, 1066], [243, 1075], [244, 1085], [245, 1095], [246, 1104], [247, 1114],
    [248, 1124], [249, 1134], [250, 1143], [251, 1153], [252, 1163], [253, 1172], [254, 1182], [255, 1192],
    [256, 1202], [257, 1211], [258, 1221], [259, 1231], [260, 1240], [261, 1250], [262, 1260], [263, 1270],
    [264, 1279], [265, 1289], [266, 1299], [267, 1308], [268, 1318], [269, 1328], [270, 1338], [271, 1347],
    [272, 1357], [273, 1367], [274, 1376], [275, 1386], [276, 1396], [277, 1405],
];

// 58, 20], [59, 21], [60, 22], [61, 24], [62, 25], [63, 26], [64, 27], [65, 28], [66, 20],
// this above array was introduced to mitigate weight below 30Kg but is not a scientific array
// the solution here should be allow, users to decided on whether to use girth length or actual weight

// Girth length - weight map
const girthMap = new Map(girthChart);

export const useGirthLength = () => [58, 277, girthMap];