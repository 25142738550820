import { TextInput, RadioInput, InputLabel, SelectInput, TextareaInput, NumberInput, Footer } from '../components'
import { useGirthLength, useEnaDisableStyle, useErrorParser, useEvents, useHandleSubmit } from '../hooks';
import { useState, useLayoutEffect, useRef, useEffect } from 'react';
import { createResource, guestCreateResource } from '../services/farmApi';
import { useTranslation } from "react-i18next";
import { useUser, useEnv } from '../contexts';
import { ToastContainer, toast } from "react-toastify";


export const CattleForm = (props) => {

    // for language translation
    const { t } = useTranslation();

    const [cattleRecord, setCattleRecord] = useState(null);
    const [initFormState, setInitFormState] = useState(null);
    const { user } = useUser();  // Get currently loggedIn user
    const { envState, setEnvState } = useEnv(); // Get Environment context
    const { publish } = useEvents(); // publish - dispatches events
    const { handleGetResource, handleSubmit } = useHandleSubmit(); // Shared submit handler
    const today = new Date().toISOString().split('T')[0]; // Todays' date
    const [formMode, setFormMode] = useState(props.formMode ? "edit" : "new");
    const footerType = formMode === "edit" ? "update" : "create";

    const showToast = (message, type) => {
      toast[type](message, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    };
    
    useEffect(() => {
        // Retrieve record from local storage using the special key if was a failed queue
        // const queuedCattleRecord = localStorage.getItem('queuedFarmRecord');
        const queuedCattleRecord = localStorage.getItem(`queuedFarmRecord_${user.id}`);
        
        if (queuedCattleRecord) {
            const parsedCattleRecord = JSON.parse(queuedCattleRecord);
            setCattleRecord(parsedCattleRecord);
    
            // Delete the special key and its corresponding data from local storage
            // localStorage.removeItem('queuedFarmRecord');
            localStorage.removeItem(`queuedFarmRecord_${user.id}`);
            
        }
    }, []);
    
    useEffect(() => {
        if (cattleRecord) {
            // If cattleRecord exists, initialize initFormState with its data
            setInitFormState({ ...cattleRecord, user_id: user.id });
        } else if (props?.cattleData) {
          // If cattleRecord is null, initialize initFormState with data
          // initilize this properly with values from the DB brought here by prop
            setInitFormState({
                id: props.cattleData.id,
                state: props.cattleData.State,
                breed: props.cattleData.Breed,
                name: props.cattleData.Name,
                gender: props.cattleData.Gender,
                girth_length: props.cattleData.Girth,
                weight: props.cattleData.Weight,
                dob: props.cattleData.DoB,
                dofe: props.cattleData.DoFe,
                source_type: props.cattleData.Source_Type,
                source_name: props.cattleData.Source_Name,
                id_at_source: props.cattleData.Old_Tag,
                mother_id: props.cattleData.Mother_Tag,
                father_id: props.cattleData.Father_Tag,
                notes: props.cattleData.Notes,
                created_at: props.cattleData.Created,
                refId: props.cattleData.refId,
            });

        } else {
            // If cattleRecord is null, initialize initFormState with default values
            setInitFormState({
                id: '',
                state: 'Calf',
                breed: 'Friesian',
                name: '',
                gender: 'Female',
                girth_length: 68,
                weight: 30,
                dob: today,
                dofe: today,
                source_type: 'Internal',
                source_name: t('cattle_page.gdf'),
                id_at_source: '',
                mother_id: '',
                father_id: '',
                notes: '',
                user_id: user.id,
            });
        }
    }, [cattleRecord]);

    // ensuring initFormState is initialized before rendering a page
    useEffect(() => {
      if (initFormState !== null) {
        setFormState(initFormState);
      }
    }, [initFormState]);

    const [formState, setFormState] = useState(initFormState);
    const [createNotice, setCreateNotice] = useState({showNotice: false, succ: false, msg: ""});
    const [responseErrors, setResponseErrors] = useState({});

    const textInputStyle = useEnaDisableStyle();
    const showFieldErrors = useErrorParser(); // Takes in array off validation errors and returns jsx.
    const girthRef = useRef(useGirthLength()); // Retrieve the Girth chart

    useLayoutEffect(() => {
        if (formState && formState.girth_length >= girthRef.current[0] && formState.girth_length <= girthRef.current[1]) {
            if (girthRef.current[2].has(formState.girth_length)) {
                setFormState(state => ({...state, weight: girthRef.current[2].get(state.girth_length)}));
            } else {
                setFormState(state => ({...state, weight: 0}));
            }
        } else {
            setFormState(state => ({...state, weight: 0}));
        }
    }, [formState?.girth_length]);

    // Notifiy the App of this location and Header Nav requirements
    useLayoutEffect(() => {
        if (formMode === "edit") {
          setEnvState(state => ({...state, whereAbout: t('navigation_element.nav_item_title_update_cattle'), backPath: "/fe/reports", homePath: "/fe"}));
        
        }else {
        
          setEnvState(state => ({...state, whereAbout: t('navigation_element.nav_item_title_cattle'), backPath: "", homePath: "/fe"}));
        
        }
      
       return () => {
         setEnvState(state => ({...state, whereAbout: "", backPath: "", homePath: ""}));
      }
      // eslint-disable-next-line
    }, []); 

    const handleChange = (inputKey, inputValue) => {
      setFormState({...formState, [inputKey]: inputValue});
    }

    const resetFormState = () => { 
      setFormState(initFormState);
      setCattleRecord(null);
    }

    // Making a custom handle submit, i.e vs generic, so as to handle local validations.
    const localHandleSubmit = (e) => {
      e.preventDefault();
      if (formState.girth_length > 277 || formState.girth_length < 68) {
        setResponseErrors({girth_length: [t('error_message.girth_length_out_of_range')]})
      } else {
        setResponseErrors({});
        handleSubmit(
          e,
          formState,
          createResource,
          envState.online,
          process.env.REACT_APP_CATTLE,
          setResponseErrors,
          setCreateNotice,
          resetFormState,
          publish
        )
      }
    }

    // Making a cattle record update as the formMode === 'Edit' 
    const localHandleUpdateSubmit = async (e) => {
      e.preventDefault();
      if (formState.girth_length > 277 || formState.girth_length < 68) {
        setResponseErrors({girth_length: [t('error_message.girth_length_out_of_range')]})
      } else {
        setResponseErrors({});

        // ExinController
        formState.trans_type = 'C';

        // how to check when successful vs failure
        handleSubmit(
          e,
          formState,
          createResource,
          envState.online,
          process.env.REACT_APP_TRANSACTIONS,
          setResponseErrors,
          setCreateNotice,
          resetFormState,
          publish,
          t
        ) 
      }
    }

    if (initFormState === null) {
      return <div>{t("error_message.loading")}</div>; // or any loading indicator
    }
    
    return (
        <div className="mt-[100px] mb-[100px]">
          <div className="md:grid md:grid-cols-2 md:gap-6">
            <div className="mt-5 md:col-span-2 md:mt-0">
              <form onSubmit={footerType === "update" ? localHandleUpdateSubmit : localHandleSubmit} autoComplete="off">
                <div className="overflow-hidden sm:rounded-md">
                  <div className="bg-white px-4 py-5 sm:p-6">
                    <div className="grid grid-cols-6 gap-6">
                      {/* Cattle ID / Tag - text */}
                      <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                        <InputLabel
                          inputId="cattleTag"
                          inputStyle="block text-sm font-medium text-gray-700"
                          labelText={ t('page_elements.tag')}
                        />
                        <TextInput
                          focus={true}
                          inputType="text"
                          inputName="id"
                          inputId="cattleTag"
                          hint={t('page_elements.enter_tag')}
                          inputPattern="^[a-zA-Z0-9]{1,10}$"
                          patternInfo={t('cattle_page.cattle_tag_pattern_info')}
                          inputStyle={textInputStyle.current.enabledInput}
                          inputValue={formState?.id}
                          handleChange={handleChange} 
                        />
                        {showFieldErrors(responseErrors.id)}
                      </div>
                      {/* Breed - Select */}
                      <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                        <InputLabel
                          inputId="breed"
                          inputStyle="block text-sm font-medium text-gray-700"
                          labelText={t('cattle_page.breed')}
                        />
                        <SelectInput
                            focus={false}
                            inputName="breed"
                            inputId="breed"
                            inputStyle={textInputStyle.current.enabledInput}
                            inputValue={formState?.breed}
                            handleChange={handleChange}
                            choices={[
                              {key: 'Ayrshire', label: t('cattle_page.ayrshire') },
                              {key: 'Friesian', label: t('cattle_page.friesian') },
                              {key: 'Fleckvieh', label: t('cattle_page.fleckvieh') },
                              {key: 'Guernsey', label: t('cattle_page.guernsey') },
                              {key: 'Jersey', label: t('cattle_page.jersey') },
                              {key: 'Hybrid', label: t('cattle_page.hybrid') },
                              {key: 'Brown Swiss', label: t('cattle_page.brown_swiss') },
                            ]}
                          />
                        {showFieldErrors(responseErrors.breed)}
                      </div>
                      {/* Gender - radio */}
                      <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                        <fieldset>
                          <legend className="contents text-sm font-semibold text-gray-900">{t('cattle_page.gender')}</legend>
                          <p className="text-sm text-gray-500">{t('cattle_page.determine_gender')}</p>
                          <div className="mt-4 space-y-4">
                            <div className="flex items-center">
                              <RadioInput
                                focus={false}
                                inputName="gender"
                                inputId="female"
                                inputStyle="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                inputValue="Female"
                                inputStateValue={formState?.gender}
                                handleChange={handleChange}
                              />
                              <InputLabel
                                inputId="female"
                                inputStyle="ml-3 block text-sm font-medium text-gray-700"
                                labelText={t('cattle_page.female')}
                              />
                            </div>
                            <div className="flex items-center">
                            <RadioInput
                              focus={false}
                              inputName="gender"
                              inputId="male"
                              inputStyle="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                              inputValue="Male"
                              inputStateValue={formState?.gender}
                              handleChange={handleChange}
                            />
                              <InputLabel
                                inputId="male"
                                inputStyle="ml-3 block text-sm font-medium text-gray-700"
                                labelText={t('cattle_page.male')}
                              />
                            </div>
                          </div>
                        </fieldset>
                        {showFieldErrors(responseErrors.gender)}
                      </div>
                      {/* State - select */}
                      <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                        <InputLabel
                          inputId="state"
                          inputStyle="block text-sm font-medium text-gray-700"
                          labelText={t('cattle_page.state')}
                        />
                        <SelectInput
                            focus={false}
                            inputName="state"
                            inputId="state"
                            inputStyle={textInputStyle.current.enabledInput}
                            inputValue={formState?.state}
                            handleChange={handleChange}
                            choices={[
                              {key: 'Heifer', label: t('cattle_page.heifer') },
                              {key: 'Bull', label: t('cattle_page.bull') },
                              {key: 'Steer', label: t('cattle_page.steer') },
                              {key: 'Weaner', label: t('cattle_page.weaner') },
                              {key: 'Calf', label: t('cattle_page.calf') },
                              {key: 'Pregnant', label: t('cattle_page.pregnant') },
                              {key: 'Lactating', label: t('cattle_page.lactating') },
                              {key: 'Lactating and Pregnant', label: t('cattle_page.lactating_and_pregnant') },
                              {key: 'Non Lactating', label: t('cattle_page.non_lactating') },
                              {key: 'Dried', label: t('cattle_page.dried') },
                            ]}
                          />
                        {showFieldErrors(responseErrors.state)}
                      </div>
                      {/* Name - text */}
                      <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                        <InputLabel
                          inputId="name"
                          inputStyle="block text-sm font-medium text-gray-700"
                          labelText={t('cattle_page.name')}
                        />
                        <TextInput
                          focus={false}
                          inputType="text"
                          inputName="name"
                          inputId="name"
                          hint={t('cattle_page.enter_name')}
                          inputStyle={textInputStyle.current.enabledInput}
                          inputValue={formState?.name}
                          handleChange={handleChange}
                        />
                        {showFieldErrors(responseErrors.name)}
                      </div>
                      {/* DOB - date */}
                      <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                        <InputLabel
                          inputId="dob"
                          inputStyle="block text-sm font-medium text-gray-700"
                          labelText={t('cattle_page.date_of_birth')}
                        />
                        <TextInput
                          focus={false}
                          inputType="date"
                          inputName="dob"
                          inputId="dob"
                          inputStyle={textInputStyle.current.enabledInput}
                          inputValue={formState?.dob}
                          handleChange={handleChange}
                        />
                        {showFieldErrors(responseErrors.dob)}
                      </div>

                      {/* Select weight measurement type i.e girth length or weight type */}
                        <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                          <InputLabel
                            inputId="measurement_type"
                            inputStyle="block text-sm font-medium text-gray-700 text-left"
                            labelText="Select measurement type"
                          />
                          <SelectInput
                            focus={false}
                            inputName="measurement_type"
                            inputId="measurement_type"
                            inputStyle={textInputStyle.current.enabledInput}
                            inputValue={formMode === 'edit' ? 'Weight' : formState?.measurement_type}
                            handleChange={handleChange}
                            choices={[
                              { key: 'Select', label: "Select measure type" },
                              { key: 'Girth', label: "Girth Length" },
                              { key: 'Weight', label: "Weight in Kg" },
                            ]}
                          />
                          <div className="response-error-message">
                            {showFieldErrors(responseErrors.measurement_type)}
                          </div>
                        </div>

                      {/** the following part should only be shown whem Milk is selected from the drop down */}
                        {/* {formState?.measurement_type === 'Weight' && ( */}
                        {(formMode === 'edit' || formState?.measurement_type === 'Weight') && (
                          <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                            <InputLabel
                              inputId="weight"
                              inputStyle="block text-sm font-medium text-gray-700 text-left"
                              labelText="Input Weight in Kg"
                            />
                            <NumberInput
                              focus={false}
                              inputName="weight"
                              inputId="weight"
                              hint="0"
                              isRequired={false}
                              isDisabled={false}
                              inputStyle={textInputStyle.current.enabledInput}
                              inputValue={formState?.weight}
                              inputStep="0.01"
                              handleChange={setFormState}
                            />                              
                            <div className="response-error-message">
                              {showFieldErrors(responseErrors.quantity)}
                            </div>
                          </div>
                        )}

                        {/* Girth Length - Number */}
                        {formState?.measurement_type === 'Girth' && (
                          <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                            <InputLabel
                              inputId="girth_length"
                              inputStyle="block text-sm font-medium text-gray-700"
                              labelText={t('table_formatter.table_girth_length')}
                            />
                            <NumberInput
                              focus={false}
                              inputName="girth_length"
                              inputId="girth_length"
                              inputStyle={textInputStyle.current.enabledInput}
                              inputValue={formState?.girth_length}
                              min={58}
                              handleChange={setFormState}
                            />
                            {showFieldErrors(responseErrors.girth_length)}


                            <InputLabel
                              inputId="weight"
                              inputStyle="block text-sm font-medium text-gray-700"
                              labelText={t('table_formatter.table_weight')}
                            />
                            <NumberInput
                              focus={false}
                              inputName="weight"
                              inputId="weight"
                              isDisabled={true}
                              inputStyle={textInputStyle.current.disabledInput}
                              inputValue={formState?.weight}
                              handleChange={setFormState}
                            />
                            {showFieldErrors(responseErrors.weight)}
                          </div>
              
                        )}
                      {/** the end of of the part that is measurement type from the drop down */}

                      {/* DOFE - date */}
                      <div className="col-span-6 sm:col-span-3">
                        <InputLabel
                          inputId="dofe"
                          inputStyle="block text-sm font-medium text-gray-700"
                          labelText={t('cattle_page.date_of_entry')}
                        />
                        <TextInput
                          focus={false}
                          inputType="date"
                          inputName="dofe"
                          inputId="dofe"
                          inputStyle={textInputStyle.current.enabledInput}
                          inputValue={formState?.dofe}
                          handleChange={handleChange}
                        />
                        {showFieldErrors(responseErrors.dofe)}
                      </div>
                      {/* Source Type - radio */}
                      <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                        <fieldset>
                          <legend className="contents text-sm font-semibold text-gray-900">{t('cattle_page.sourced_from')}</legend>
                          <p className="text-sm text-gray-500">{t('cattle_page.enter_cattle_source')}</p>
                          <div className="mt-4 space-y-4">
                            <div className="flex items-center">
                            <RadioInput
                              focus={false}
                              inputType="radio"
                              inputName="source_type"
                              inputId="internal"
                              inputStyle="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                              inputValue="Internal"
                              inputStateValue={formState?.source_type}
                              handleChange={handleChange}
                            />
                              <InputLabel
                                inputId="internal"
                                inputStyle="ml-3 block text-sm font-medium text-gray-700"
                                labelText={t('cattle_page.internal')}
                              />
                            </div>
                            <div className="flex items-center">
                            <RadioInput
                              focus={false}
                              inputType="radio"
                              inputName="source_type"
                              inputId="external"
                              inputStyle="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                              inputValue="External"
                              inputStateValue={formState?.source_type}
                              handleChange={handleChange}
                            />
                              <InputLabel
                                inputId="external"
                                inputStyle="ml-3 block text-sm font-medium text-gray-700"
                                labelText={t('cattle_page.external')}
                              />
                            </div>
                          </div>
                        </fieldset>
                        {showFieldErrors(responseErrors.source_type)}
                      </div>
                      {/* Sourced From - text */}
                      <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                        <InputLabel
                          inputId="source_name"
                          inputStyle="block text-sm font-medium text-gray-700"
                          labelText={t('cattle_page.sourced_from')}    
                        />
                        <TextInput
                          focus={false}
                          isDisabled={(formState?.source_type === 'Internal') ? true : false}
                          inputType="text"
                          inputName="source_name"
                          inputId="source_name"
                          hint={t('cattle_page.enter_sourced_from')} 
                          inputStyle={(formState?.source_type === 'Internal') ? textInputStyle.current.disabledInput : textInputStyle.current.enabledInput}
                          inputValue={formState?.source_name}
                          handleChange={handleChange}
                        />
                        {showFieldErrors(responseErrors.source_name)}
                      </div>
                      {/* Cattle ID at Source - text */}
                      <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                        <InputLabel
                          inputId="id_at_source"
                          inputStyle="block text-sm font-medium text-gray-700"
                          labelText={t('cattle_page.tag_at_source')} 
                        />
                        <TextInput
                          focus={false}
                          inputType="text"
                          inputName="id_at_source"
                          inputId="id_at_source"
                          hint={t('cattle_page.enter_tag_at_source')} 
                          inputStyle={textInputStyle.current.enabledInput}
                          inputValue={formState?.id_at_source}
                          handleChange={handleChange}
                        />
                        {showFieldErrors(responseErrors.id_at_source)}
                      </div>
                      {/* Mother ID - text */}
                      <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                        <InputLabel
                          inputId="mother_id"
                          inputStyle="block text-sm font-medium text-gray-700"
                          labelText={t('cattle_page.mother_tag')} 
                        />
                        <TextInput
                          focus={false}
                          inputType="text"
                          inputName="mother_id"
                          inputId="mother_id"
                          hint={t('cattle_page.enter_monther_tag')} 
                          inputStyle={textInputStyle.current.enabledInput}
                          inputValue={formState?.mother_id}
                          handleChange={handleChange}
                        />
                        {showFieldErrors(responseErrors.mother_id)}
                      </div>
                      {/* Father ID - text */}
                      <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                        <InputLabel
                          inputId="father_id"
                          inputStyle="block text-sm font-medium text-gray-700"
                          labelText={t('cattle_page.father_tag')} 
                        />
                        <TextInput
                          focus={false}
                          inputType="text"
                          inputName="father_id"
                          inputId="father_id"
                          hint={t('cattle_page.enter_father_tag')} 
                          inputStyle={textInputStyle.current.enabledInput}
                          inputValue={formState?.father_id}
                          handleChange={handleChange}
                        />
                        {showFieldErrors(responseErrors.father_id)}
                      </div>
                      {/* Notes - textarea */}
                      <div className="col-span-6">
                        <InputLabel
                          inputId="notes"
                          inputStyle="block text-sm font-medium text-gray-700"
                          labelText={t('search_page.search_notes')} 
                        />
                        <TextareaInput
                          focus={false}
                          inputName="notes"
                          inputId="notes"
                          hint={t('page_elements.enter_notes_about_cattle')} 
                          isRequired={false}
                          inputRows={3}
                          inputStyle={textInputStyle.current.enabledInput}
                          inputValue={formState?.notes}
                          handleChange={handleChange}
                        />
                        {showFieldErrors(responseErrors.notes)}
                      </div> 
                    </div>
                  </div>
                  {/* <Footer respNotice={createNotice} setRespNotice={setCreateNotice} footerType="create"/> */}
                    <Footer 
                      respNotice={createNotice} 
                      setRespNotice={setCreateNotice} 
                      footerType={footerType}
                      onEdit={props.onEdit}
                      editableRecord={formState}
                      editableRecordId={props.cattleData?.Created}
                    />
                </div>
              </form>
            </div>
                                        <div>
                                          {/* Toast Container */}
                                          <ToastContainer
                                              position="top-center"
                                              autoClose={5000}
                                              hideProgressBar={false}
                                              closeOnClick
                                              pauseOnHover
                                              draggable
                                              pauseOnFocusLoss
                                              theme="light"
                                          />
                                        </div>
          </div>
      </div>
    );
}