import React, { useLayoutEffect, useState, useEffect, useContext, useCallback } from "react";
import { HamburgerMenuItem, handleMenuItemClick } from "./HamburgerMenuItem";
import { clearQueue, getQueueLength, editQueue } from "../services/queue";
import { messaging, getFCMToken, firebaseInit } from '../firebase';
import OutsideClickHandler from "react-outside-click-handler";
import { Link, Outlet, useNavigate } from "react-router-dom";
import { NotificationContext } from "./NotificationContext";
import { guestCreateResource } from "../services/farmApi";
import { ToastContainer, toast } from "react-toastify";
import gdfProjectLogo from "../icons/gdfproject.png";
import { useTranslation } from "react-i18next";
import "react-toastify/dist/ReactToastify.css";
import { useUser, useEnv } from "../contexts";
import { ConsoleLogEvent } from "../utils";
import { useLogout } from "../hooks";
import Cookies from "js-cookie";

import {
  WifiIcon,
  ArrowPathRoundedIcon,
  ArrowPathRoundedSmallerIcon,
  LockOpenIcon,
  NoSymbolIcon,
  ArrowSmallLeftIcon,
  HomeIcon,
  InformationCircleIcon,
  // icons used in the hamburger menu
  HamburgerMenuIcon,
  LockSolidOpenedIcon,
  DairyFarmingIcon,
  LanguageIcon,
  ShareIcon,
  ManualIcon,
  TermsAndConditionIcon,
  AboutUsIcon,
  UpdateUsersIcon,
  FeedbackIcon,
  DisclaimerIcon,
  FaqIcon,
  PrivatePolicy,
} from "../icons/";

const HamburgerMenu = ({ isOpen, onClose, onChangeLanguage }) => {
  const { user, setUser } = useUser();
  const { envState } = useEnv();
  const { logoutUser } = useLogout();

  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  // ----------------- start language change part ---------------------------
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  // Modal visibility state
  const [showLanguageModal, setShowLanguageModal] = useState(false);

  // Functions to show and close the modal
  // const handleChangeLanguageClick = () => setShowLanguageModal(true);
  const closeModal = () => {
    setShowLanguageModal(false); // Hide the modal
    onClose(); // Close the HamburgerMenu
  };
  
  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
    onClose();
  };

  // ----------------- end language change part ---------------------------

  // Queue count state
  const [queueCount, setQueueCount] = useState(getQueueLength() ?? 0);

  useLayoutEffect(() => {
    const handleQueueChange = () => {
      setQueueCount(() => getQueueLength());
    };
    document.addEventListener("queueing", handleQueueChange, true);
    return document.removeEventListener("queueing", handleQueueChange);
  }, []);

  useEffect(() => {
    // Listen for changes in queueCount
    const handleQueueChange = () => {
      setQueueCount(getQueueLength());
    };
    document.addEventListener("queueing", handleQueueChange, true);
    return () => document.removeEventListener("queueing", handleQueueChange);
  }, [queueCount]); // Run the effect when queueCount changes

  const handleClearQueue = async () => {
    try {
      const failedCount = await clearQueue();
      setQueueCount(failedCount);
  
      if (failedCount > 0) {
        await editQueue(failedCount, navigate, t, user.id)
          .then((newQueueLength) => {
                  setQueueCount(newQueueLength);
                })
                .catch((error) => {
                  // console.log("Got error in clearing queue", error);
                });
      } 

    } catch (error) {
      // console.log("Got error in clearing queue", error);
    }
  };

  const closeMenuAndNavigate = async (to) => {
    onClose(); // Close the menu
    if (to === "/logout") await handleLogout();
    if (to === "/clearQueue" && queueCount > 0) await handleClearQueue();
    handleMenuItemClick(to, navigate, t, queueCount, showToast, i18n); // Navigate to the desired route
  };

  // The handleLogout function remains the same
  const handleLogout = async () => {
    await logoutUser(setUser);
  };

  const showToast = (message, type) => {
    toast[type](message, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };

  return (
    <OutsideClickHandler onOutsideClick={onClose}>
      <div
        className={`fixed hamburgerCustomTop hamburgerCustomHeight left-0 h-full w-64 bg-blue-200 p-2 rounded-md border border-gray-400 transform transition-transform duration-300 ease-in-out ${
          isOpen ? "translate-x-0" : "translate-x-full"
        } z-50`}
      >
        <div className="flex items-center justify-between">
          {/* Logo */}
          <div className="flex justify-center ml-16">
            <img
              src={gdfProjectLogo}
              className="w-[100px] h-auto"
              alt="GDF 512x512 sized logo"
            />
          </div>
          <button
            onClick={handleMenuToggle}
            className="text-gray-600 text-3xl hover:text-gray-800 focus:outline-none"
          >
            &#10005;
          </button>
        </div>
        <div className="flex flex-col mt-6">
          {/* Horizontal line */}
          <div className="border-t border-gray-400 my-2"></div>
          <h2>{t("hamburger_messages.farm_account")}</h2>

          <HamburgerMenuItem
            to="/logout"
            onClick={() => closeMenuAndNavigate("/logout")}
          >
            <div className="flex items-center">
              <LockSolidOpenedIcon />
              <span className="ml-1">{t("hamburger_messages.logout")}</span>
            </div>
          </HamburgerMenuItem>

          <HamburgerMenuItem
            to="/updateUsers"
            onClick={() => closeMenuAndNavigate("/updateUsers")}
          >
            <div className="flex items-center">
              <UpdateUsersIcon />
              <span className="ml-1">
                {t("hamburger_messages.update_users")}
              </span>
            </div>
          </HamburgerMenuItem>

            <HamburgerMenuItem>
              {/* Button that triggers the modal */}
              {/* <button onClick={handleChangeLanguageClick} className="flex items-center"> */}
              <button onClick={onChangeLanguage} className="flex items-center">
                <LanguageIcon />
                <span className="ml-1">{t("hamburger_messages.change_language")}</span>
              </button>
            </HamburgerMenuItem>

            {/* Conditionally render the modal if `showLanguageModal` is true */}
            {showLanguageModal && <LanguageForm onClose={closeModal} />}

          <HamburgerMenuItem
            to={envState.online ? "/fe/ClearQueue" : undefined}
            onClick={envState.online ? () => closeMenuAndNavigate("/clearQueue") : undefined}
            className={envState.online ? '' : 'pointer-events-none opacity-50'}
          >
            <div className="flex items-center">
              <ArrowPathRoundedSmallerIcon />
              {queueCount > 0 ? (
                <span className="absolute -top-0.1 left-[18px] w-[20px] h-[20px] flex justify-center items-center text-xxs font-bold rounded-full text-red-500 bg-slate-100 bg-opacity-20">
                  {queueCount >= 0 && queueCount < 10 ? `0${queueCount}` : queueCount}
                </span>
              ) : (
                <span className="absolute -top-0.1 left-[18px] w-[20px] h-[20px] flex justify-center items-center text-xxs font-bold rounded-full text-gray-500 bg-slate-100 bg-opacity-20">
                  {queueCount >= 0 && queueCount < 10 ? `${queueCount}` : queueCount}
                </span>
              )}
              <span className="ml-1">
                {t("hamburger_messages.upload_queue_data")}
              </span>
            </div>
          </HamburgerMenuItem>

          {/* Horizontal line */}
          <div className="border-t border-gray-400 my-2"></div>
          <h2>{t("hamburger_messages.preferences")}</h2>
          <HamburgerMenuItem
            to="/share"
            onClick={() => closeMenuAndNavigate("/share")}
          >
            <div className="flex items-center">
              <ShareIcon />
              <span className="ml-1">{t("hamburger_messages.share")}</span>
            </div>
          </HamburgerMenuItem>

          <HamburgerMenuItem
            to="/appmanual"
            onClick={() => closeMenuAndNavigate("/appmanual")}
          >
            <div className="flex items-center">
              <ManualIcon />
              <span className="ml-1">{t("hamburger_messages.manual")}</span>
            </div>
          </HamburgerMenuItem>

          <HamburgerMenuItem
            to="/tandcs"
            onClick={() => closeMenuAndNavigate("/tandcs")}
          >
            <div className="flex items-center">
              <TermsAndConditionIcon />
              <span className="ml-1">{t("hamburger_messages.tandcs")}</span>
            </div>
          </HamburgerMenuItem>

          <HamburgerMenuItem
            to="/dairyfarming"
            onClick={() => closeMenuAndNavigate("/dairyfaming")}
          >
            <div className="flex items-center">
              <DairyFarmingIcon />
              <span className="ml-1">
                {t("hamburger_messages.dairyfarming")}
              </span>
            </div>
          </HamburgerMenuItem>

          <HamburgerMenuItem
            to="/faq"
            onClick={() => closeMenuAndNavigate("/faq")}
          >
            <div className="flex items-center">
              <FaqIcon />
              <span className="ml-1">{t("hamburger_messages.faq")}</span>
            </div>
          </HamburgerMenuItem>

          {/* Horizontal line */}
          <div className="border-t border-gray-400 my-2"></div>
          <h2>{t("hamburger_messages.gdf_project")}</h2>
          <HamburgerMenuItem
            to="/aboutUs"
            onClick={() => closeMenuAndNavigate("/aboutUs")}
          >
            <div className="flex items-center">
              <AboutUsIcon />
              <span className="ml-1">{t("hamburger_messages.about_us")}</span>
            </div>
          </HamburgerMenuItem>

          <HamburgerMenuItem
            to="/feedback"
            onClick={() => closeMenuAndNavigate("/feedback")}
          >
            <div className="flex items-center">
              <FeedbackIcon />
              <span className="ml-1">{t("hamburger_messages.feedback")}</span>
            </div>
          </HamburgerMenuItem>

          <HamburgerMenuItem
            to="/disclaimer"
            onClick={() => closeMenuAndNavigate("/disclaimer")}
          >
            <div className="flex items-center">
              <DisclaimerIcon />
              <span className="ml-1">{t("hamburger_messages.disclaimer")}</span>
            </div>
          </HamburgerMenuItem>

          <HamburgerMenuItem
            to="/privacy"
            onClick={() => closeMenuAndNavigate("/privacy")}
          >
            <div className="flex items-center">
              <PrivatePolicy />
              <span className="ml-1">{t("terms_and_conditions.privacy_policy")}</span>
            </div>
          </HamburgerMenuItem>
        </div>
      </div>
    </OutsideClickHandler>
  );
};

const Header = () => {
  const { user, setUser } = useUser();
  const { envState } = useEnv();
  const { logoutUser } = useLogout();

  const { t } = useTranslation();

  const navigate = useNavigate();

  const handleLogout = async () => {
    await logoutUser(setUser);
  };

  // Queue count state
  const [queueCount, setQueueCount] = useState(getQueueLength() ?? 0);

  // Register event handlers to capture the 'queueing' event
  useLayoutEffect(() => {
    const handleQueueChange = () => {
      // console.log("sensed the event...can we work?")
      setQueueCount(() => getQueueLength());
    };
    document.addEventListener("queueing", handleQueueChange, true);
    return document.removeEventListener("queueing", handleQueueChange);
  }, []);

  useEffect(() => {
    // Listen for changes in queueCount
    const handleQueueChange = () => {
      setQueueCount(getQueueLength());
    };
    document.addEventListener("queueing", handleQueueChange, true);
    return () => document.removeEventListener("queueing", handleQueueChange);
  }, [queueCount]); // Run the effect when queueCount changes

  const handleClearQueue = async () => {
    try {
      const failedCount = await clearQueue();
      setQueueCount(failedCount);
  
      if (failedCount > 0) {
        await editQueue(failedCount, navigate, t, user.id)
          .then((newQueueLength) => {
                  setQueueCount(newQueueLength);
                })
                .catch((error) => {
                  // console.log("Got error in clearing queue", error);
                });
      } 

    } catch (error) {
      // console.log("Got error in clearing queue", error);
    }
  };

  // implementing toast to be called from HamburgerMenuItem
  const showToast = (message, type) => {
    toast[type](message, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };

  // ---------------- start new notification implementation -----------------------------
      const { notification, setNotification } = useContext(NotificationContext);
      const [permissionStatus, setPermissionStatus] = useState(Notification.permission);

      // polling mechanism to monitor changes in the Notification.permission status.
      // I need this so that when permission is changed from settings, I can hide/unhide notificationIcon
      const checkNotificationPermission = useCallback(() => {
        const currentPermission = Notification.permission;
        if (currentPermission !== permissionStatus) {
          setPermissionStatus(currentPermission);
        }
      }, [permissionStatus]);
    
      useEffect(() => {
        // Poll for changes in notification permissions
        const intervalId = setInterval(checkNotificationPermission, 3000); // Check every 3 seconds
    
        return () => clearInterval(intervalId); // Cleanup the interval on component unmount
      }, [checkNotificationPermission]);

      // Handle Firebase notification initialization
      useEffect(() => {
        if (permissionStatus === 'granted') {
          firebaseInit(setNotification);
        }
      }, [permissionStatus, setNotification]);

      // Function to handle sending token to the server
      const sendTokenToServer = async (userId, token) => {
        ConsoleLogEvent('info', 'fcmToken to store into a database.', token);

        try {
          const regResp = await guestCreateResource(
            process.env.REACT_APP_AUTH_UPDATE_DEVICE_TOKEN,
            {
              fcmToken: token,
              user_id: userId, // Use the actual user id here
            }
          );
          if (regResp?.success) {
            ConsoleLogEvent('info', 'fcmToken stored in a database.', token);
          } else {
            ConsoleLogEvent('warn', 'fcmToken failed storing in database.', token);
          }
        } catch (error) {
          ConsoleLogEvent('error', 'Network error while saving fcmToken', error);
          showToast('Failed to update notification settings on the server.', 'error');
        }
      };

      // Function to request notification permission and get FCM token
      const requestNotificationPermission = useCallback(async () => {
        // Check if permission is already granted
        if (permissionStatus === 'granted') {
          // Check if FCM token is already stored
          // const existingToken = localStorage.getItem('gdfFcmToken');
          const existingToken = localStorage.getItem(`gdfFcmToken_${user.id}`);

          
          if (!existingToken) {
            try {
              // Register the service worker and fetch the FCM token
              await handleFCMTokenRequest();
            } catch (error) {
              ConsoleLogEvent('error', 'Error during FCM token request:', error);
              showToast('Failed to enable notifications. Please try again.', 'error');
            }
          }else{
            // token is available, just refresh the page, it will hide this button
            window.location.reload();
          }
        } else if (permissionStatus === 'denied') {
          ConsoleLogEvent('warn', 'Notifications are disabled.');
          showToast('Notifications are disabled. Please enable them in browser settings.', 'info');
        } else if (permissionStatus === 'default') {
          try {
            // Request notification permission from the user
            const newPermission = await Notification.requestPermission();
            if (newPermission === 'granted') {
              // Register the service worker and fetch the FCM token
              await handleFCMTokenRequest();
            } else {
              ConsoleLogEvent('warn', 'User denied notification permission request.');
              showToast('Notifications are disabled. Please enable them in browser settings.', 'info');
            }
          } catch (error) {
            ConsoleLogEvent('error', 'Error requesting notification permission:', error);
            showToast('An error occurred. Please try again later.', 'error');
          }
        }
      }, [permissionStatus, user?.id]);
      
      // Function to handle FCM token request and service worker registration
      const handleFCMTokenRequest = async () => {
        try {
          // Register the service worker
          const swRegistration = await navigator.serviceWorker.register('/fe/firebase-messaging-sw.js', { scope: '/fe/' });
          ConsoleLogEvent('log', 'Service Worker registered successfully:', swRegistration);
      
          // Fetch the FCM token using VAPID key
          const fcmToken = await getFCMToken(messaging, {
            vapidKey: process.env.REACT_APP_VAPID_KEY,
            serviceWorkerRegistration: swRegistration,
          });
      
          if (fcmToken) {
            // Store the FCM token in localStorage
            // localStorage.setItem('gdfFcmToken', fcmToken);
            localStorage.setItem(`gdfFcmToken_${user.id}`, fcmToken);

            ConsoleLogEvent('info', 'New FCM token obtained:', fcmToken);
      
            // Send token to the server if the user is authenticated
            if (user?.id) {
              await sendTokenToServer(user.id, fcmToken);
            }
          }
        } catch (error) {
          ConsoleLogEvent('error', 'Error obtaining FCM token or registering service worker:', error);
          throw new Error('FCM token or service worker registration failed');
        }
      };
      
      // Check notification permission on component load
      useEffect(() => {
        // const existingToken = localStorage.getItem('gdfFcmToken');
        const existingToken = localStorage.getItem(`gdfFcmToken_${user.id}`);


        if (!existingToken) {
          requestNotificationPermission();
        } else {
          ConsoleLogEvent('info', 'FCM token already exists:', existingToken);
        }
      }, [requestNotificationPermission]);

      // Handle incoming notifications
      useEffect(() => {
        if (notification && notification.title) {
          const message = `${notification.title}: ${notification.body}`;
          showToast(message, 'info');
          ConsoleLogEvent('info', 'Notification received:', notification);
        }
      }, [notification]);

  // ---------------- end new notification implementation -------------------------------

  // // Network status Indicator for notification
  const notificationIcon = permissionStatus !== 'granted' ? (
    <div
      className="flex flex-row justify-center items-center text-gray-800 h-full p-2 border-l-2 border-gray-400 cursor-pointer"
      onClick={requestNotificationPermission} // Handle click to request permission
    >
      <InformationCircleIcon />
    </div>
  ) : null; // If permission is granted, show nothing

  // Network status Indicator
  const netStatusIcon = envState.online ? (
    <div className="flex flex-row justify-center items-center text-gray-800 h-full p-2 border-l-2 border-gray-400">
      <WifiIcon />
    </div>
  ) : (
    <div className="flex flex-row justify-center items-center text-gray-400 h-full p-2 border-l-2 border-gray-400">
      <NoSymbolIcon />
    </div>
  );

  const queueIcon =
  queueCount > 0 ? (
    <div
      className={`flex flex-row justify-center items-center h-full p-2 border-l-2 border-gray-400 cursor-pointer ${envState.online ? '' : 'pointer-events-none opacity-50'}`}
      onClick={envState.online ? handleClearQueue : undefined}
    >
      <ArrowPathRoundedIcon />
      <span className="absolute -top-0.1 -right-0.1 w-[20px] h-[20px] flex justify-center items-center text-sm font-bold rounded-full text-red-500 bg-slate-100 bg-opacity-20">
        {queueCount >= 0 && queueCount < 10 ? `0${queueCount}` : queueCount}
      </span>
    </div>
  ) : (
    <div className="flex flex-row justify-center items-center text-gray-400 h-full p-2 border-l-2 border-gray-400">
      <ArrowPathRoundedIcon />
      <span className="absolute -top-0.1 -right-0.1 w-[20px] h-[20px] flex justify-center items-center text-sm font-bold rounded-full text-gray-500 bg-slate-100 bg-opacity-20">
        {queueCount}
      </span>
    </div>
  );

  // Back Navigation Button
  const backBtn = envState.backPath ? (
    <div className="flex flex-row justify-center items-center text-gray-400 h-full p-2">
      <Link to={envState.backPath}>
        <ArrowSmallLeftIcon />
      </Link>
    </div>
  ) : (
    ""
  );
  // Home Navigation Button
  const homeBtn = envState.homePath ? (
    <div className="flex flex-row justify-center items-center text-gray-400 h-full p-2">
      <Link to={envState.homePath}>
        <HomeIcon />
      </Link>
    </div>
  ) : (
    ""
  );
  // Message Button
  const msgButton = envState.whereAbout ? (
    <span className="uppercase">{envState.whereAbout}</span>
  ) : (
    // <span className="mr-2"> {t('home_page.home_page_title')}{user.firstName}</span>
    <span className="mr-2">
        {t('home_page.home_page_title')}
        {'! '}
        {user.firstName
            ? `${user.firstName.charAt(0).toUpperCase()}${user.firstName.length > 10 ? user.firstName.slice(1, 10) + '...' : user.firstName.slice(1)}`
            : ''}
    </span>
  );

  // ------------------ start language change part -----------------
  const onClose = () => {
    setIsMenuOpen(false);
  };

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isLanguageModalOpen, setIsLanguageModalOpen] = useState(false);

  // Toggles the Hamburger Menu
  const handleMenuToggle = () => setIsMenuOpen((prev) => !prev);

  // Closes the Hamburger Menu
  const handleCloseMenu = () => setIsMenuOpen(false);

  // Handles the "Change Language" click
  const handleChangeLanguageClick = () => {
    handleCloseMenu(); // Close the Hamburger Menu
    setTimeout(() => {
      setIsLanguageModalOpen(true); // Open the Language Modal
    }, 300); // Delay to allow the menu animation to complete
  };

  // Closes the Language Modal
  const closeLanguageModal = () => setIsLanguageModalOpen(false);

  // ------------------- end language change part -----------------

  return (
    <>
      <header className="flex flex-col fixed w-screen top-0 z-50">
        <div className="flex flex-row h-16 justify-end items-center bg-blue-200">
          <div className="flex flex-row justify-center items-center h-full w-full">
            <div className="h-full flex justify-start items-center divide-x-2 divide-gray-400">
              <div
                onClick={handleMenuToggle}
                className="flex flex-row justify-center items-center text-gray-400 h-full p-2 cursor-pointer"
              >
                <HamburgerMenuIcon />
              </div>
              {backBtn}
              {homeBtn}
            </div>
            <div className="grow h-full flex justify-center items-center"></div>
          </div>
          <div className="flex flex-row justify-center items-center text-gray-400 h-full p-2">
            <span className="text-slate-500 font-semibold text-lg">
              {/* // TODO:v update this version before building and releasing*/}
              v30.3.5
            </span>
          </div>
          {notificationIcon}
          {netStatusIcon}
          {queueIcon}
          <div
            onClick={handleLogout}
            className="flex flex-row justify-center items-center text-red-600 h-full p-2 border-l-2 border-gray-400 cursor-pointer"
          >
            <LockOpenIcon location="header" />
          </div>
        </div>
        <div className="h-7 flex flex-row justify-center">
          <span className="bg-slate-500 pt-[2px] text-md text-gray-100 font-semibold w-1/2 text-center rounded-b-lg">
            {msgButton}
          </span>
        </div>
        <div>
          {/* Toast Container */}
          <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            closeOnClick
            pauseOnHover
            draggable
            pauseOnFocusLoss
            theme="light"
          />
        </div>
      </header>
      {isMenuOpen && (
        <HamburgerMenu
          isOpen={isMenuOpen}
          onClose={handleCloseMenu}
          showToast={showToast}
          onChangeLanguage={handleChangeLanguageClick} 
        />
      )}

      {/* Language Modal */}
      {isLanguageModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
          <div className="bg-white rounded-lg p-6 w-full max-w-sm">
            <LanguageForm onClose={closeLanguageModal} />
          </div>
        </div>
      )}
      <Outlet />
    </>
  );
};

export { Header };


// Modal Component for Language Selection
const LanguageForm = ({ onClose }) => {
  const { t, i18n } = useTranslation();

  const languages = [
    { name: " \uD83C\uDDEC\uD83C\uDDE7 English", code: "en" },
    { name: "\uD83C\uDDF9\uD83C\uDDFF Kiswahili", code: "sw" },
  ];

  const currentLocale = Cookies.get("gdf_i18next") || "en";
  const [language, setLanguage] = useState(currentLocale);

  const handleChangeLocale = (e) => {
    const lang = e.target.value;
    if (lang !== language) {
      Cookies.set('gdf_i18next', lang, { expires: 365, sameSite: 'None', secure: true });
      setLanguage(lang);
      i18n.changeLanguage(lang);
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
      <div className="bg-white rounded-lg p-6 w-full max-w-sm">
        <h2 className="text-xl font-semibold text-center mb-4">
          {t('hamburger_messages.change_language_information')}
        </h2>
        
        <div className="flex justify-center">
          <select
            className="block border-gray-300 shadow-sm focus:border-slate-300 focus:ring-slate-300 sm:text-sm"
            id="lang"
            name="lang"
            onChange={handleChangeLocale}
            value={language}
          >
            {languages.map(({ name, code }) => (
              <option key={code} value={code}>
                {name}
              </option>
            ))}
          </select>
        </div>
        <div className="flex justify-center mt-4">
          <button
            className="px-4 py-2 bg-gray-400 text-white rounded-lg hover:bg-slate-400 ocus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-slate-500"
            onClick={onClose}
          >
            {t('buttons_navigation.button_agree_ok')}
          </button>
        </div>
      </div>
    </div>
  );
};

export  { HamburgerMenu };
