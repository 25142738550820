// This is a generic form text and email input
// The idea is to use this wherever a text input is required

import { useTranslation } from 'react-i18next';

export const TextInput = (props) => {
  const { t } = useTranslation();

  const handleInputChange = (e) => {
    if (props.inputType === 'file') {
      const fileList = e.target.files; 
      const file = fileList.length > 0 ? fileList[0] : null; 
      props.handleChange(props.inputName, file); 
    } else {
        const inputValue = e.target.value;
        props.handleChange(props.inputName, inputValue);

        // Check if the input value is empty
        if (props.isRequired && !inputValue.trim()) {
          e.target.setCustomValidity(t('validation_messages.required_field'));
        } else if (inputValue.trim() === '') {
          e.target.setCustomValidity(t('validation_messages.required_field'));
        } else {
          e.target.setCustomValidity('');
        }
    }
  };

  const handleBlur = (e) => {
    // Check for pattern mismatch on blur
    if (props.inputPattern) {
      const isValid = e.target.checkValidity();

      if (!isValid) {
        e.target.setCustomValidity(t('validation_messages.pattern_mismatch'));
      } else {
        e.target.setCustomValidity('');
      }

      // Call handlePatternMismatch if provided
      if (props.handlePatternMismatch) {
        props.handlePatternMismatch(!isValid);
      }
    }
  };

  return (
    <>
      <input
        autoFocus={props.focus}
        type={props.inputType}
        name={props.inputName}
        id={props.inputId}
        placeholder={props.hint}
        autoComplete={props.isAutoComplete ?? 'off'}
        required={props.isRequired ?? true}
        disabled={props.isDisabled ?? false}
        pattern={props.inputPattern ?? null}
        className={props.inputStyle}
        value={props.inputType !== 'file' ? props.inputValue : undefined} // Ensure value is not set for file input
        // value={props.inputValue}
        onChange={handleInputChange}
        onBlur={handleBlur}
        maxLength={props.maxLength}
      />
      {props.patternInfo ? (
        <div className="text-gray-400 pl-2">{props.patternInfo}</div>
      ) : (
        ''
      )}
      {!props.isDisabled && props.isRequired ? (
        <div className="text-red-600 pl-2">
          {t('validation_messages.required_field')}
        </div>
      ) : (
        ''
      )}
    </>
  );
};

// import { useTranslation } from 'react-i18next';

// export const TextInput = (props) => {
//   const { t } = useTranslation();

//   const handleInputChange = (e) => {
//     if (props.inputType === 'file') {
//       const fileList = e.target.files; 
//       // Check if fileList is defined and has at least one file
//       const file = fileList && fileList.length > 0 ? fileList[0] : null; 
//       props.handleChange(props.inputName, file); 
//     } else {
//       const inputValue = e.target.value;
//       props.handleChange(props.inputName, inputValue);

//       // Check if the input value is empty
//       if (props.isRequired && !inputValue.trim()) {
//         e.target.setCustomValidity(t('validation_messages.required_field'));
//       } else if (inputValue.trim() === '') {
//         e.target.setCustomValidity(t('validation_messages.required_field'));
//       } else {
//         e.target.setCustomValidity('');
//       }
//     }
//   };

//   const handleBlur = (e) => {
//     // Check for pattern mismatch on blur
//     if (props.inputPattern) {
//       const isValid = e.target.checkValidity();

//       if (!isValid) {
//         e.target.setCustomValidity(t('validation_messages.pattern_mismatch'));
//       } else {
//         e.target.setCustomValidity('');
//       }

//       // Call handlePatternMismatch if provided
//       if (props.handlePatternMismatch) {
//         props.handlePatternMismatch(!isValid);
//       }
//     }
//   };

//   return (
//     <>
//       <input
//         autoFocus={props.focus}
//         type={props.inputType}
//         name={props.inputName}
//         id={props.inputId}
//         placeholder={props.hint}
//         autoComplete={props.isAutoComplete ?? 'off'}
//         required={props.isRequired ?? true}
//         disabled={props.isDisabled ?? false}
//         pattern={props.inputPattern ?? null}
//         className={props.inputStyle}
//         // Ensure value is not set for file input
//         value={props.inputType !== 'file' ? props.inputValue : undefined} 
//         onChange={handleInputChange}
//         onBlur={handleBlur}
//         maxLength={props.maxLength}
//       />
//       {props.patternInfo ? (
//         <div className="text-gray-400 pl-2">{props.patternInfo}</div>
//       ) : null}
//       {!props.isDisabled && props.isRequired ? (
//         <div className="text-red-600 pl-2">
//           {t('validation_messages.required_field')}
//         </div>
//       ) : null}
//     </>
//   );
// };
