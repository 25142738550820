import React, { useState, useEffect, useRef } from 'react';
import { createResource, getResource } from '../services/farmApi';
import { Footer, EditDelTableDataView } from '../components';
import TabNavItem from "../components/forms/TabNavItem";
import TabContent from "../components/forms/TabContent";
import { toast, ToastContainer } from 'react-toastify';
import { useEvents, useHandleSubmit } from '../hooks';
import 'react-toastify/dist/ReactToastify.css';
import { useTranslation } from "react-i18next";
import { useUser, useEnv } from '../contexts';
import "./reports/reportStyle.css";
import "../tabsview.css";
import Swal from 'sweetalert2';
import { ConsoleLogEvent } from '../utils';
import { sendMail } from '../services/farmApi';

import { 
    TRANS_TYPE_BOARD_BLOCK_CONTACT_ITEMS,
    TRANS_TYPE_BOARD_BLOCK_CONTACT_CATTLE,
    TRANS_TYPE_BOARD_CONTACT_ITEMS,
    TRANS_TYPE_BOARD_CONTACT_CATTLE,
    QUERY_FILTER_BOARD_LIST_CATTLE,
    QUERY_FILTER_BOARD_LIST_ITEMS
 } from '../constants';


const OpenBoardForm = () => {
        // for language translation
        const { t } = useTranslation();
        const { user } = useUser();  // Get currently loggedIn user
        const [respNotice, setRespNotice] = useState({ isSubmitting: false });

        const showToast = (message, type) => {
            toast[type](message, {
            position: "top-center", 
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            });
        };

        const [tableSettings, setTableSettings] = useState({
            idUsage: 1,
            useSerial: true,
        });

        const initNotice = useRef({
            msg: "",
            showNotice: false,
            succ: false,
            status: null,
            ver: null,
        });

        const [createNotice, setCreateNotice] = useState({showNotice: false, succ: false, msg: ""});
        const [selectedId, setSelectedId] = useState(0);
        const [footerType, setFooterType] = useState("contactPerson");
        const { publish } = useEvents(); // publish - dispatches events
        const { handleSubmit } = useHandleSubmit(); // Shared submit handler
        const [responseErrors, setResponseErrors] = useState({});
        const {envState, setEnvState } = useEnv(); // Get Environment context
        const [boardCattleData, setBoardCattleData] = useState([]);
        const [boardItemsData, setBoardItemsData] = useState([]);
        const [activeTab, setActiveTab] = useState("tab1");
        const [loading, setLoading] = useState(false); // Loading state
        const [errorMessage, setErrorMessage] = useState(null); // Error message state
        const { handleGetResource } = useHandleSubmit(); // Shared submit handler
        const [initFormState, setInitFormState] = useState(null);
        const resetFormState = () => { setFormState(initFormState) }

        // ------------- initialize backend calls --------------------

        // Setting initial form state in useEffect
        useEffect(() => {
            const initFormState = {
                trans_type: '',         // transaction id
                list_id: '',            // advert id
                contact_id: '',         // email address as an identifier of the purchaser or supplier
                auto_inc_id: '',        // auto increment id in supplier or purchaser table
                seller_id: user.email,  // seller or requester identifier 
                user_id: user.id,       // assuming user is defined in the scope
            };
            setInitFormState(initFormState);
            setFormState(initFormState); // Set formState when initFormState is set
        }, []);

        const [formState, setFormState] = useState({
            trans_type: '',         // transaction id
            list_id: '',            // advert id
            contact_id: '',         // email address as an identifier of the purchaser or supplier
            auto_inc_id: '',        // auto increment id in supplier or purchaser table
            seller_id: user.email,  // seller or requester identifier 
            user_id: user.id,       // assuming user is defined in the scope
        });

        useEffect(() => {
            getBoardCattleList(); // default call when screen opens
        }, [envState.online, formState]);

        //-------------- start listing cattle if any ------------------------
        const getBoardCattleList = async () => {
            setLoading(true);  // Start loading

            const reportQueryItem = "/?filter=" + QUERY_FILTER_BOARD_LIST_CATTLE;

            try {
                handleGetResource(
                    null,
                    getResource,
                    envState.online,
                    process.env.REACT_APP_MARKET_PLACE + reportQueryItem,
                    setCreateNotice,
                    null,
                    setBoardCattleData, // This will update boardData
                    null,
                    false
                );
                setErrorMessage(null); // Clear any previous errors

            } catch (error) {
                // console.error('Error fetching report data:', error);
                setErrorMessage(t('market_place_page.failed_fetch_data'));
            } finally {
                setLoading(false); // Stop loading
            }
        };

        const formattedCattleData = (boardCattleData.returned_records || []).map(boardCattle => {
            const formattedCattleInterestDate = new Date(boardCattle.interest_date).toISOString().split('T')[0];
            return {
                id: boardCattle.id, // use id to get the list_id after select
                list_id: boardCattle.list_id,
                description: boardCattle.description,
                interest_date: formattedCattleInterestDate,
                // purchaser_id: boardCattle.purchaser_id,
                contact_id: boardCattle.contact_id,
                contacted: boardCattle.contacted === '0' ? 'No' : 'Yes',
                blocked: boardCattle.blocked === '0' ? 'No' : 'Yes',
            };
        });

        //-------------- end listing cattle if any ------------------------

        //-------------- start listing items if any ------------------------
        
        const getBoardItemsList = async () => {
            setLoading(true);  // Start loading

            const reportQueryItem = "/?filter=" + QUERY_FILTER_BOARD_LIST_ITEMS;

            try {
                handleGetResource(
                    null,
                    getResource,
                    envState.online,
                    process.env.REACT_APP_MARKET_PLACE + reportQueryItem,
                    setCreateNotice,
                    null,
                    setBoardItemsData, // This will update boardData
                    null,
                    false
                );
                setErrorMessage(null); // Clear any previous errors
            } catch (error) {
                // console.error('Error fetching report data:', error);
                setErrorMessage(t('market_place_page.failed_fetch_data'));
            } finally {
                setLoading(false); // Stop loading
            }
        };

        const formattedItemsData = (boardItemsData.returned_records || []).map(boardItems => {
            const formattedItemsInterestDate = new Date(boardItems.interest_date).toISOString().split('T')[0];
            return {
                id: boardItems.id, // use id to get the list_id after select
                list_id: boardItems.list_id,
                description: boardItems.description,
                interest_date: formattedItemsInterestDate,
                // supplier_id: boardItems.supplier_id,
                contact_id: boardItems.contact_id,
                contacted: boardItems.contacted === '0' ? 'No' : 'Yes',
                blocked: boardItems.blocked === '0' ? 'No' : 'Yes',
            };
        });        

        //-------------- end listing items if any ------------------------


        // ------------ start common effect for cattle items ----------------

        useEffect(() => {
            ConsoleLogEvent('info', 'received board cattle data:', boardCattleData);
            ConsoleLogEvent('info', 'received board items data:', boardItemsData);
        }, [boardCattleData, boardItemsData]);  // This effect runs whenever reportData changes 

        const handleChangeItems = (inputKey, inputValue) => {
            
            // console.log(' what key and value here: ', inputKey + "  " + inputValue);
            resetFormState(); 
            setActiveTab('tab2'); // Default for Cattle tab
            setSelectedId('') // reset id
            setResponseErrors({});

            getBoardItemsList();
        }

        const handleChangeCattle = (inputKey, inputValue) => {

            // console.log(' what key and value here: ', inputKey + "  " + inputValue);
            resetFormState(); 
            setActiveTab('tab1'); // Default for Cattle tab
            setSelectedId('') // reset id
            setResponseErrors({});

            getBoardCattleList();

        }

        const handleOnSelectId = (inputKey, inputValue) => {
            // console.log('handle on input Key and Value select ID: ', inputKey + '  ' + inputValue);
            setSelectedId(inputValue);
        }; 

        // Helper function to populate formState based on active tab and selected record
        // tab1 is for Cattle sales and tab2 is for Items wanting to be supplied
        const populateFormState = (transType, selectedRecord, selectedId) => {
            formState.trans_type = transType;
            formState.list_id = selectedRecord.list_id;
            formState.contact_id = activeTab === 'tab1' ? selectedRecord.purchaser_id : selectedRecord.supplier_id;
            formState.auto_inc_id = selectedId;
            formState.seller_id = user.email;
            formState.user_id = user.id;
        };

        // Handle Contact Person
        const handleContactPerson = async (e) => {
            e.preventDefault();


            // TODO: before submitting to the seller, check if this user has
            //       phone number from migration users, if has then proceed to send
            //       if not then ask the user to input a phone number before next step
            //       note, inform user that this phone number will be used to transact on this item only
            //       with the buyer and that others will not see neither the transaction nor the phone number

            
            if (!selectedId) {
                showToast(t("validation_messages.select_record_first"), "error");
                return;
            }
        
            // Determine the data source and transaction type
            const isCattleTab = activeTab === 'tab1';
            const data = isCattleTab ? boardCattleData.returned_records : boardItemsData.returned_records;
            const transType = isCattleTab ? TRANS_TYPE_BOARD_CONTACT_CATTLE : TRANS_TYPE_BOARD_CONTACT_ITEMS;
        
            // Find the selected record by ID
            const selectedRecord = data.find(record => record.id === selectedId);
        
            if (selectedRecord) {
                // Check if the selected record is blocked
                if (selectedRecord.blocked === '1') {
                    showToast(t('market_place_page.cannot_contact_blocked_person'), "info");
                    // console.warn("Selected record is blocked.");
                    return;
                }

                const isConfirmed = await handleContactPersonShowSwal();


                if (isConfirmed){
                    // TODO: show receipient information and send email

                    const emailSubject = 'Marketplace Board Ubaoni'; // 
                    const emailFromAddress = process.env.REACT_APP_EMAIL_FROM_ADDRESS;
                    
                    // Generate email details based on the transaction type
                    const { emailContent, emailToAddress } = getEmailDetails(transType, selectedRecord, formState.seller_id);

                    if (!emailToAddress) {
                        // this is a strange behaviour hence needs to monitor here
                        const recipientType = transType === 'board_contact_cattle' ? 'buyer' : 
                                              transType === 'board_contact_items' ? 'supplier' : 'recipient';
                        // TODO: translate this
                        showToast(`No email address provided by ${recipientType}. Skipping email operation.`, 'warn');
                        ConsoleLogEvent('warn', `No email address provided by ${recipientType}. Skipping email operation.`);
                        return; // Exit the function early
                    }                  

                    try{
                        // TODO: 1: start loading status
                        setRespNotice({ isSubmitting: true }); // Start loading
                        // Send the email if valid details are available
                        if (emailContent && emailToAddress) {
                            const reqResp = await sendMail(
                                process.env.REACT_APP_EMAIL_SENDING,
                                { emailToAddress, emailFromAddress, emailSubject, emailContent },
                                t
                            );
                            if (reqResp.success) {
                                // TODO: I want to send another email 
                                ConsoleLogEvent('info', 'First email successfully sent:', emailContent);
                                const shareContent = `Details of the person you want to contact:
                                                    Sale lot: ' ${selectedRecord.list_id} '.
                                                    Phone number: ' ${selectedRecord.phone_number ? selectedRecord.phone_number : "Not available"} '.
                                                    Email address: ' ${emailToAddress} '
                                                    You have contacted: ${selectedRecord.contacted} times`;
                                onShare(shareContent);
                            } else {
                                ConsoleLogEvent('error', 'Failed to send email to recipient:', emailToAddress);
                            }
                        } else {
                            // TODO: toast here for the user to know what has happened
                            // TODO: translate this
                            showToast('Email content or recipient address missing for transaction type', 'error');
                            ConsoleLogEvent('error', 'Email content or recipient address missing for transaction type:', transType);
                        }
                    }catch (error) {
                        ConsoleLogEvent('error', 'Error while sending email:', error);
                        // TODO: translate this 
                        showToast('An unexpected error occurred. Please try again.', 'error');
                    }finally {
                        // TODO: 2: end loading status
                        setRespNotice({ isSubmitting: false }); // End loading
                    }
    
                    // Populate form state with the selected record details if not blocked
                    populateFormState(transType, selectedRecord, selectedId);
                    submitForm(e); // Submit form with updated formState

                    // TODO: pop up confirmation notice and recipient information

                    ConsoleLogEvent('info', 'Finalized processing contact person', emailToAddress);

                }else{
                    // TODO: stop here
                    return;
                }
            } else {
                // console.warn('No matching record found for selectedId:', selectedId);
                showToast(t("validation_messages.record_not_found"), "error");
            }
        };

        const onShare = async (shareContent) => {
            const content = {
                title: 'GDF App® Note', // TODO: translate
                text: shareContent, // TODO: translate
            };
        
            const emailSubject = 'Marketplace Board Ubaoni';
            const emailFromAddress = process.env.REACT_APP_EMAIL_FROM_ADDRESS;
            const emailToAddress = formState.seller_id;
            const emailContent = shareContent;
        
            if (!emailToAddress) {
                ConsoleLogEvent('error', 'No valid email address provided for fallback email.');
                showToast('Recipient email address is missing. Unable to proceed.', 'error'); // TODO: Translate this
                return;
            }
        
            if (navigator.share) {
                try {
                    await navigator.share(content);
                    alert(t('validation_messages.thank_you_for_sharing'), t('validation_messages.sharing_alert_title'));
                } catch (err) {
                    ConsoleLogEvent('warn', 'User canceled share action:', err);
                    showToast('Sharing was canceled. Sending contact details to you via email instead.', 'warn'); // TODO: Translate this
                    await fallbackEmail(emailToAddress, emailFromAddress, emailSubject, emailContent );
                }
            } else {
                ConsoleLogEvent('info', 'Web share not supported, using email fallback.');
                showToast(t('validation_messages.sharing_alert_title') + ' ' + t('error_message.share_device_not_support'), 'info'); // TODO: Translate this
                await fallbackEmail(emailToAddress, emailFromAddress, emailSubject, emailContent );
            }
        }

        const fallbackEmail = async (emailToAddress, emailFromAddress, emailSubject, emailContent ) => {
            
            if (!emailToAddress) {
                ConsoleLogEvent('error', 'No valid email address provided for the second email.');
                showToast('Unable to send email: your email address is missing. Please try again later', 'error'); // TODO: Translate this
                return; // Exit early
            }

            try {
                const reqResp = await sendMail(
                    process.env.REACT_APP_EMAIL_SENDING,
                    { emailToAddress, emailFromAddress, emailSubject, emailContent },
                    t
                );
                if (reqResp.success) {
                    ConsoleLogEvent('info', 'Fallback email successfully sent:', emailToAddress);
                } else {
                    ConsoleLogEvent('error', 'Fallback email failed emailToAddress:', emailToAddress);
                }
            } catch (error) {
                ConsoleLogEvent('error', 'Error while sending fallback email:', error);
                showToast('An error occurred while sending the email.', 'error'); // Translate this
            }
        }

        // Function to generate email content and recipient address based on transType
        const getEmailDetails = (transType, selectedRecord, sellerId) => {
            let emailContent = '';
            let emailToAddress = '';
            
            switch (transType) {
                case 'board_contact_cattle':
                    // TODO: provide a translation to add into this
                    emailContent = `Seller wants to communicate with you on your request for:
                                    Sale lot: ' ${selectedRecord.list_id} '.
                                    Your Description: ' ${selectedRecord.description} '.
                                    Seller phone number: ' ${user.phone_number ? user.phone_number : 'not available'} '.
                                    Seller email address: ' ${sellerId ? sellerId : 'not available'} '.
                                    You have been contacted: ${selectedRecord.contacted} times`; 
                    emailToAddress = selectedRecord.purchaser_id;
                break;
            
                case 'board_contact_items':
                    // TODO: provide a translation to add into this
                    emailContent = `Purchaser wants to communicate with you on your request for:
                                    Item lot: ' ${selectedRecord.list_id} '.
                                    Your Description: ' ${selectedRecord.description} '.
                                    Purchaser phone number: ' ${user.phone_number ? user.phone_number : 'not available'} '.
                                    Purchaser email address: ' ${sellerId} '.
                                    You have been contacted: ${selectedRecord.contacted} times`;
                    emailToAddress = selectedRecord.supplier_id;
                break;

                default:
                    showToast(t("validation_messages.record_not_found"), "error");
                    ConsoleLogEvent('warn', 'Unknown transaction type:', transType);
                break;
            }
            
            return { emailContent, emailToAddress };
        };

        // Handle Block Person
        const handleBlockPerson = async (e) => {
            e.preventDefault();
        
            if (!selectedId) {
                showToast(t("validation_messages.select_record_first"), "error");
                return;
            }
        
            // Determine the data source and transaction type
            const isCattleTab = activeTab === 'tab1';
            const data = isCattleTab ? boardCattleData.returned_records : boardItemsData.returned_records;
            const transType = isCattleTab ? TRANS_TYPE_BOARD_BLOCK_CONTACT_CATTLE : TRANS_TYPE_BOARD_BLOCK_CONTACT_ITEMS;
        
            // Find the selected record by ID
            const selectedRecord = data.find(record => record.id === selectedId);
        
            if (selectedRecord) {
                // Check if the selected record is already blocked
                if (selectedRecord.blocked === '1') {
                    showToast(t('market_place_page.already_blocked'), "info"); 
                    return;
                }else{

                    // Populate form state with the selected record details to block
                    populateFormState(transType, selectedRecord, selectedId);
                    // Ask user to confirm blocking or cancel. once blocked can't be unblocked
                    handleShowSwal(e);
                }
            } else {
                // console.warn('No matching record found for selectedId:', selectedId);
                showToast(t("validation_messages.record_not_found"), "error");
            }
        };

        const handleContactPersonShowSwal = async () => {
            const result = await Swal.fire({
                title: 'GDP App Marketplace', // TODO: translate this
                text: "To facilitate this transaction, \nyour contact details will be shared with this person.\nYou will also receive recipient's contact details", // TODO: translate this
                icon: "info",
                showCancelButton: true,
                confirmButtonText: 'Go Ahead', // TODO: translate this
                cancelButtonText: t('market_place_page.confirm_block_cancel'),
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
            });
        
            return result.isConfirmed; // Return true if confirmed, false otherwise
        };

        const handleShowSwal = (e) => {
            Swal.fire({
                title: t('market_place_page.title_blocking_warning'), 
                text: t('market_place_page.text_blocking_warning'), 
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: t('market_place_page.confirm_block_button'),
                cancelButtonText: t('market_place_page.confirm_block_cancel'),
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
            }).then((result) => {
                if (result.isConfirmed) {

                    //  if confirmed then run the following. uncomment submitForm(e)
                    submitForm(e); // Submit form with updated formState
                } else {
                    //  if not confirmed then stop 
                    toast.success(t('market_place_page.note_not_blocking')); 
                    
                }
            });
        };

        // Submit form with the populated formState
        const submitForm = (e) => {
            e.preventDefault(); // Prevent form reload
            // Call handleSubmit with the prepared formState and other parameters
            handleSubmit(
                e,
                formState,
                createResource,
                envState.online,
                process.env.REACT_APP_MARKET_PLACE,
                setResponseErrors,
                setCreateNotice,
                resetFormState,
                publish,
                t
            );

            // if a person is contacted, contact flow is a backend process
        };

        // ------------ start common effect for cattle items ----------------        

    return (
        <div className="overflow-hidden sm:rounded-md">
            <div className="md:grid md:grid-cols-2 md:gap-6">
                <div className="mt-1 md:col-span-2 md:mt-0">
                    <ul className="nav">
                            <TabNavItem 
                                title={t('market_place_page.mapping_cattle')} 
                                id="tab1"
                                activeTab={activeTab} 
                                setActiveTab={setActiveTab}
                                handleChange={() => handleChangeCattle("tab1", "tab1")}
                            />

                            <TabNavItem 
                                title={t('market_place_page.item_header')} 
                                id="tab2" 
                                activeTab={activeTab} 
                                setActiveTab={setActiveTab}
                                handleChange={() => handleChangeItems("tab2", "tab2")}
                            />
                    </ul>
                    <div className="outlet">
                        {/* Cattle tab */}
                        <TabContent id="tab1" activeTab={activeTab}>
                            <div className="text-sm"> 
                                {formattedCattleData && formattedCattleData.length > 0 ? (
                                    <form autoComplete="off" className="w-full">
                                        <div className="overflow-hidden sm:rounded-md">
                                            <div className="col-span-6 lg:col-span-3">
                                                <div className="w-full flex justify-center">
                                                    <EditDelTableDataView
                                                        tableData={formattedCattleData}
                                                        idUsage={0}
                                                        useSerial={tableSettings.useSerial}
                                                        mapHash={{
                                                            "#": "#",
                                                            list_id: t('market_place_page.table_title_advert'), 
                                                            description: t('market_place_page.table_title_description'),
                                                            interest_date: t('market_place_page.table_title_advertised'),
                                                            // purchaser_id: 'Purchaser',
                                                            contact_id: t('market_place_page.table_title_person'),
                                                            contacted: t('market_place_page.table_title_contacted'),
                                                            blocked:  t('market_place_page.table_title_blocked'),
                                                        }}
                                                        selectedId={selectedId}
                                                        onSelectId={handleOnSelectId}
                                                    />
                                                </div>
                                            </div>
                                            <div className="hidden lg:block lg:col-span-1">{/* spacer */}</div>
                                        </div>
                                        <Footer
                                            // respNotice={createNotice}
                                            respNotice={respNotice} // don't want to fire this
                                            setRespNotice={setCreateNotice}
                                            backLink="/fe/reports"
                                            footerType={footerType}
                                            onContactPerson={handleContactPerson}  
                                            onBlockPerson={handleBlockPerson}  
                                        />
                                        
                                    </form>
                                ) : (
                                    <div className="grid grid-cols-6 lg:grid-cols-5">
                                        <div className="col-span-6 lg:col-span-3">
                                            <div className="w-full flex justify-center">
                                                <div>{t('market_place_page.not_cattle_for_sale')}</div> 
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </TabContent>

                        {/* Items tab */}
                        <TabContent id="tab2" activeTab={activeTab}>
                            <div className="text-sm"> 
                                {formattedItemsData && formattedItemsData.length > 0 ? (
                                    <form autoComplete="off" className="w-full">
                                        <div className="overflow-hidden sm:rounded-md">
                                            <div className="col-span-6 lg:col-span-3">
                                                <div className="w-full flex justify-center">
                                                    <EditDelTableDataView
                                                        tableData={formattedItemsData}
                                                        idUsage={0}
                                                        useSerial={tableSettings.useSerial}
                                                        mapHash={{
                                                            "#": "#",
                                                            list_id: t('market_place_page.table_title_advert'), 
                                                            description: t('market_place_page.table_title_description'),
                                                            interest_date: t('market_place_page.table_title_advertised'),
                                                            // supplier_id: 'Supplier',
                                                            contact_id: t('market_place_page.table_title_person'),
                                                            contacted: t('market_place_page.table_title_contacted'),
                                                            blocked:  t('market_place_page.table_title_blocked'),
                                                        }}
                                                        selectedId={selectedId}
                                                        onSelectId={handleOnSelectId}
                                                    />
                                                </div>
                                            </div>
                                            <div className="hidden lg:block lg:col-span-1"></div>
                                        </div>

                                        <Footer
                                            // respNotice={createNotice}
                                            respNotice={respNotice} // don't want to fire this
                                            setRespNotice={setCreateNotice}
                                            backLink="/fe/reports"
                                            footerType={footerType}
                                            onContactPerson={handleContactPerson}  
                                            onBlockPerson={handleBlockPerson} 
                                        />

                                    </form>
                                ) : (
                                    <div className="grid grid-cols-6 lg:grid-cols-5">
                                        <div className="col-span-6 lg:col-span-3">
                                            <div className="w-full flex justify-center">
                                                <div>{t('market_place_page.no_items_needed')}</div> 
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </TabContent>
                    </div>
                </div>

                    <ToastContainer
                        position="top-center"
                        autoClose={5000}
                        hideProgressBar={false}
                        closeOnClick
                        pauseOnHover
                        draggable
                        pauseOnFocusLoss
                        theme="light"
                    />        
                    
            </div>
        </div>

    );

};

export { OpenBoardForm };
