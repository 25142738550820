// The error parser
// @param errors array

const parseErrors = (errors) => {
    return errors
     ? errors.map(message => {
         return <span
                    className="text-red-500 block ml-2 mt-1"
                    key={message.trim().split(" ").join("-")}
                >
                    {message}
                </span>
     })
     : "" 
}

export const useErrorParser = () => parseErrors;