import React, { useLayoutEffect } from 'react';
import { useTranslation } from "react-i18next";
import { useUser, useEnv } from '../contexts';
import { ProductNavbar } from '../components';
import { Outlet } from 'react-router-dom'; // Import Outlet

export const MarketPlaceForm = () => {

  // For language translation
  const { t } = useTranslation();
  const { user } = useUser();  // Get currently logged-in user
  const { envState, setEnvState } = useEnv(); // Get Environment context

  // Notify the App of this location and Header Nav requirements
  useLayoutEffect(() => {
    setEnvState(state => ({ ...state, whereAbout: t('market_place_page.market_place'), backPath: "", homePath: "/fe" })); 
    return () => {
      setEnvState(state => ({ ...state, whereAbout: "", backPath: "", homePath: "" }));
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div className="container mx-auto px-4 py-8 mt-16">
        <div className="lg:col-span-2">{/* spacer */}</div>
        {/* Single spacer with larger margin */}
        <div className="mb-4"></div>

        {/* Include the navigation bar */}
        <ProductNavbar />

        {/* Render the child routes */}
        <Outlet />

    </div>
  );
};
