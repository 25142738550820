// The info parser
// @param info array

const parseErrors = (info) => {
    return info
     ? info.map(message => {
         return <span
                    className="text-gray-800 block ml-2 mt-1"
                    key={message.trim().split(" ").join("-")}
                >
                    {message}
                </span>
     })
     : "" 
}

export const useInfoParser = () => parseErrors;