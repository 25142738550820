import { useState, useEffect } from "react";

import { InputLabel, Footer, Spinner, CattleDataView } from "../components";
import { MagnifyingGlassIcon } from "../icons";

import { useHandleSubmit } from "../hooks";
import { useEnv } from "../contexts";
import { getResource } from "../services/farmApi";

import { useTranslation } from "react-i18next";

function CattleView() {

  // for language translation
  const { t } = useTranslation();

  const initQuery = { resourceId: "" };
  const [query, setQuery] = useState(initQuery);
  const [isSearching, setIsSearching] = useState(false);
  const [resourceData, setResourceData] = useState([]);
  const [createNotice, setCreateNotice] = useState({
    msg: "",
    showNotice: false,
    succ: false,
    status: null,
  });
  const { handleGetResource } = useHandleSubmit(); // Shared submit handler
  const { envState, setEnvState } = useEnv(); // Get Environment context

  const handleTagChange = (e) => {
    setQuery({
      resourceId: e.target.value,
    });
  };

  const resetFormState = () => {
    setQuery(initQuery);
  };

  // Notifiy the App of this location and provide Header Nav requirements
  useEffect(() => {
    setEnvState((state) => ({
      ...state,
      whereAbout: t('navigation_element.nav_item_title_search'),
      backPath: "/fe/reports",
      homePath: "/fe",
    }));
    return () => {
      setEnvState((state) => ({
        ...state,
        whereAbout: "",
        backPath: "",
        homePath: "",
      }));
    };
    // eslint-disable-next-line
  }, []);

  return (
    <div className="md:grid md:grid-cols-2 md:gap-6 mt-[100px] mb-[100px]">
      <div className="mt-2 md:col-span-2 md:mt-0">
        <form
          autoComplete="off"
          onSubmit={(e) => {
            e.preventDefault();
            setResourceData([]);
            if (query.resourceId) {
              handleGetResource(
                query,
                getResource,
                envState.online,
                process.env.REACT_APP_CATTLE,
                setCreateNotice,
                resetFormState,
                setResourceData,
                setIsSearching
              );
            }
          }}
        >
          <div className="overflow-hidden sm:rounded-md">
            <div className="py-5">
              <div className="grid grid-cols-6 lg:grid-cols-5">
                <div className="lg:col-span-1">{/*spacer*/}</div>
                <div className="col-span-4 lg:col-span-3">
                  {/* Cattle Find - text */}
                  <div className="sm:text-lg md:text-sm">
                    <InputLabel
                      inputId="cattle_id"
                      inputStyle="block text-sm font-medium text-gray-700"
                      labelText={t('search_page.find_cattle')}
                    />
                    <div className="relative mt-2 rounded-md shadow-sm">
                      <input
                        type="text"
                        name="cattle_id"
                        id="cattle_id"
                        autoComplete="on"
                        className="block w-full rounded-md border-0 py-1.5 pl-3 pr-20 text-gray-900 ring-1 ring-inset ring-slate-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-slate-400 sm:text-sm sm:leading-6"
                        placeholder={t('search_page.enter_tag_or_cattle_name')}
                        onChange={handleTagChange}
                        value={query.resourceId}
                      />
                      <div className="absolute inset-y-0 right-0 flex items-center">
                        <span className="sr-only">Search button</span>
                        {/* <span className="sm:ml-3 border"> */}
                        <button
                          type="submit"
                          className="inline-flex items-center rounded-r-md rounded-l-sm bg-slate-500 px-3 h-full text-sm font-semibold text-white shadow-sm hover:bg-slate-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-slate-500"
                        >
                          <MagnifyingGlassIcon />
                        </button>
                        {/* </span> */}
                      </div>
                    </div>
                  </div>
                  {/*showFieldErrors(responseErrors.cattle_id)*/}
                </div>
                <div className="lg:col-span-1">{/*spacer*/}</div>
              </div>
            </div>
            <Footer
              respNotice={createNotice}
              setRespNotice={setCreateNotice}
              backLink="/fe/reports"
              footerType="nav"
            />
          </div>
        </form>
      </div>
      <div className="mt-2 md:col-span-2 md:mt-0">
        <div className="overflow-hidden grid grid-cols-6 lg:grid-cols-5">
          <div className="lg:col-span-1">{/*spacer*/}</div>
          <div className="col-span-4 lg:col-span-3">
            {isSearching ? (
              <Spinner />
            ) : createNotice.succ ? (
              <CattleDataView cattleData={resourceData[0]} />
            ) : createNotice.status === 404 ? (
              <span className="text-gray-500 text-md">
                <span className="font-semibold text-gray-800">
                {t('search_page.no_recourd_found')},{" "}
                </span>
                <span>{t('search_page.used_search_value')} </span>
                <span className="font-bold text-gray-800">
                  {createNotice.queryString}.
                </span>
                <span>
                  {" "}
                  {t('search_page.allowed_search_value')}
                </span>
              </span>
            ) : (
              ""
            )}
          </div>
          <div className="lg:col-span-1">{/*spacer*/}</div>
        </div>
      </div>
    </div>
  );
}

export { CattleView };
