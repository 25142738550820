import { useEnaDisableStyle, useErrorParser, useInfoParser, useEvents, useHandleSubmit } from '../hooks';
import { TextInput, InputLabel, SelectInput, TextareaInput, NumberInput, Footer, TableDAtaView } from '../components'
import { createResource, getResource } from '../services/farmApi';
import React, { useLayoutEffect, useState, useEffect} from 'react';
import TabNavItem from "../components/forms/TabNavItem";
import TabContent from "../components/forms/TabContent";
import { useTranslation } from "react-i18next";
import { useUser, useEnv } from '../contexts';
import { Chart } from "react-google-charts";
import "../tabsview.css";


function TransactionsForm() {

    const { user } = useUser();  // Get currently loggedIn user

    // for language translation
    const { t } = useTranslation();
    const {envState, setEnvState } = useEnv(); // Get Environment context
  

    const { publish } = useEvents(); // publish - dispatches events
    const { handleSubmit } = useHandleSubmit(); // Shared submit handler

    // Influences how table is displayed
    const [tableSettings, setTableSettings] = useState({
      idUsage: 1,
      useSerial: true,
    });

    // Todays' date
    const today = new Date().toISOString().split('T')[0];
    // Initial Form state
    const initFormState = {
        date_of_income: today, // date of income
        other_income_type: '',
        income_type: 'Select',
        notes_income: '',
        cattle_id: '',
        medicine: '',
        fodder: '',
        vets: '',

        date_of_expenses: today, // date of expenses
        expenses_type: 'Select',
        notes_expenses: '',
        other_expenses_type: '',
        spent_amount: '',
        
        user_id: user.id,
    };

    const [formState, setFormState] = useState(initFormState);
    const [createNotice, setCreateNotice] = useState({showNotice: false, succ: false, msg: ""});
    const [responseErrors, setResponseErrors] = useState({});

    const [reportData, setReportData] = useState([]);
    const { handleGetResource } = useHandleSubmit(); // Shared submit handler

    const textInputStyle = useEnaDisableStyle();
    const showFieldErrors = useErrorParser(); // Takes in array off validation errors and returns jsx.
    const showFieldInfo = useInfoParser(); // Takes in array off validation errors and returns jsx.

    const [activeTab, setActiveTab] = useState("tab1");

    // Notifiy the App of this location and Header Nav requirements
    useLayoutEffect(() => {
        setEnvState(state => ({...state, whereAbout: t('navigation_element.nav_item_title_transactions'), backPath: "/fe/manage", homePath: "/fe"}));
        return () => {
          setEnvState(state => ({...state, whereAbout: "", backPath: "", homePath: ""}));
        }
        // eslint-disable-next-line
    }, []);

    const handleChangeIncome = (inputKey, inputValue) => {
      setFormState({...formState, [inputKey]: inputValue});
      setResponseErrors({});

      if (inputValue === 'tab1'){
        let keyExpenses = 'trans_type';
        let valueExpenses = 'I';
        setFormState({
          ...formState,
          [keyExpenses]: valueExpenses,
          // Clear previous value
          date_of_income: today,
          income_type: 'Select',
          notes_income: '',
          cattle_id: '', // Clear previous value

          spent_amount: '',    
          fodder: '',
          vets: '',
          medicine: '',
          other_expenses_type: '',
          notes_expenses: '',
        });
      }else if (inputKey === 'income_type' && inputValue === 'Milk') {
        // If "Milk" is selected, show the fields "quantity" and "money"
        setFormState({
          ...formState,
          [inputKey]: inputValue,
          quantity: '', // Clear previous value
          money_received: '',    // Clear previous value
          notes_income: '',
          cattle_id: '', // Clear previous value
        });
      } else if(inputKey === 'income_type' && inputValue === 'Cattle') {
        // If "Cattle" is selected, show the fields "cattle_id"
        setFormState({
          ...formState,
          [inputKey]: inputValue,
          cattle_id: '', // Clear previous value
          quantity: '', // Clear previous value
          money_received: '',    // Clear previous value
          notes_income: '',
        });
      } else if(inputKey === 'income_type' && inputValue === 'Other'){
        // If "Other (specify)" is selected, show the fields "cattle_id"
        setFormState({
          ...formState,
          [inputKey]: inputValue,
          other_income_type: '', // Clear previous value
          quantity: '', // Clear previous value
          money_received: '',    // Clear previous value
          notes_income: '',
          cattle_id: '', // Clear previous value
        });
      } else if (formState.notes_income){
          if(inputValue.length > 128 && inputValue.length < 256){
            setResponseErrors({notes_income: ["Characters remaining: " + inputValue.length + '/256']})
          } else if(inputValue.length >= 256){
            setResponseErrors({notes_income: ["Max characters reached: " + inputValue.length + '/256']});
          }
      } else {
        // For other income types, just update the state normally
        setFormState({ ...formState, [inputKey]: inputValue });
      }
    }

    const handleChangeExpenses = (inputKeyExpenses, inputValueExpenses) => {
      setFormState({...formState, [inputKeyExpenses]: inputValueExpenses});
      setResponseErrors({});

        if (inputValueExpenses === 'tab2'){

          let keyExpenses = 'trans_type';
          let valueExpenses = 'E';
          setFormState({
            ...formState,
            [keyExpenses]: valueExpenses,
            // clear previous values
            date_of_expenses: today,
            expenses_type: 'Select',
            cattle_id: '',         
            quantity: '',
            money_received: '',
            notes_income: '',
            other_income_type: '',
          });
        }else if (inputKeyExpenses === 'fodder' || inputKeyExpenses === 'vets' || inputKeyExpenses === 'medicine' || inputKeyExpenses === 'other_expenses_type'){
          switch (inputKeyExpenses) {
            case 'fodder':
              inputKeyExpenses = 'expenses_description';
              setFormState({
                  ...formState,
                  [inputKeyExpenses]: inputValueExpenses,
                  fodder: inputValueExpenses,
                  vets: '',
                  medicine: '',
                  other_expenses_type: '',
              });
              break;
            case 'vets':
              inputKeyExpenses = 'expenses_description';
              setFormState({
                  ...formState,
                  [inputKeyExpenses]: inputValueExpenses,
                  fodder: '',
                  vets: inputValueExpenses,
                  medicine: '',
                  other_expenses_type: '',
              });
              break;
            case 'medicine':
              inputKeyExpenses = 'expenses_description';
              setFormState({
                  ...formState,
                  [inputKeyExpenses]: inputValueExpenses,
                  fodder: '',
                  vets: '',
                  medicine: inputValueExpenses,
                  other_expenses_type: '',
              });
              break;
            case 'other_expenses_type':
                inputKeyExpenses = 'expenses_description';
                setFormState({
                    ...formState,
                    [inputKeyExpenses]: inputValueExpenses,
                    fodder: '',
                    vets: '',
                    medicine: '',
                    other_expenses_type: inputValueExpenses,
                });
                break;
            default:
                // Handle the default case if needed
                break;
          }
        } else if (inputKeyExpenses === 'notes_expenses'){
          if (inputValueExpenses.length > 128 && inputValueExpenses.length < 256){
            setResponseErrors({notes_expenses: ["Characters remaining: " + inputValueExpenses.length + '/256']})
          } else if(inputValueExpenses.length >= 256){
            setResponseErrors({notes_expenses: ["Max characters reached: " + inputValueExpenses.length + '/256']});
          }
        } else {
          // For other income types, just update the state normally
          setFormState({ ...formState, [inputKeyExpenses]: inputValueExpenses });
        }

    }
      
    const resetFormState = () => { setFormState(initFormState) }

    const localHandleSubmitIncomeProcessor = (e) => {
      e.preventDefault(); 

      // this is income processor
      formState.trans_type = 'I';
    
      if (formState.trans_type === 'I'){
        // process income form submit
        if(formState.income_type === 'Select'){
          setResponseErrors({income_type: [t('validation_messages.select_income_type')]});   
          return;
        }else if (formState.income_type === 'Milk' && (formState.quantity === '' || formState.quantity === null)){
          setResponseErrors({quantity: [t('validation_messages.specify_milk_quantity')]}); 
          return;
        }else if (formState.income_type === 'Cattle' && formState.cattle_id === ''){
          setResponseErrors({cattle_id: [t('validation_messages.specify_cattle_tag')]}); 
          return;
        }else if(formState.cattle_id && !/^[a-zA-Z0-9]{1,10}$/.test(formState.cattle_id)){
          setResponseErrors({cattle_id: [t('validation_messages.alphanumeric_only')]}); 
          return;
        }else if (formState.income_type === 'Other' && formState.other_income_type === ''){
          setResponseErrors({other_income_type: [t('validation_messages.specify_other_income_type_name')]}); 
          return;
        }else if (formState.money_received === null || formState.money_received === ''){
          setResponseErrors({money_received: [t('validation_messages.fill_amount_received')]}); 
          return;
        }else if(String(formState.money_received).length > 13){
          // this will horibly huge amount for this transaction
          setResponseErrors({money_received: [t('validation_messages.high_amount')]}); 
          return;
        }else if(String(formState.quantity).length > 4){
          // this will horibly huge amount for this transaction
          setResponseErrors({quantity: [t('validation_messages.high_quantity')]}); 
          return;
        }else if(String(formState.other_income_type).length > 16){
          // this will horibly huge amount for this transaction
          setResponseErrors({other_income_type: [t('validation_messages.shortern_income_type_name')]}); 
          return;
        }else{ 
            // all good now submit
            setResponseErrors({});

            handleSubmit(
              e,
              formState,
              createResource,
              envState.online,
              process.env.REACT_APP_TRANSACTIONS,
              setResponseErrors,
              setCreateNotice,
              resetFormState,
              publish,
              t
            ) 
        }
      }

    }

    const localHandleSubmitExpnesesProcessor = (e) => {
      e.preventDefault(); 

      // this is expenses processor
      formState.trans_type = 'E';

      if (formState.trans_type === 'E'){
        // process expenses form submit
        if(formState.expenses_type === 'Select'){
          setResponseErrors({expenses_type: [t('validation_messages.select_expenses_type')]}); 
          return;
        }else if (formState.expenses_type === 'Fodder' && formState.fodder === ''){
          setResponseErrors({fodder: [t('validation_messages.specify_fodder')]});   
          return;
        }else if (formState.expenses_type === 'Vets' && formState.vets === ''){
          setResponseErrors({vets: [t('validation_messages.specify_vets_name')]});  
          return;
        }else if (formState.expenses_type === 'Medicine' && formState.medicine === ''){
          setResponseErrors({medicine: [t('validation_messages.specify_medicine_name')]});   
          return;
        }else if (formState.expenses_type === 'Other' && formState.other_expenses_type === ''){
          setResponseErrors({other_expenses_type: [t('validation_messages.specify_other_expenses')]}); 
          return;
        }else if(formState.spent_amount <= 0){
          setResponseErrors({spent_amount: [t('validation_messages.specify_amount_spent')]});  
          return;
        }else if(String(formState.spent_amount).length > 13){
          // this will horibly huge amount for this transaction
          setResponseErrors({spent_amount: [t('validation_messages.high_amount')]});   
          return;
        }else if(String(formState.other_expenses_type).length > 16){
          // this will horibly huge amount for this transaction
          setResponseErrors({other_expenses_type: [t('validation_messages.shortern_expenses_type_name')]});   
          return;
        }else{ 
            // all good now submit
            setResponseErrors({});

            handleSubmit(
              e,
              formState,
              createResource,
              envState.online,
              process.env.REACT_APP_TRANSACTIONS,
              setResponseErrors,
              setCreateNotice,
              resetFormState,
              publish,
              t
            ) 
        }
        
      }

    }
    
    useEffect(() => {
        // Fetch data and update reportFarmData
        // This is just an example, replace it with your logic
        fetchDataAndUpdateReportData();
        const idUsage = 1;
        const useSerial = true;
        setTableSettings({ idUsage, useSerial });
    }, []);

    // Function to fetch data and update reportFarmData
    const fetchDataAndUpdateReportData = async () => {
        try {
            handleGetResource(
              null,
              getResource,
              envState.online,
              process.env.REACT_APP_TRANSACTIONS,
              setCreateNotice,
              null,
              setReportData,
              null,
              false // explicitly expecting a single model?
          );
        } catch (error) {
          console.error('Error fetching report data:', error);
        }
    };
    
    const optionsIncome = {
      title: t('income_expenses_page.income_split'),
      is3D: true,
    };

    const optionsExpenses = {
      title: t('income_expenses_page.expenses_split'),
      is3D: true,
    };

    return (
      <center>
        <div className="mt-[100px] mb-[100px]">
          <div className="md:grid md:grid-cols-2 md:gap-6">
            <div className="mt-5 md:col-span-2 md:mt-0">
                <ul className="nav">
                  <TabNavItem 
                    title={t('income_expenses_page.income')} 
                    id="tab1"
                    activeTab={activeTab} 
                    setActiveTab={setActiveTab}
                    handleChange={() => handleChangeIncome("tab1", "tab1")}
                  />
                  <TabNavItem 
                      title={t('income_expenses_page.expenses')} 
                      id="tab2" 
                      activeTab={activeTab} 
                      setActiveTab={setActiveTab}
                      handleChange={() => handleChangeExpenses("tab2", "tab2")}
                  />
                  <TabNavItem 
                      title={t('income_expenses_page.reports')} 
                      id="tab3" 
                      activeTab={activeTab} 
                      setActiveTab={setActiveTab}
                      handleChange={() => handleChangeExpenses("tab3", "tab3")}
                  />

                  {/** if you want to add another tab, uncomment below  */}
                  {/* <TabNavItem title="Tab 3" id="tab3" activeTab={activeTab} setActiveTab={setActiveTab}/> */}

                </ul>
          
                <div className="outlet">
                  {/* Income tab */}
                  <TabContent id="tab1" activeTab={activeTab}>
                    <form onSubmit={localHandleSubmitIncomeProcessor} autoComplete="off">
                        <div className="overflow-hidden sm:rounded-md">
                          <div className="bg-white px-4 py-5 sm:p-6">
                            <div className="grid grid-cols-6 gap-6">

                              {/* Date of income */}
                              <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                                <InputLabel
                                  inputId="date_of_income"
                                  inputStyle="block text-sm font-medium text-gray-700 text-left"
                                  labelText={t('income_expenses_page.income_date')} 
                                />
                                <TextInput
                                  focus={false}
                                  inputType="date"
                                  inputName="date_of_income"
                                  inputId="date_of_income"
                                  isAutoComplete="on"
                                  isRequired={false}
                                  isDisabled={false}
                                  inputStyle={textInputStyle.current.enabledInput}
                                  inputValue={formState.date_of_income}
                                  handleChange={handleChangeIncome}
                                />
                                <div className="response-error-message">
                                  {showFieldErrors(responseErrors.date_of_income)}
                                </div>
                              </div>


                              {/* Select income type */}
                              <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                                <InputLabel
                                  inputId="income_type"
                                  inputStyle="block text-sm font-medium text-gray-700 text-left"
                                  labelText={t('income_expenses_page.select_income_type')} 
                                />
                                <SelectInput
                                    focus={false}
                                    inputName="income_type"
                                    inputId="income_type"
                                    inputStyle={textInputStyle.current.enabledInput}
                                    inputValue={formState.income_type}
                                    handleChange={handleChangeIncome}
                                    choices={[
                                      {key: 'Select', label: t('income_expenses_page.select_type') }, 
                                      {key: 'Milk', label: t('income_expenses_page.milk_sale') }, 
                                      {key: 'Cattle', label: t('income_expenses_page.cattle_sale') },  
                                      {key: 'Other', label: t('income_expenses_page.other_specify') }, 
                                    ]}
                                  />
                                <div className="response-error-message">
                                  {showFieldErrors(responseErrors.income_type)}
                                </div>
                              </div>

                              {/** the following part should only be shown whem Milk is selected from the drop down */}

                              {/* Milk Selected show capture quantity */}
                              {formState.income_type === 'Milk' && (
                                <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                                  <InputLabel
                                    inputId="quantity"
                                    inputStyle="block text-sm font-medium text-gray-700 text-left"
                                    labelText={t('income_expenses_page.milk_sale_tittle')} 
                                  />
                                  <NumberInput
                                    focus={false}
                                    inputName="quantity"
                                    inputId="quantity"
                                    hint="0"
                                    isRequired={false}
                                    isDisabled={false}
                                    inputStyle={textInputStyle.current.enabledInput}
                                    inputValue={formState.quantity}
                                    inputStep="0.01"
                                    handleChange={setFormState}
                                  />                              
                                  <div className="response-error-message">
                                    {showFieldErrors(responseErrors.quantity)}
                                  </div>
                                </div>
                              )}
                              {/** the end of of the part that is shown whem Milk is selected from the drop down */}



                              {/** the following part should only show when Cattle is selected from the drop down */}
                              {/* Cattle Id field - text*/}
                              {formState.income_type === 'Cattle' && (
                                <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                                  <InputLabel
                                    inputId="quantity"
                                    inputStyle="block text-sm font-medium text-gray-700 text-left"
                                    labelText={t('income_expenses_page.cattle_sold_title')}
                                  />
                                  <NumberInput
                                    focus={true}
                                    inputName="quantity"
                                    inputId="quantity"
                                    hint='0'
                                    isRequired={false}
                                    isDisabled={false}
                                    inputStyle={textInputStyle.current.enabledInput}
                                    inputValue={formState.quantity}
                                    inputStep="0.01"
                                    handleChange={setFormState}
                                  /> 
                                  <div className="response-error-message">
                                    {showFieldErrors(responseErrors.quantity)}
                                  </div>

                                  <div style={{ marginBottom: '1rem' }}></div>
                                  <InputLabel
                                    inputId="cattle_id"
                                    inputStyle="block text-sm font-medium text-gray-700 text-left"
                                    labelText={t('income_expenses_page.tag_title')}
                                  />
                                  <TextInput
                                    focus={true}
                                    inputType="text"
                                    inputName="cattle_id"
                                    inputId="cattle_id"
                                    hint={t('income_expenses_page.tag_hint')}
                                    isAutoComplete="on"
                                    isRequired={false}
                                    isDisabled={false}
                                    inputStyle={textInputStyle.current.enabledInput}
                                    inputValue={formState.cattle_id}
                                    handleChange={handleChangeIncome}
                                  />
                                  <div className="response-error-message">
                                    {showFieldErrors(responseErrors.cattle_id)}
                                  </div>
                                </div>
                              )}

                              {/** the end of the part that is shown when Cattle is selected from the drop down */}

                              {/** the following part should only show when Other (specify) is selected from the drop down */}
                              {/* Name - text */}
                              {formState.income_type === 'Other' && (
                                <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                                  <InputLabel
                                    inputId="otherspecify"
                                    inputStyle="block text-sm font-medium text-gray-700 text-left"
                                    labelText={t('income_expenses_page.other_income_title')}
                                  />
                                  <TextInput
                                    focus={false}
                                    inputType="text"
                                    inputName="other_income_type"
                                    inputId="other_income_type"
                                    hint={t('income_expenses_page.other_income_hint')} 
                                    isRequired={false}
                                    isDisabled={false}
                                    inputStyle={textInputStyle.current.enabledInput}
                                    inputValue={formState.other_income_type}
                                    handleChange={handleChangeIncome}
                                    maxLength={25} // Limit the character count to 26 as per migration
                                  />        
                                  <div className="response-error-message">
                                  {showFieldErrors(responseErrors.other_income_type)}
                                  </div>
                                  <div style={{ marginBottom: '1rem' }}></div>
                                  <InputLabel
                                    inputId="quantity"
                                    inputStyle="block text-sm font-medium text-gray-700 text-left"
                                    labelText={t('income_expenses_page.quantity_sold_title')} 
                                  />
                                  <NumberInput
                                    focus={true}
                                    inputName="quantity"
                                    inputId="quantity"
                                    hint="0"
                                    isRequired={false}
                                    isDisabled={false}
                                    inputStyle={textInputStyle.current.enabledInput}
                                    inputValue={formState.quantity}
                                    inputStep="0.01"
                                    handleChange={setFormState}
                                  /> 
                                </div>
                              )}

                              {/** the end of the part that is shown when Other (specify) is selected form the drop down */}

                              {/* capture amount paid */}
                              <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                                <InputLabel
                                  inputId="money_received"
                                  inputStyle="block text-sm font-medium text-gray-700 text-left"
                                  labelText={t('income_expenses_page.amount_received')}  
                                />
                                <NumberInput
                                  focus={false}
                                  inputName="money_received"
                                  inputId="money_received"
                                  hint="0"
                                  isRequired={false}
                                  isDisabled={false}
                                  inputStyle={textInputStyle.current.enabledInput}
                                  inputValue={formState.money_received}  
                                  inputStep="0.01"
                                  handleChange={setFormState}
                                />
                                <div className="response-error-message">
                                  {showFieldErrors(responseErrors.money_received)}
                                </div>
                              </div>

                              {/* Notes - textarea */}
                              <div className="col-span-6">
                                <InputLabel
                                  inputId="notes_income"
                                  inputStyle="block text-sm font-medium text-gray-700 text-left"
                                  labelText={t('income_expenses_page.notes_title')}  
                                />
                                <TextareaInput
                                  focus={false}
                                  inputName="notes_income"
                                  inputId="notes_income"
                                  hint={t('income_expenses_page.notes_hint')}  
                                  isRequired={false}
                                  inputRows={3}
                                  maxLength={256}
                                  inputStyle={textInputStyle.current.enabledInput}
                                  inputValue={formState.notes_income}
                                  handleChange={handleChangeIncome}
                                />
                              {formState.notes_income.length > 128 && formState.notes_income.length < 256 ? (
                                <div className="response-info-message">
                                  {showFieldInfo(responseErrors.notes_income)}
                                </div>
                              ) : (
                                <div className="response-error-message">
                                  {showFieldErrors(responseErrors.notes_income)}
                                </div>
                              )}
                              </div> 
                            </div>
                          </div>
                          <div className="overflow-hidden sm:rounded-md" style={{ fontSize: '14px' }}>
                            <Footer respNotice={createNotice} setRespNotice={setCreateNotice} footerType="create"/>
                          </div>
                        </div>
                    </form>
                  </TabContent>

                  {/* Expenses tab */}
                  <TabContent id="tab2" activeTab={activeTab}>
                    <form onSubmit={localHandleSubmitExpnesesProcessor} autoComplete="off">
                            <div className="overflow-hidden sm:rounded-md">
                              <div className="bg-white px-4 py-5 sm:p-6">
                                <div className="grid grid-cols-6 gap-6">

                                  {/* Date of income */}
                                  <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                                    <InputLabel
                                      inputId="date_of_expenses"
                                      inputStyle="block text-sm font-medium text-gray-700 text-left"
                                      labelText={t('income_expenses_page.expenses_date')} 
                                    />
                                    <TextInput
                                      focus={false}
                                      inputType="date"
                                      inputName="date_of_expenses"
                                      inputId="date_of_expenses"
                                      inputStyle={textInputStyle.current.enabledInput}
                                      inputValue={formState.date_of_expenses}
                                      handleChange={handleChangeExpenses}
                                    />
                                    <div className="response-error-message">
                                      {showFieldErrors(responseErrors.date_of_expenses)}
                                    </div>
                                  </div>


                                  {/* Select expenses type */}
                                  <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                                    <InputLabel
                                      inputId="expenses_type"
                                      inputStyle="block text-sm font-medium text-gray-700 text-left"
                                      labelText={t('income_expenses_page.select_expenses_type')}
                                    />
                                    <SelectInput
                                        focus={false}
                                        inputName="expenses_type"
                                        inputId="expenses_type"
                                        inputStyle={textInputStyle.current.enabledInput}
                                        inputValue={formState.expenses_type}
                                        handleChange={handleChangeExpenses}
                                        choices={[
                                          {key: 'Select', label: t('income_expenses_page.select_type')}, 
                                          {key: 'Fodder', label: t('income_expenses_page.bought_fodder')}, 
                                          {key: 'Vets', label: t('income_expenses_page.paid_vet') }, 
                                          {key: 'Medicine', label: t('income_expenses_page.bought_medicine') }, 
                                          {key: 'Other', label: t('income_expenses_page.other_specify') }, 
                                        ]}
                                      />
                                    <div className="response-error-message">
                                      {showFieldErrors(responseErrors.expenses_type)}
                                    </div>
                                  </div>

                                  {/** the following part should only be shown whem expenses type is selected from the drop down */}

                                  {/* Fodder Selected show capture fodder name */}
                                  {formState.expenses_type === 'Fodder' && (
                                    <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                                      <InputLabel
                                        inputId="fodder"
                                        inputStyle="block text-sm font-medium text-gray-700 text-left"
                                        labelText={t('income_expenses_page.fodder_name_title')} 
                                      />
                                      <TextInput
                                          focus={true}
                                          inputType="text"
                                          inputName="fodder"
                                          inputId="fodder"
                                          hint={t('income_expenses_page.fodder_name_hint')} 
                                          isAutoComplete="on"
                                          isRequired={false}
                                          isDisabled={false}
                                          inputStyle={textInputStyle.current.enabledInput}
                                          inputValue={formState.fodder}
                                          handleChange={handleChangeExpenses}
                                          maxLength={16}
                                        />                           
                                      <div className="response-error-message">
                                        {showFieldErrors(responseErrors.fodder)}
                                    </div>
                                    </div>
                                  )}

                                  {/* Vets Selected show capture vets name */}
                                  {formState.expenses_type === 'Vets' && (
                                    <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                                      <InputLabel
                                        inputId="vets"
                                        inputStyle="block text-sm font-medium text-gray-700 text-left"
                                        labelText={t('income_expenses_page.vet_name_title')} 
                                      />
                                      <TextInput
                                          focus={true}
                                          inputType="text"
                                          inputName="vets"
                                          inputId="vets"
                                          hint={t('income_expenses_page.vet_name_hint')} 
                                          isAutoComplete="on"
                                          isRequired={false}
                                          isDisabled={false}
                                          inputStyle={textInputStyle.current.enabledInput}
                                          inputValue={formState.vets}
                                          handleChange={handleChangeExpenses}
                                          maxLength={16}
                                        />                           
                                      <div className="response-error-message">
                                        {showFieldErrors(responseErrors.vets)}
                                    </div>
                                    </div>
                                  )}

                                  {/* Medicine Selected show capture vets name */}
                                  {formState.expenses_type === 'Medicine' && (
                                    <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                                      <InputLabel
                                        inputId="medicine"
                                        inputStyle="block text-sm font-medium text-gray-700 text-left"
                                        labelText={t('income_expenses_page.medicine_name_title')} 
                                      />
                                      <TextInput
                                          focus={true}
                                          inputType="text"
                                          inputName="medicine"
                                          inputId="medicine"
                                          hint={t('income_expenses_page.medicine_name_hint')} 
                                          isAutoComplete="on"
                                          isRequired={false}
                                          isDisabled={false}
                                          inputStyle={textInputStyle.current.enabledInput}
                                          inputValue={formState.medicine}
                                          handleChange={handleChangeExpenses}
                                          maxLength={16}
                                        />                           
                                      <div className="response-error-message">
                                        {showFieldErrors(responseErrors.medicine)}
                                    </div>
                                    </div>
                                  )}                          

                                  {/* Medicine Selected show capture vets name */}
                                  {formState.expenses_type === 'Other' && (
                                    <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                                      <InputLabel
                                        inputId="other_expenses_type"
                                        inputStyle="block text-sm font-medium text-gray-700 text-left"
                                        labelText={t('income_expenses_page.other_expenses_title')} 
                                      />
                                      <TextInput
                                          focus={true}
                                          inputType="text"
                                          inputName="other_expenses_type"
                                          inputId="other_expenses_type"
                                          hint={t('income_expenses_page.other_expenses_hint')} 
                                          isAutoComplete="on"
                                          isRequired={false}
                                          isDisabled={false}
                                          inputStyle={textInputStyle.current.enabledInput}
                                          inputValue={formState.other_expenses_type}
                                          handleChange={handleChangeExpenses}
                                          maxLength={25} // Limit the character count to 26 as per migration
                                        />                           
                                      <div className="response-error-message">
                                        {showFieldErrors(responseErrors.other_expenses_type)}
                                    </div>
                                    </div>
                                  )}
                                  {/** the end of of the part that is shown whem expenses type is selected from the drop down */}

                                  {/* capture amount paid */}
                                  <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                                    <InputLabel
                                      inputId="spent_amount"
                                      inputStyle="block text-sm font-medium text-gray-700 text-left"
                                      labelText={t('income_expenses_page.amount_spent')}
                                    />
                                    <NumberInput
                                      focus={false}
                                      inputName="spent_amount"
                                      inputId="spent_amount"
                                      hint="0"
                                      isRequired={false}
                                      isDisabled={false}
                                      inputStyle={textInputStyle.current.enabledInput}
                                      inputValue={formState.spent_amount}
                                      inputStep="0.01"
                                      handleChange={setFormState}
                                    />
                                    <div className="response-error-message">
                                      {showFieldErrors(responseErrors.spent_amount)}
                                    </div>
                                  </div>

                                  {/* Notes - textarea */}
                                  <div className="col-span-6">
                                    <InputLabel
                                      inputId="notes_expenses"
                                      inputStyle="block text-sm font-medium text-gray-700 text-left"
                                      labelText={t('income_expenses_page.notes_title')}
                                    />
                                    
                                    <TextareaInput
                                      focus={false}
                                      inputName="notes_expenses"
                                      inputId="notes_expenses"
                                      hint={t('income_expenses_page.notes_hint')} 
                                      isRequired={false}
                                      inputRows={3}
                                      maxLength={256}
                                      inputStyle={textInputStyle.current.enabledInput}
                                      inputValue={formState.notes_expenses}
                                      handleChange={handleChangeExpenses}
                                    /> 
                                    {formState.notes_expenses.length > 128 && formState.notes_expenses.length < 256 ? (
                                      <div className="response-info-message">
                                        {showFieldInfo(responseErrors.notes_expenses)}
                                      </div>
                                    ) : (
                                      <div className="response-error-message">
                                        {showFieldErrors(responseErrors.notes_expenses)}
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div className="overflow-hidden sm:rounded-md" style={{ fontSize: '14px' }}>
                                <Footer respNotice={createNotice} setRespNotice={setCreateNotice} footerType="create"/>
                              </div>
                            </div>
                    </form>
                  </TabContent>
                  
                  <TabContent id="tab3" activeTab={activeTab}>
                    {!reportData || reportData.length === 0 ? (
                      <div>{t('validation_messages.no_data_available')}</div> 
                    ): (
                      <div style={{marginLeft: '25px'}}>
                        <h2 style={{textAlign: 'left', fontSize: '1.2rem'}}>{t('income_expenses_page.current_month')} :</h2>
                        <table style={{marginLeft: '1px'}}>
                          <tbody>

                            <tr>
                              <td style={{textAlign: 'left', fontSize: '1.0rem'}}>{t('income_expenses_page.opening_balance_amount')} :</td> 
                              <td style={{textAlign: 'right', fontSize: '1.2rem'}}>{reportData.current_month_summary.total_carry_forward}</td>
                            </tr>

                            <tr>
                              <td style={{textAlign: 'left', fontSize: '1.0rem'}}>{t('income_expenses_page.total_income')} :</td>
                              <td style={{textAlign: 'right', fontSize: '1.2rem'}}>{reportData.current_month_summary.total_income}</td>
                            </tr>
                            <tr style={{borderBottom: '1px solid black'}}>
                              <td style={{textAlign: 'left', fontSize: '1.0rem'}}>{t('income_expenses_page.total_expenses')} :</td>
                              <td style={{textAlign: 'right', fontSize: '1.2rem'}}>{reportData.current_month_summary.total_expenses}</td>
                            </tr>
                            <tr>
                              <td style={{textAlign: 'left', fontSize: '1.0rem'}}>{t('income_expenses_page.net')} :</td>
                              <td style={{textAlign: 'right', fontSize: '1.2rem'}}>{reportData.current_month_summary.net}</td>
                            </tr>
                          </tbody>
                        </table>

                        {(!reportData || !reportData.processedData) ? (
                            <div>
                              <h2 style={{textAlign: 'left', fontSize: '1.2rem'}}>{t('error_message.loading')}</h2> 
                            </div>
                          ):(
                            <TableDAtaView
                              tableData={reportData?.processedData}
                              idUsage={tableSettings?.idUsage}
                              useSerial={tableSettings?.useSerial} 
                              mapHash={{
                                "#": "#",
                                date: t('income_expenses_page.transaction_date'), 
                                item: t('income_expenses_page.transacted_item'), 
                                income: t('income_expenses_page.income'), 
                                expenses: t('income_expenses_page.expenses'), 
                              }}
                              tableStyle="text-sm" // Example class for table styling
                              cellStyle={{ fontSize: '0.8rem' }} // Example style for cell font size
                            />            
                          )}
                        

                        <h2 style={{textAlign: 'left', fontSize: '1.2rem'}}>{t('income_expenses_page.current_year')} :</h2>
                        <table style={{marginLeft: '10px'}}>
                          <tbody>
                            <tr>
                              <td style={{textAlign: 'left', fontSize: '1.0rem'}}>{t('income_expenses_page.total_income')} :</td>
                              <td style={{textAlign: 'right', fontSize: '1.2rem'}}>{reportData.current_year_summary.total_income}</td>
                            </tr>
                            <tr style={{borderBottom: '1px solid black'}}>
                              <td style={{textAlign: 'left', fontSize: '1.0rem'}}>{t('income_expenses_page.total_expenses')} :</td>
                              <td style={{textAlign: 'right', fontSize: '1.2rem'}}>{reportData.current_year_summary.total_expenses}</td>
                            </tr>
                            <tr>
                              <td style={{textAlign: 'left', fontSize: '1.0rem'}}>{t('income_expenses_page.net')} :</td>
                              <td style={{textAlign: 'right', fontSize: '1.2rem'}}>{reportData.current_year_summary.net}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    )}
                    <hr style={{marginLeft: '40px', marginRight: '40px', borderColor: '#cbd5e0', borderWidth: '1px'}} />                  
                    {/** Unlike React library, I find this Google Charts library to be quite handy and useful  
                     *   you can find more information from this link https://www.react-google-charts.com/examples/pie-chart
                    */}
                    <Chart
                      chartType="PieChart"
                      data={reportData.data_current_month_income}
                      options={optionsIncome}
                      width={"100%"}
                      height={"200px"}
                    />

                    <hr style={{marginLeft: '40px', marginRight: '40px', borderColor: '#cbd5e0', borderWidth: '1px'}} />

                    <Chart
                      chartType="PieChart"
                      data={reportData.data_current_month_expenses}
                      options={optionsExpenses}
                      width={"100%"}
                      height={"200px"}
                    />

                  </TabContent>
                  
                  {/** if you want to add another tab, uncomment below  */}
                  {/* <TabContent id="tab3" activeTab={activeTab}>
                    <p>Tab 3 works!</p>
                  </TabContent> */} 

                </div>
            </div>
            
          </div>
          
        </div>
      </center>
    );
}
export { TransactionsForm };






