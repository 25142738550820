import React from 'react';

export const HamburgerMenuItem = ({ onClick, children, itemTitle }) => {
    const handleClick = () => {
      if (onClick) {
        onClick();
      }
    };
  
    return (
      <button onClick={handleClick} className="text-gray-600 hover:text-gray-800 mb-2 cursor-pointer text-left ml-2">
        {children}
        <span className="text-sm uppercase mt-2">{itemTitle}</span>
      </button>
    );
};

export const handleMenuItemClick = (to, navigate, t, queueCount, showToast, i18n) => {
    switch (to) {
        case '/logout':
            // Call the function for '/logout'
            handleLogoutClick(navigate, t);
            break;
        case '/updateUsers':
            // Call the function for '/manager_users'
            handleUpdateUsersClick(navigate, t, showToast);
            break;
        case '/clearQueue': 
            // Call the function for 'clearQueue'
            handleClearQueueClick(t, queueCount, showToast);
            break;
        case '/share':
            handleShareClick(navigate, t, showToast);
            break;
        case '/appmanual':
            handleAppManualClick(navigate, t);
            break;
        case '/tandcs':
            handleTandCsClick(navigate, t);
            break;
        case '/dairyfaming':
            handleDairyFamingClick(navigate, t);
            break;
        case '/faq':
            handleFaqBoardClick(navigate, t);
            break;            
        case '/aboutUs':
            // Call the function for '/aboutUs'
            handleAboutUsClick(navigate, t);
            break;
        case '/feedback':
            handlefeedbackClick(navigate, t);
            break;
        case '/disclaimer':
            handleDisclaimerClick(navigate, t);
            break;
        case '/privacy':
            openPrivacyPopup(i18n);
            break;
        default:
            // Handle other cases if needed
            break;
    }
};



// Function to open the popup
const openPrivacyPopup = (i18n) => {
    // URL of the terms and conditions page
    let termsURL = "https://www.gdf.co.tz"; // Default URL

    // Adjust the URL based on the language
    if (i18n.language === 'en') {
        // termsURL = "https://www.gdf.co.tz/terms/en.html"; // English terms and conditions page URL
        termsURL = "https://www.gdf.co.tz/privacy/en.html"; // English privacy policy page URL
    } else if (i18n.language === 'sw') {
        // termsURL = "https://www.gdf.co.tz/terms/sw.html"; // Swahili terms and conditions page URL
        termsURL = "https://www.gdf.co.tz/privacy/sw.html"; // Swahili privacy policy page URL
    }
    
    // Open a new popup window with the terms and conditions page
    window.open(termsURL, "_blank", "width=400,height=900");
};

// Define your functions for each menu item
const handleLogoutClick = () => {
    // Logic for '/logout'
    // user has been logged out 
    // nothing to be done here, may be in the future
    // we may redirect user to some custom logged out page
};

// Define your functions for each menu item
const handleUpdateUsersClick = (navigate, t, showToast) => {

    // Navigate to Edit Delete view for Users
    navigate('/fe/reports/users');
};

const handleClearQueueClick = (t, queueCount, showToast) => {

    if (queueCount === 0){
        showToast(t('hamburger_messages.there_is_no_queue_data'), 'info');
      }else{
        showToast(t('hamburger_messages.processed_queue_data'), 'success');
        // reload to resolve reseting queue counter

        setTimeout(() => {
            window.location.reload();
        }, 5000); // 5000 milliseconds = 5 seconds

      }

};

// Define your functions for each menu item
const handleShareClick = (navigate, t, showToast) => {
    // Logic for '/share'
    // Trigger share directly without an event listener
    onShare(t, showToast);
    // show some information about Share
    //navigate('/fe/share');
};

// Define your functions for each menu item
const handleAppManualClick = (navigate) => {
    // Logic for '/manual'
    navigate('/fe/appmanual');
};

// Define your functions for each menu item
const handleTandCsClick = (navigate) => {
    // Logic for '/tandcs'
    navigate('/fe/termsandconditions');
};

// Define your functions for each menu item
const handleFaqBoardClick = (navigate) => {
    // Logic for '/dairyfaming'
    navigate('/fe/faq'); 
};

// Define your functions for each menu item
const handleDairyFamingClick = (navigate) => {
    // Logic for '/dairyfaming'
    navigate('/fe/dairyfarming'); 
};

const handleAboutUsClick = (navigate) => {
    // Navigate to the desired route
    navigate('/fe/aboutus');
};

// Define your functions for each menu item
const handlefeedbackClick = (navigate) => {
    // Logic for '/feedback'
    navigate('/fe/feedback');
};

// Define your functions for each menu item
const handleDisclaimerClick = (navigate) => {
    // Logic for '/disclaimer'
    navigate('/fe/disclaimer');
};


async function onShare(t, showToast) {
    // const title = "GDF Information";
    // const url = "https://www.gdf.co.tz";
    // const text = "GDF App®. Nurturing high-quality dairy farming. Access from www.app.gdf.co.tz/fe";

    const content = {
        title: t('validation_messages.sharing_title'),
        text: t('validation_messages.sharing_text')
        // url: "https://www.gdf.co.tz",
      };

    // check to make sure the web share api is supported by your browser
    if (navigator.share) {
        //Your code here
        try {
            await navigator
            .share(content)
            // Show a message if the user shares something
            // here we need alert as the app will be behind other app and user returns later
            alert((t('validation_messages.thank_you_for_sharing')), (t('validation_messages.sharing_alert_title')));
        } catch (err) {
            // This error will appear if the user cancels the action of sharing.
            showToast((t('validation_messages.sharing_alert_title') + ' ' + t('error_message.share_not_completed')), 'error');
        }
    } else {
        // Toast user if the device does not support
        showToast((t('validation_messages.sharing_alert_title') + ' ' + t('error_message.share_device_not_support')), 'info');
    }
}; 
  
// Custom alert function with a title
const alert = (message, title) => {
    window.alert(`${title ? title + ': ' : ''}${message}`);
};


