export const MilkBottleSolidIcon = () => {
    return (
        <svg 
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            fill="#404040"
            viewBox="0 0 512 512"
            strokeWidth="1.5"
            stroke="currentColor"
            className="w-10 h-10"
        >
            <metadata>
                Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools
            </metadata>
            <g>
                <path d="M360.273,137.058c-11.117-25.722-17.455-65.426-19.045-83.196c13.676-1.363,24.376-12.763,24.376-26.794
                    C365.604,12.11,353.494,0,338.543,0H173.456c-14.951,0-27.061,12.11-27.061,27.068c0,14.031,10.708,25.431,24.376,26.794
                    c-1.576,17.77-7.928,57.475-19.038,83.196c-20.651,47.775-25.572,92.81-25.572,115.076c0,22.258,0,211.768,0,211.768
                    c0,26.565,21.533,48.098,48.106,48.098H337.74c26.557,0,48.098-21.534,48.098-48.098c0,0,0-189.51,0-211.768
                    C385.838,229.868,380.926,184.833,360.273,137.058z"/>
            </g>
        </svg>
    );

}

export const MilkBottleOutlineIcon = () => {
    return (
        <svg 
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            fill="#404040"
            viewBox="0 0 223.792 223.792"
            strokeWidth="1.5"
            stroke="currentColor"
            className="w-10 h-10"
        >
            <metadata>
                Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools
            </metadata>
            <g>
                <path d="M58.104,94.392v107.086c0,12.305,10.675,22.315,23.795,22.315h59.994c13.12,0,23.795-10.01,23.795-22.315V94.392
                    c0-9.181-2.911-23.053-6.629-31.584L142.732,25.36c-1.655-3.797-6.072-5.532-9.872-3.88c-3.797,1.655-5.534,6.075-3.88,9.872
                    l16.328,37.449c2.866,6.576,5.38,18.534,5.38,25.59v107.086c0,3.965-4.027,7.315-8.795,7.315H81.899
                    c-4.768,0-8.795-3.35-8.795-7.315V94.392c0-7.012,2.624-18.85,5.616-25.331L96.061,31.5c1.736-3.761,0.095-8.217-3.666-9.953
                    c-3.76-1.737-8.216-0.095-9.953,3.666L65.101,62.774C61.178,71.275,58.104,85.163,58.104,94.392z"/>
                <path d="M139.037,15c4.143,0,7.5-3.357,7.5-7.5s-3.357-7.5-7.5-7.5H86.121c-4.143,0-7.5,3.357-7.5,7.5s3.357,7.5,7.5,7.5H139.037z"
                    />
            </g>
        </svg>
    );

}
export const FemaleCattleIcon = () => {
    return (
        <svg
            version="1.0"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 1280.000000 792.000000"
            strokeWidth="1.5"
            stroke="currentColor"
            className="w-10 h-10"
            preserveAspectRatio="xMidYMid meet"
        >
            <metadata>
                Created by potrace 1.15, written by Peter Selinger 2001-2017
            </metadata>
            <g 
                transform="translate(0.000000,792.000000)
                scale(0.100000,-0.100000)"
                fill="#404040"
                stroke="none"
            >
                <path d="M10804 7871 c-37 -36 -62 -51 -90 -56 -21 -3 -55 -12 -74 -19 -32
                -11 -35 -15 -36 -55 -1 -39 -6 -48 -78 -120 -45 -45 -76 -85 -74 -92 3 -8 1
                -22 -4 -32 -10 -20 -22 -19 -213 20 -149 29 -243 38 -326 29 l-64 -7 -44 -66
                c-42 -62 -53 -101 -41 -143 4 -11 -13 -21 -60 -37 -123 -41 -211 -55 -388 -60
                -185 -6 -292 2 -552 43 -515 81 -603 88 -878 69 -359 -25 -708 -35 -1582 -45
                -831 -10 -1094 -17 -1355 -40 -113 -10 -127 -9 -231 14 -248 57 -517 91 -1019
                131 -607 49 -832 79 -1120 151 -77 19 -177 48 -222 63 -72 25 -88 27 -135 19
                -29 -5 -233 -11 -453 -12 -571 -4 -639 -18 -917 -182 -110 -65 -150 -96 -233
                -179 -209 -209 -307 -464 -355 -925 -6 -58 -18 -152 -26 -210 -19 -125 -20
                -379 -4 -505 7 -49 13 -209 15 -355 2 -162 11 -358 24 -505 33 -386 26 -672
                -24 -858 -8 -32 -33 -106 -54 -165 -113 -305 -191 -768 -191 -1126 0 -310 57
                -611 157 -840 30 -66 36 -92 37 -155 1 -105 -14 -310 -26 -346 -5 -16 -22 -97
                -38 -180 -16 -82 -32 -160 -36 -172 -6 -21 -3 -23 29 -23 26 0 41 -7 56 -25
                24 -32 29 -52 40 -186 5 -59 14 -116 20 -128 14 -26 72 -56 154 -81 98 -29
                156 -39 334 -55 93 -9 195 -20 227 -26 74 -13 92 0 82 56 -6 28 -37 68 -150
                188 -78 84 -169 180 -201 215 l-58 63 7 87 c67 850 182 1359 409 1807 67 131
                77 146 171 240 95 95 171 148 245 170 l23 7 -4 -103 c-4 -98 -8 -116 -78 -319
                -70 -204 -74 -220 -78 -322 l-5 -108 62 -125 c115 -235 184 -434 326 -935 99
                -351 150 -511 211 -663 33 -82 45 -127 50 -188 l7 -81 31 5 c64 9 91 -20 130
                -140 39 -119 183 -190 430 -213 184 -18 299 -30 322 -36 38 -10 42 50 4 64
                -16 7 -69 69 -265 311 -129 160 -180 275 -190 433 -4 59 -13 85 -64 187 -245
                487 -319 885 -226 1206 27 92 46 135 103 230 30 50 37 70 37 117 0 99 43 134
                213 178 59 14 118 32 131 39 31 17 37 7 46 -77 5 -49 13 -71 30 -88 71 -71
                146 28 130 172 -9 87 3 94 145 82 137 -11 162 -26 172 -95 11 -72 48 -148 73
                -148 31 0 58 66 65 161 5 62 13 92 33 127 27 46 41 54 151 88 28 8 75 47 172
                141 188 182 239 200 346 122 52 -39 122 -57 380 -99 429 -69 1103 -100 1156
                -52 38 34 101 47 236 48 72 0 156 5 186 11 30 5 132 11 225 13 94 2 240 9 325
                15 221 16 501 14 613 -5 128 -20 156 -30 193 -66 19 -18 39 -29 47 -26 24 9
                28 -64 21 -368 -6 -216 -4 -297 6 -350 17 -89 18 -321 1 -445 -7 -52 -37 -220
                -67 -373 -72 -376 -77 -418 -77 -597 1 -116 6 -178 21 -244 14 -64 17 -97 11
                -115 -40 -103 145 -319 328 -382 89 -30 186 -41 311 -33 l114 7 -7 34 c-9 48
                -36 109 -113 253 -83 155 -129 258 -142 315 -13 59 4 568 25 713 19 137 54
                262 101 362 40 85 94 170 109 170 6 0 23 -10 38 -23 25 -19 34 -43 67 -172 42
                -164 98 -433 159 -765 31 -168 46 -228 70 -275 24 -47 34 -86 46 -180 17 -131
                35 -193 65 -220 17 -16 43 -16 325 -10 231 6 308 11 311 20 9 22 -16 138 -42
                194 -28 62 -68 119 -180 257 -121 148 -149 191 -134 209 7 8 8 15 3 15 -21 0
                -92 325 -118 532 -19 162 -19 469 0 607 15 101 14 105 -11 214 -26 116 -25
                150 6 190 17 21 32 26 97 32 296 27 542 127 726 295 48 43 188 220 275 347 46
                66 97 165 186 359 51 110 111 227 133 260 65 96 145 246 246 459 143 303 229
                453 340 590 33 41 70 101 88 145 86 207 195 380 298 473 58 51 59 51 144 37
                32 -5 163 -14 290 -20 326 -16 484 -42 601 -102 38 -19 48 -20 270 -9 251 11
                313 23 408 73 51 27 59 36 75 82 12 34 18 79 17 136 0 76 -3 92 -33 150 -35
                66 -76 111 -343 365 -120 113 -165 177 -193 268 -12 41 -12 73 -2 209 l11 161
                101 75 c199 149 255 222 255 331 0 58 -1 60 -46 100 -73 64 -151 86 -309 86
                -110 0 -140 -4 -197 -23 -39 -14 -76 -21 -88 -17 -20 6 -76 61 -92 90 -13 22
                29 61 85 79 77 24 97 41 97 80 0 41 8 39 -281 61 -228 17 -265 23 -390 65
                -113 37 -210 50 -384 50 l-130 0 -51 -49z"/>  
            </g>
        </svg>
    );

}

export const TasksOpenIcon = () => {
    return (
        <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 512 512"
            strokeWidth="1.5"
            stroke="currentColor"
            className="w-10 h-10"
            preserveAspectRatio="xMidYMid meet"
        >
            <metadata>
                Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools
            </metadata>
            <title>tasks-open</title>
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Combined-Shape" fill="#000000" transform="translate(85.333333, 42.666667)">
                    <path d="M1.42108547e-14,7.10542736e-15 L106.666667,7.10542736e-15 L106.666667,106.666667 L1.42108547e-14,106.666667 L1.42108547e-14,7.10542736e-15 Z M32,32 L32,74.6666667 L74.6666667,74.6666667 L74.6666667,32 L32,32 Z M170.666667,42.6666667 L341.333333,42.6666667 L341.333333,85.3333333 L170.666667,85.3333333 L170.666667,42.6666667 Z M1.42108547e-14,149.333333 L106.666667,149.333333 L106.666667,256 L1.42108547e-14,256 L1.42108547e-14,149.333333 Z M32,181.333333 L32,224 L74.6666667,224 L74.6666667,181.333333 L32,181.333333 Z M170.666667,192 L341.333333,192 L341.333333,234.666667 L170.666667,234.666667 L170.666667,192 Z M1.42108547e-14,298.666667 L106.666667,298.666667 L106.666667,405.333333 L1.42108547e-14,405.333333 L1.42108547e-14,298.666667 Z M32,330.666667 L32,373.333333 L74.6666667,373.333333 L74.6666667,330.666667 L32,330.666667 Z M170.666667,341.333333 L341.333333,341.333333 L341.333333,384 L170.666667,384 L170.666667,341.333333 Z" />
                </g>
            </g>
        </svg>
    );

}

export const DataBoardIcon = () => {
    return (
        <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 1024 1024"
            strokeWidth="1.5"
            stroke="currentColor"
            className="w-10 h-10"
            preserveAspectRatio="xMidYMid meet"
        >
            <metadata>
                Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools
            </metadata>
            <path fill="#000000" d="M32 128h960v64H32z"/>
	        <path fill="#000000" d="M192 192v512h640V192H192zm-64-64h768v608a32 32 0 01-32 32H160a32 32 0 01-32-32V128z"/>
	        <path fill="#000000" d="M322.176 960H248.32l144.64-250.56 55.424 32L322.176 960zm453.888 0h-73.856L576 741.44l55.424-32L776.064 960z"/>
        </svg>
    );

}

export const WaterResourceIcon = () => {
    return (
        <svg
            version="1.0"
            xmlns="http://www.w3.org/2000/svg"
            fill="#000000"
            viewBox="0 0 1024 1024"
            strokeWidth="0.5"
            stroke="currentColor"
            className="w-10 h-10"
            preserveAspectRatio="xMidYMid meet"
        >
            <metadata>
                Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools
            </metadata>
            <path d="M512 201.076364c33.978182 55.156364 69.818182 105.658182 103.563636 152.901818C700.741818 473.6 768 568.087273 768 674.909091c0 116.363636-114.269091 256-256 256S256 791.272727 256 674.909091c0-106.821818 67.258182-201.309091 152.436364-320.930909 33.512727-46.545455 69.818182-97.745455 103.563636-152.901818M512 0C395.636364 279.272727 162.909091 442.181818 162.909091 674.909091c0 170.589091 160.581818 349.090909 349.090909 349.090909s349.090909-178.501818 349.090909-349.090909c0-232.727273-232.727273-395.636364-349.090909-674.909091zM575.534545 744.727273a116.363636 116.363636 0 0 1-80.058181-31.650909L465.454545 683.52a23.272727 23.272727 0 0 0-31.883636 0l-28.16 26.763636a46.545455 46.545455 0 1 1-64.232727-67.490909l28.16-26.763636a116.363636 116.363636 0 0 1 160.116363 0L558.545455 645.585455a23.272727 23.272727 0 0 0 31.883636 0l28.16-26.763637a46.545455 46.545455 0 1 1 64.232727 67.490909l-28.16 26.763637a116.363636 116.363636 0 0 1-79.127273 31.650909z" />
        </svg>
    );

}

export const CleanEnvIcon = () => {
    return (
        <svg
            version="1.0"
            xmlns="http://www.w3.org/2000/svg"
            fill="#000000"
            viewBox="0 0 512 512"
            strokeWidth="1.5"
            stroke="currentColor"
            className="w-10 h-10"
            preserveAspectRatio="xMidYMid meet"
        >
            <metadata>
                Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools
            </metadata>
            <g id="garbage_cleaning_properly">
                <path d="M462.08,71.69H420.34a19.2,19.2,0,0,0-19.16,19.2v97.63H327.09c-2.66-1.76-5.4-3.43-8.19-5V90.89a19.2,19.2,0,0,0-19.16-19.2H258a19.2,19.2,0,0,0-19.16,19.2v77.43a129.14,129.14,0,0,0-26.93,6.6A69.16,69.16,0,0,0,198.83,142l-43-58.36v-22h13.62a7,7,0,0,0,7-7v-27A19.35,19.35,0,0,0,157.11,8.3H89.32A19.35,19.35,0,0,0,70,27.63v27a7,7,0,0,0,7,7H89.37V85.71l-42.29,56a70.14,70.14,0,0,0-14.09,42V462.93a39.93,39.93,0,0,0,39.88,39.88h99.69a39.93,39.93,0,0,0,39.88-39.88V418.47a128.23,128.23,0,0,0,26.4,6.42V470.5a32.3,32.3,0,0,0,32.21,32.31H449a32.29,32.29,0,0,0,32.2-32.31V90.89A19.19,19.19,0,0,0,462.08,71.69Zm-61,130.83a15.78,15.78,0,0,1-15.62,14H357.56a130.11,130.11,0,0,0-12.79-14Zm-29.64,94.09A115.42,115.42,0,1,1,256,181.19,115.55,115.55,0,0,1,371.42,296.61Zm-118.58-186h30a7,7,0,0,0,0-14h-30V90.89a5.19,5.19,0,0,1,5.16-5.2h41.74a5.19,5.19,0,0,1,5.16,5.2v85.9a128.62,128.62,0,0,0-48.9-9.6c-1.06,0-2.11,0-3.16,0ZM84,47.65v-20a5.33,5.33,0,0,1,5.33-5.33h67.79a5.33,5.33,0,0,1,5.33,5.33v20H84Zm57.83,14V75.1H103.37V61.65Zm56.62,401.28a25.91,25.91,0,0,1-25.88,25.88H167.5l9.65-16.14a7,7,0,0,0-12-7.19l-14,23.33h-19.6V469.07a7,7,0,0,0-14,0v19.74H98L84,465.48a7,7,0,1,0-12,7.19l9.65,16.14h-8.8A25.91,25.91,0,0,1,47,462.93V349.54h62.56a7,7,0,1,0,0-14H47V281.9h62.56a7,7,0,1,0,0-14H47V183.76a56.11,56.11,0,0,1,11.26-33.6L104.36,89.1h38.11l45.09,61.18a55.32,55.32,0,0,1,10.81,30.47,129.38,129.38,0,0,0,.07,231.75ZM449,488.81h-178a18.26,18.26,0,0,1-18.15-17.13H467.17A18.25,18.25,0,0,1,449,488.81Zm18.2-31.13H252.84V426c1,0,2.1,0,3.16,0A129.37,129.37,0,0,0,367.21,230.48h18.23a29.82,29.82,0,0,0,29.74-29.83v-90h30a7,7,0,1,0,0-14h-30V90.89a5.19,5.19,0,0,1,5.16-5.2h41.74a5.18,5.18,0,0,1,5.15,5.2Z"/>
                <path d="M235.92,230.2v44.28h-21.4a16.21,16.21,0,0,0-16.12,14.39l-2.14,19h0l-1.35,12c-.83,7.39-4.48,14.64-10.84,21.57a12.7,12.7,0,0,0,9.33,21.3h107a18,18,0,0,0,18-19.32l-2.56-35.12h0l-1.36-18.74a16.27,16.27,0,0,0-16.17-15H277.21V230.2a20.65,20.65,0,1,0-41.29,0Zm69.46,114.11a5,5,0,0,1-5,5.38h-9.31l-1.24-14.82a6.5,6.5,0,0,0-13,1.09L278,349.69H260.61V335.42a6.5,6.5,0,1,0-13,0v14.27H230.2L231.36,336a6.5,6.5,0,0,0-13-1.09l-1.24,14.82H194.1c8-8.86,12.6-18.41,13.73-28.41l.7-6.2,94.73.13Zm-7.12-56.83a3.22,3.22,0,0,1,3.2,3l.86,11.75L210,302.09l1.32-11.76a3.21,3.21,0,0,1,3.2-2.85ZM248.92,230.2a7.65,7.65,0,1,1,15.29,0v42.13H248.92Z"/>
            </g>
        </svg>
    );

}

export const WeightKgIcon = () => {
    return (
        <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 16 16"
            strokeWidth="1.5"
            stroke="currentColor"
            className="w-10 h-10 si-glyph si-glyph-weight-kilograms"
            preserveAspectRatio="xMidYMid meet"
        >
            <metadata>
                Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools
            </metadata>
            <title>Weight-kilograms</title>
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g transform="translate(1.000000, 0.000000)" fill="#434343">
                    <rect x="8" y="9" width="0.905" height="0.92" className="si-glyph-fill">
                    </rect>
                    <path d="M11.017,4.42369478 C11.017,3.69176707 10.42,3.01706827 9.682,3.01706827 L8.913,3.01706827 C8.948,2.84839357 8.97,2.67369478 8.97,2.4939759 C8.97,1.11947791 7.855,0 6.486,0 C5.116,0 4.002,1.11947791 4.002,2.4939759 C4.002,2.67369478 4.023,2.84839357 4.059,3.01706827 L3.315,3.01706827 C2.58,3.01706827 1.983,3.69176707 1.983,4.42369478 L0.00800000003,13.6736948 C0.00800000003,14.4066265 0.606,15 1.344,15 L11.657,15 C12.393,15 12.99,14.4066265 12.99,13.6736948 L11.017,4.42369478 L11.017,4.42369478 Z M5,2.50802644 C5,1.67610954 5.67272158,1 6.50046978,1 C7.32633887,1 8,1.67610954 8,2.50802644 C8,2.68177526 7.96523645,2.84419263 7.9116818,3 L5.08925775,3 C5.03476355,2.84513692 5,2.68177526 5,2.50802644 L5,2.50802644 Z M6.047,9.005 L5.045,9.005 L5.045,9.953 L6.047,9.953 L6.047,11.02 L4.982,11.02 L4.982,10.015 L4.043,10.015 L4.043,11.02 L2.962,11.02 L2.962,5.911 L4.043,5.911 L4.043,8.942 L4.982,8.942 L4.982,7.937 L6.047,7.937 L6.047,9.005 L6.047,9.005 Z M10.047,13.023 L9.047,13.023 L9.047,14.046 L7.985,14.046 L7.985,13.038 L8.049,12.975 L8.941,12.958 L8.953,11.021 L6.986,11.021 L6.978,7.952 L10.048,7.965 L10.048,13.023 L10.047,13.023 Z" className="si-glyph-fill">
                    </path>
                </g>
            </g>
        </svg>
    );

}

export const UsersPeopleIcon = () => {
    const cls1 = {
        fill: "none",
        stroke: "#000000",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeWidth: "2px",
    }
    return (
        <svg
            version="1.0"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 32 32"
            strokeWidth="1.5"
            stroke="currentColor"
            className="w-10 h-10"
            preserveAspectRatio="xMidYMid meet"
        >
            <metadata>
                Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools
            </metadata>
            <g data-name="79-users" id="_79-users">
                <circle style={cls1} cx="16" cy="13" r="5"/>
                <path style={cls1} d="M23,28A7,7,0,0,0,9,28Z"/>
                <path style={cls1} d="M24,14a5,5,0,1,0-4-8"/>
                <path style={cls1} d="M25,24h6a7,7,0,0,0-7-7"/>
                <path style={cls1} d="M12,6a5,5,0,1,0-4,8"/>
                <path style={cls1} d="M8,17a7,7,0,0,0-7,7H7"/>
            </g>
        </svg>
    );

}

export const LogoutExitIcon = () => {
    return (
        <svg
            version="1.0"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 1000 1000" 
            strokeWidth="1.5"
            stroke="currentColor"
            className="w-10 h-10"
            preserveAspectRatio="xMidYMid meet"
        >
            <metadata>
                Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools
            </metadata>
            <path
                style={
                    {
                        fill: "none",
                        stroke: "#020202",
                        strokeLinecap: "round",
                        strokeMiterlimit: "10",
                        strokeWidth: "22px",

                    }
                }
                d="M591.61,280.48C693.9,317.86,766.91,416,766.91,531.26c0,147.41-119.5,266.91-266.91,266.91S233.09,678.67,233.09,531.26c0-115.22,73-213.4,175.3-250.78"
            />
            <rect
                style={
                    {fill : "#020202"}
                }
                height="160.61"
                rx="35.92"
                width="71.84"
                x="464.08"
                y="201.83"
            />
        </svg>
    );

}

export const SearchIcon = () => {
    return (
        <svg
            version="1.0"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="w-10 h-10"
            preserveAspectRatio="xMidYMid meet"
        >
            <metadata>
                Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools
            </metadata>
            <path d="M15 15L21 21" stroke="#323232" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M17 10C17 13.866 13.866 17 10 17C6.13401 17 3 13.866 3 10C3 6.13401 6.13401 3 10 3C13.866 3 17 6.13401 17 10Z" stroke="#323232" strokeWidth="2"/>
        </svg>
    );

}

export const StopIcon = () => {
    return (
        <svg
            version="1.0"
            xmlns="http://www.w3.org/2000/svg"
            fill="rgb(156 163 175)"
            viewBox="0 0 16 16"
            strokeWidth="0.2"
            stroke="currentColor"
            className="w-10 h-10 text-gray-300"
            preserveAspectRatio="xMidYMid meet"
        >
            <metadata>
                Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools
            </metadata>
            <path d="M3 7.38h10v1.25H3z"/>
            <path d="M8 .5A7.76 7.76 0 0 0 0 8a7.76 7.76 0 0 0 8 7.5A7.76 7.76 0 0 0 16 8 7.76 7.76 0 0 0 8 .5zm0 13.75A6.52 6.52 0 0 1 1.25 8 6.52 6.52 0 0 1 8 1.75 6.52 6.52 0 0 1 14.75 8 6.52 6.52 0 0 1 8 14.25z"/>
        </svg>
    );

}

export const AnalyzeIcon = () => {
    return (
        <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
            className="w-12 h-12"
            strokeWidth="0"
        >
            <metadata>
                Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools
            </metadata>

            <g id="Page-1" stroke="none" fill="none" fillRule="evenodd">
                <g id="add" fill="#666666" transform="translate(42.666667, 64.000000)">
                    <path strokeWidth="0.1" d="M266.666667,128 C331.468077,128 384,180.531923 384,245.333333 C384,270.026519 376.372036,292.938098 363.343919,311.840261 L423.228475,371.725253 L393.058586,401.895142 L333.173594,342.010585 C314.271431,355.038703 291.359852,362.666667 266.666667,362.666667 C201.865256,362.666667 149.333333,310.134744 149.333333,245.333333 C149.333333,180.531923 201.865256,128 266.666667,128 Z M266.666667,170.666667 C225.429405,170.666667 192,204.096072 192,245.333333 C192,286.570595 225.429405,320 266.666667,320 C307.903928,320 341.333333,286.570595 341.333333,245.333333 C341.333333,204.096072 307.903928,170.666667 266.666667,170.666667 Z M128.404239,234.665576 C128.136379,238.186376 128,241.743928 128,245.333333 C128,256.34762 129.284152,267.061976 131.710904,277.334851 L7.10542736e-15,277.333333 L7.10542736e-15,234.666667 L128.404239,234.665576 Z M85.3333333,1.42108547e-14 L85.3333333,213.333333 L21.3333333,213.333333 L21.3333333,1.42108547e-14 L85.3333333,1.42108547e-14 Z M170.666667,85.3333333 L170.663947,145.273483 C151.733734,163.440814 137.948238,186.928074 131.710904,213.331815 L106.666667,213.333333 L106.666667,85.3333333 L170.666667,85.3333333 Z M256,42.6666667 L255.999596,107.070854 C232.554315,108.854436 210.738728,116.46829 191.999452,128.465799 L192,42.6666667 L256,42.6666667 Z M341.333333,64 L341.333983,128.465865 C322.594868,116.468435 300.779487,108.854588 277.334424,107.070906 L277.333333,64 L341.333333,64 Z" id="Combined-Shape">
                    </path>
                </g>
            </g>
        </svg>
    );
}

export const ReportDataIcon = () => {
    return (
        <svg
            version="1.1"
            fill="#323232"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 32 32"
            className="w-10 h-10"
            id="icon-rd" 
        >
            <metadata>
                Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools
            </metadata>
			<title>report</title>
			<rect x="15" y="20" width="2" height="4"/>
			<rect x="20" y="18" width="2" height="6"/>
			<rect x="10" y="14" width="2" height="10"/>
			<path d="M25,5H22V4a2,2,0,0,0-2-2H12a2,2,0,0,0-2,2V5H7A2,2,0,0,0,5,7V28a2,2,0,0,0,2,2H25a2,2,0,0,0,2-2V7A2,2,0,0,0,25,5ZM12,4h8V8H12ZM25,28H7V7h3v3H22V7h3Z"/>
			<rect id="_Transparent_Rectangle_" fill="none" data-name="&lt;Transparent Rectangle&gt;" className="h-[32px] w-[32px]"/>
        </svg>
    );
}

export const ReportLineChartIcon = () => {
    return (
        <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512" 
            className="w-10 h-10"
            id="icon-rd" 
        >
            <metadata>
                Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools
            </metadata>
            <title>report-linechart</title>
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="add" fill="#323232" transform="translate(42.666667, 85.333333)">
                    <path d="M341.333333,1.42108547e-14 L426.666667,85.3333333 L426.666667,341.333333 L3.55271368e-14,341.333333 L3.55271368e-14,1.42108547e-14 L341.333333,1.42108547e-14 Z M330.666667,42.6666667 L42.6666667,42.6666667 L42.6666667,298.666667 L384,298.666667 L384,96 L330.666667,42.6666667 Z M106.666667,85.3333333 L106.666333,217.591333 L167.724208,141.269742 L232.938667,173.866667 L280.864376,130.738196 L295.135624,146.595138 L236.398693,199.458376 L173.589333,168.064 L120.324333,234.666333 L341.333333,234.666667 L341.333333,256 L85.3333333,256 L85.3333333,85.3333333 L106.666667,85.3333333 Z" id="Combined-Shape">
                    </path>
                </g>
            </g>
        </svg>
    );
}

export const ReportTextIcon = () => {
    return (
        <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 512" 
        className="w-10 h-10"
        id="icon-rd" 
    >
        <metadata>
            Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools
        </metadata>
        <title>report-text</title>
        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="add" fill="#4d4d4d" transform="translate(42.666667, 85.333333)">
                <path d="M341.333333,1.42108547e-14 L426.666667,85.3333333 L426.666667,341.333333 L3.55271368e-14,341.333333 L3.55271368e-14,1.42108547e-14 L341.333333,1.42108547e-14 Z M330.666667,42.6666667 L42.6666667,42.6666667 L42.6666667,298.666667 L384,298.666667 L384,96 L330.666667,42.6666667 Z M149.333333,234.666667 L149.333333,266.666667 L85.3333333,266.666667 L85.3333333,234.666667 L149.333333,234.666667 Z M341.333333,234.666667 L341.333333,266.666667 L192,266.666667 L192,234.666667 L341.333333,234.666667 Z M149.333333,170.666667 L149.333333,202.666667 L85.3333333,202.666667 L85.3333333,170.666667 L149.333333,170.666667 Z M341.333333,170.666667 L341.333333,202.666667 L192,202.666667 L192,170.666667 L341.333333,170.666667 Z M149.333333,106.666667 L149.333333,138.666667 L85.3333333,138.666667 L85.3333333,106.666667 L149.333333,106.666667 Z M341.333333,106.666667 L341.333333,138.666667 L192,138.666667 L192,106.666667 L341.333333,106.666667 Z" id="Combined-Shape">
                </path>
            </g>
        </g>
    </svg>
    );
}

export const ReportBarChartIcon = () => {
    return (
        <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
            className="w-10 h-10"
            id="icon-rd" 
        >
            <metadata>
                Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools
            </metadata>
            <title>report-barchart</title>
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="add" fill="#4d4d4d" transform="translate(42.666667, 85.333333)">
                    <path d="M341.333333,1.42108547e-14 L426.666667,85.3333333 L426.666667,341.333333 L3.55271368e-14,341.333333 L3.55271368e-14,1.42108547e-14 L341.333333,1.42108547e-14 Z M330.666667,42.6666667 L42.6666667,42.6666667 L42.6666667,298.666667 L384,298.666667 L384,96 L330.666667,42.6666667 Z M106.666667,85.3333333 L106.666,234.666 L341.333333,234.666667 L341.333333,256 L85.3333333,256 L85.3333333,85.3333333 L106.666667,85.3333333 Z M170.666667,149.333333 L170.666667,213.333333 L128,213.333333 L128,149.333333 L170.666667,149.333333 Z M234.666667,106.666667 L234.666667,213.333333 L192,213.333333 L192,106.666667 L234.666667,106.666667 Z M298.666667,170.666667 L298.666667,213.333333 L256,213.333333 L256,170.666667 L298.666667,170.666667 Z" id="Combined-Shape">
                    </path>
                </g>
            </g>
        </svg>
    );
}

export const ActivityIcon = () => {
    return (
        <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 -5.5 20 20"
            className="w-10 h-10" 
        >
            <metadata>
                Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools
            </metadata>
            <title>activity</title>
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="Dribbble-Light-Preview" transform="translate(-260.000000, -3845.000000)" fill="#000000">
            <g id="icons" fill="#666666" transform="translate(56.000000, 160.000000)">
                <path d="M216.512,3685 L210.796,3690.79 L208.051,3688.155 L208.044,3688.162 L208.038,3688.156 L204,3692.386 L205.453,3693.8 L208.115,3691.033 L210.859,3693.653 L216.512,3687.885 L219.252,3690.66 C219.545,3690.028 220.046,3689.514 220.666,3689.208 L216.512,3685 Z M224,3691.96 C224,3693.087 223.098,3694 221.986,3694 C220.874,3694 219.973,3693.087 219.973,3691.96 C219.973,3690.833 220.874,3689.92 221.986,3689.92 C223.098,3689.92 224,3690.833 224,3691.96 L224,3691.96 Z" id="activity-[#985]">
                </path>
            </g>
        </g>
            </g>
        </svg>
    );
}

export const EventsAltIcon = () => {
    return (
        <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 32 32" 
            className="w-10 h-10"
            fill="#4d4d4d"
        >
            <metadata>
                Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools
            </metadata>
            <title>events--alt</title>
			<path d="M18,31h2V29a1.0006,1.0006,0,0,1,1-1h6a1.0006,1.0006,0,0,1,1,1v2h2V29a3.0033,3.0033,0,0,0-3-3H21a3.0033,3.0033,0,0,0-3,3Z" transform="translate(0 0)"/>
			<path d="M24,25a4,4,0,1,1,4-4A4.0039,4.0039,0,0,1,24,25Zm0-6a2,2,0,1,0,2,2A2.0027,2.0027,0,0,0,24,19Z" transform="translate(0 0)"/>
			<path d="M2,31H4V29a1.0009,1.0009,0,0,1,1-1h6a1.0009,1.0009,0,0,1,1,1v2h2V29a3.0033,3.0033,0,0,0-3-3H5a3.0033,3.0033,0,0,0-3,3Z" transform="translate(0 0)"/>
			<path d="M8,25a4,4,0,1,1,4-4A4.0042,4.0042,0,0,1,8,25Zm0-6a2,2,0,1,0,2,2A2.0023,2.0023,0,0,0,8,19Z" transform="translate(0 0)"/>
			<path d="M18,16h2V14a1.0009,1.0009,0,0,1,1-1h6a1.0009,1.0009,0,0,1,1,1v2h2V14a3.0033,3.0033,0,0,0-3-3H21a3.0033,3.0033,0,0,0-3,3Z" transform="translate(0 0)"/>
			<path d="M24,10a4,4,0,1,1,4-4A4.0042,4.0042,0,0,1,24,10Zm0-6a2,2,0,1,0,2,2A2.0023,2.0023,0,0,0,24,4Z" transform="translate(0 0)"/>
			<path d="M2,16H4V14a1.0013,1.0013,0,0,1,1-1h6a1.0013,1.0013,0,0,1,1,1v2h2V14a3.0033,3.0033,0,0,0-3-3H5a3.0033,3.0033,0,0,0-3,3Z" transform="translate(0 0)"/>
			<path d="M8,10a4,4,0,1,1,4-4A4.0045,4.0045,0,0,1,8,10ZM8,4a2,2,0,1,0,2,2A2.002,2.002,0,0,0,8,4Z" transform="translate(0 0)"/>
			<rect id="_Transparent_Rectangle_" data-name="&lt;Transparent Rectangle&gt;" fill="none" className="w-[32px] h-[32px]" />
        </svg>
    );
}


export const TransactionsIcon = () => {
    return (
        <svg 
            xmlns="http://www.w3.org/2000/svg" 
            fill="none" 
            viewBox="0 2 22 22" 
            strokeWidth="1.5" 
            stroke="currentColor" 
            className="w-10 h-10"
        >
            
            <path 
                strokeLinecap="round" 
                strokeLinejoin="round" 
                d="M12 6v12m-3-2.818.879.659c1.171.879 3.07.879 4.242 0 1.172-.879 1.172-2.303 0-3.182C13.536 12.219 12.768 12 12 12c-.725 0-1.45-.22-2.003-.659-1.106-.879-1.106-2.303 0-3.182s2.9-.879 4.006 0l.415.33M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
        </svg>

    );
}

export const PasswordRestIcon = () =>{
    return(
        <svg 
            xmlns="http://www.w3.org/2000/svg" 
            fill="none" 
            viewBox="0 0 24 24" 
            strokeWidth={1.5} 
            stroke="currentColor" 
            className="w-8 h-8">

            {/* Circle with arrows */}
            <circle cx="12" cy="12" r="12" stroke="currentColor" fill="none" />
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M9.75 14.25l2.25-2.25M14.25 14.25l-2.25-2.25"
            />  

            <path 
                strokeLinecap="round" 
                strokeLinejoin="round" d="M16.5 10.5V6.75a4.5 4.5 0 1 0-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 0 0 2.25-2.25v-6.75a2.25 2.25 0 0 0-2.25-2.25H6.75a2.25 2.25 0 0 0-2.25 2.25v6.75a2.25 2.25 0 0 0 2.25 2.25Z" />
        </svg>
    );
}


