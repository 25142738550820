
import { useTranslation } from "react-i18next";

export const CattleDataView = (props) => {

  // for language translation
  const { t } = useTranslation();

  return (
    <div>

      <div className="sm:px-0">
        <h3 className="text-base font-semibold leading-7 text-gray-900">{t('search_page.cattle_information')}</h3>
        {/* <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">Details as Entered at the time of Entry to the Farm.</p> */}
      </div>

      <div className="mt-4 border-y border-gray-300"> 

        <dl className="divide-y divide-gray-300">

          <div className="px-4 py-1 grid grid-cols-4 lg:grid-cols-3 gap-4 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-gray-700 col-span-2 lg:col-span-1">{t('search_page.tag_number')}</dt>
            <dd className="text-sm leading-6 text-gray-600 col-span-2 sm:mt-0">{props.cattleData.id ?? "NA"}</dd>
          </div>

          <div className="px-4 py-1 grid grid-cols-4 lg:grid-cols-3 gap-4 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-gray-700 col-span-2 lg:col-span-1">{t('search_page.state_on_entry')}</dt>
            <dd className="text-sm leading-6 text-gray-600 col-span-2 sm:mt-0">{props.cattleData.state ?? "NA"}</dd>
          </div>
          <div className="px-4 py-1 grid grid-cols-4 lg:grid-cols-3 gap-4 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-gray-700 col-span-2 lg:col-span-1">{t('search_page.breed_type')}</dt>
            <dd className="text-sm leading-6 text-gray-600 col-span-2 sm:mt-0">{props.cattleData.breed ?? "NA"}</dd>
          </div>
          <div className="px-4 py-1 grid grid-cols-4 lg:grid-cols-3 gap-4 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-gray-700 col-span-2 lg:col-span-1">{t('search_page.gender_type')}</dt>
            <dd className="text-sm leading-6 text-gray-600 col-span-2 sm:mt-0">{props.cattleData.gender ?? "NA"}</dd>
          </div>
          <div className="px-4 py-1 grid grid-cols-4 lg:grid-cols-3 gap-4 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-gray-700 col-span-2 lg:col-span-1">{t('search_page.name_search')}</dt>
            <dd className="text-sm leading-6 text-gray-600 col-span-2 sm:mt-0">{props.cattleData.name ?? "NA"}</dd>
          </div>
          <div className="px-4 py-1 grid grid-cols-4 lg:grid-cols-3 gap-4 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-gray-700 col-span-2 lg:col-span-1">{t('search_page.date_of_birth')}</dt>
            <dd className="text-sm leading-6 text-gray-600 col-span-2 sm:mt-0">{props.cattleData.dob ?? "NA"}</dd>
          </div>
          <div className="px-4 py-1 grid grid-cols-4 lg:grid-cols-3 gap-4 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-gray-700 col-span-2 lg:col-span-1">{t('search_page.date_entered_farm')}</dt>
            <dd className="text-sm leading-6 text-gray-600 col-span-2 sm:mt-0">{props.cattleData.dofe ?? "NA"}</dd>
          </div>
          <div className="px-4 py-1 grid grid-cols-4 lg:grid-cols-3 gap-4 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-gray-700 col-span-2 lg:col-span-1">{t('search_page.weight_on_entry')}</dt>
            <dd className="text-sm leading-6 text-gray-600 col-span-2 sm:mt-0">{props.cattleData.weight ? `${props.cattleData.weight} Kg` : "NA"}</dd>
          </div>
          <div className="px-4 py-1 grid grid-cols-4 lg:grid-cols-3 gap-4 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-gray-700 col-span-2 lg:col-span-1">{t('search_page.latest_weight')}</dt>
            <dd className="text-sm leading-6 text-gray-600 col-span-2 sm:mt-0">{props.cattleData.latest_weight ? `${props.cattleData.latest_weight} Kg` : "NA"}</dd>
          </div>
          <div className="px-4 py-1 grid grid-cols-4 lg:grid-cols-3 gap-4 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-gray-700 col-span-2 lg:col-span-1">{t('search_page.came_from')}</dt>
            <dd className="text-sm leading-6 text-gray-600 col-span-2 sm:mt-0">{props.cattleData.source_name ?? "NA"}</dd>
          </div>
          <div className="px-4 py-1 grid grid-cols-4 lg:grid-cols-3 gap-4 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-gray-700 col-span-2 lg:col-span-1">{t('search_page.tag_at_source')}</dt>
            <dd className="text-sm leading-6 text-gray-600 col-span-2 sm:mt-0">{props.cattleData.id_at_source ?? "NA"}</dd>
          </div>
          <div className="px-4 py-1 grid grid-cols-4 lg:grid-cols-3 gap-4 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-gray-700 col-span-2 lg:col-span-1">{t('search_page.search_notes')}</dt>
            <dd className="text-sm leading-6 text-gray-600 col-span-2 sm:mt-0">{props.cattleData.notes ?? "NA"}</dd>
          </div>

          <div className="px-4 py-1 grid grid-cols-4 lg:grid-cols-3 gap-4 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-gray-700 col-span-2 lg:col-span-1">{t('search_page.latest_event')}</dt>
            <dd className="text-sm leading-6 text-gray-600 col-span-2 sm:mt-0">{props.cattleData.latest_event ?? "NA"}</dd>
          </div>

          <div className="px-4 py-1 grid grid-cols-4 lg:grid-cols-3 gap-4 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-gray-700 col-span-2 lg:col-span-1">{t('search_page.treatment_notes')}</dt> 
            <dd className="text-sm leading-6 text-gray-600 col-span-2 sm:mt-0">{props.cattleData.LastTreatment ?? "NA"}</dd>
          </div>

          <div className="px-4 py-1 grid grid-cols-4 lg:grid-cols-3 gap-4 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-gray-700 col-span-2 lg:col-span-1">{t('search_page.vaccination_notes')}</dt>
            <dd className="text-sm leading-6 text-gray-600 col-span-2 sm:mt-0">{props.cattleData.LastVaccination ?? "NA"}</dd>
          </div>

          <div className="px-4 py-1 grid grid-cols-4 lg:grid-cols-3 gap-4 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-gray-700 col-span-2 lg:col-span-1">{t('search_page.deworm_notes')}</dt>
            <dd className="text-sm leading-6 text-gray-600 col-span-2 sm:mt-0">{props.cattleData.LastDeworm ?? "NA"}</dd>
          </div>          



        </dl>
      </div>
    </div>
  )
}
