
export const InfoCircleIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="w-6 h-6"
        >
            <path strokeLinecap="round"
            strokeLinejoin="round"
            d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
            />
        </svg>
    );
}

export const MagnifyingGlassCircleIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="w-6 h-6"
        >
            <path strokeLinecap="round"
            strokeLinejoin="round"
            d="M15.75 15.75l-2.489-2.489m0 0a3.375 3.375 0 10-4.773-4.773 3.375 3.375 0 004.774 4.774zM21 12a9 9 0 11-18 0 9 9 0 0118 0z"
            />
        </svg>
    );
}

export const UserCircleIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="w-6 h-6"
        >
            <path strokeLinecap="round"
                strokeLinejoin="round"
                d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z"
            />
        </svg>
    );
}

export const CrossCircleIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="w-6 h-6"
        >
            <path strokeLinecap="round"
                strokeLinejoin="round"
                d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
            />
        </svg>
    );
}

export const InfoTriangleIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="w-6 h-6"
        >
            <path strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"
            />
        </svg>
    );
}

export const MagnifyingGlassIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="w-6 h-6"
        >
            <path strokeLinecap="round"
                strokeLinejoin="round"
                d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
            />
        </svg>
    );
}

export const MegaphoneIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="w-6 h-6"
        >
            <path strokeLinecap="round"
                strokeLinejoin="round"
                d="M10.34 15.84c-.688-.06-1.386-.09-2.09-.09H7.5a4.5 4.5 0 110-9h.75c.704 0 1.402-.03 2.09-.09m0 9.18c.253.962.584 1.892.985 2.783.247.55.06 1.21-.463 1.511l-.657.38c-.551.318-1.26.117-1.527-.461a20.845 20.845 0 01-1.44-4.282m3.102.069a18.03 18.03 0 01-.59-4.59c0-1.586.205-3.124.59-4.59m0 9.18a23.848 23.848 0 018.835 2.535M10.34 6.66a23.847 23.847 0 008.835-2.535m0 0A23.74 23.74 0 0018.795 3m.38 1.125a23.91 23.91 0 011.014 5.395m-1.014 8.855c-.118.38-.245.754-.38 1.125m.38-1.125a23.91 23.91 0 001.014-5.395m0-3.46c.495.413.811 1.035.811 1.73 0 .695-.316 1.317-.811 1.73m0-3.46a24.347 24.347 0 010 3.46"
            />
        </svg>
    );
}

export const InfoShieldIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="w-6 h-6"
        >
            <path strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 9v3.75m0-10.036A11.959 11.959 0 013.598 6 11.99 11.99 0 003 9.75c0 5.592 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.31-.21-2.57-.598-3.75h-.152c-3.196 0-6.1-1.249-8.25-3.286zm0 13.036h.008v.008H12v-.008z"
            />
        </svg>
    );
}


export const ArrowPathIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            fill="currentColor"
            className="w-6 h-6"
        >
            <path fillRule="evenodd" d="M4.755 10.059a7.5 7.5 0 0112.548-3.364l1.903 1.903h-3.183a.75.75 0 100 1.5h4.992a.75.75 0 00.75-.75V4.356a.75.75 0 00-1.5 0v3.18l-1.9-1.9A9 9 0 003.306 9.67a.75.75 0 101.45.388zm15.408 3.352a.75.75 0 00-.919.53 7.5 7.5 0 01-12.548 3.364l-1.902-1.903h3.183a.75.75 0 000-1.5H2.984a.75.75 0 00-.75.75v4.992a.75.75 0 001.5 0v-3.18l1.9 1.9a9 9 0 0015.059-4.035.75.75 0 00-.53-.918z" clipRule="evenodd" />
        </svg>

    );
}

export const LanguageIcon = () => {
    return (
        <svg 
            xmlns="http://www.w3.org/2000/svg" 
            fill="none" 
            viewBox="0 0 24 24" 
            strokeWidth={1.5} 
            stroke="currentColor" 
            className="w-6 h-6"
        >
            <path strokeLinecap="round" strokeLinejoin="round" d="m10.5 21 5.25-11.25L21 21m-9-3h7.5M3 5.621a48.474 48.474 0 0 1 6-.371m0 0c1.12 0 2.233.038 3.334.114M9 5.25V3m3.334 2.364C11.176 10.658 7.69 15.08 3 17.502m9.334-12.138c.896.061 1.785.147 2.666.257m-4.589 8.495a18.023 18.023 0 0 1-3.827-5.802" />
      </svg>
    );    
}  

export const ShareIcon = () => {
    return (
        <svg 
            xmlns="http://www.w3.org/2000/svg" 
            fill="none" 
            viewBox="0 0 24 24" 
            strokeWidth={1.5} 
            stroke="currentColor" 
            className="w-6 h-6"
        >
            <path strokeLinecap="round" strokeLinejoin="round" d="M7.217 10.907a2.25 2.25 0 1 0 0 2.186m0-2.186c.18.324.283.696.283 1.093s-.103.77-.283 1.093m0-2.186 9.566-5.314m-9.566 7.5 9.566 5.314m0 0a2.25 2.25 0 1 0 3.935 2.186 2.25 2.25 0 0 0-3.935-2.186Zm0-12.814a2.25 2.25 0 1 0 3.933-2.185 2.25 2.25 0 0 0-3.933 2.185Z" />
        </svg>
    );
}


export const ManualIcon = () => {
    return (
        <svg 
            xmlns="http://www.w3.org/2000/svg" 
            fill="none" 
            viewBox="0 0 24 24" 
            strokeWidth={1.5} 
            stroke="currentColor" 
            className="w-6 h-6"
        >
            <path strokeLinecap="round" strokeLinejoin="round" d="M12 6.042A8.967 8.967 0 0 0 6 3.75c-1.052 0-2.062.18-3 .512v14.25A8.987 8.987 0 0 1 6 18c2.305 0 4.408.867 6 2.292m0-14.25a8.966 8.966 0 0 1 6-2.292c1.052 0 2.062.18 3 .512v14.25A8.987 8.987 0 0 0 18 18a8.967 8.967 0 0 0-6 2.292m0-14.25v14.25" />
        </svg>
    );
}

export const TermsAndConditionIcon = () => {
    return (
        <svg 
            xmlns="http://www.w3.org/2000/svg" 
            fill="none" 
            viewBox="0 0 24 24" 
            strokeWidth={1.5} 
            stroke="currentColor" 
            className="w-6 h-6"
        >
            <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12H12m-8.25 5.25h16.5" />
        </svg>
    );
}

export const PrivatePolicy = () => {
    return (
        <svg 
            xmlns="http://www.w3.org/2000/svg" 
            fill="none" 
            viewBox="0 0 24 24" 
            strokeWidth={1.5} 
            stroke="currentColor" 
            className="w-6 h-6"
        >
            
            <path strokeLinecap="round" strokeLinejoin="round" d="M12 3v17.25m0 0c-1.472 0-2.882.265-4.185.75M12 20.25c1.472 0 2.882.265 4.185.75M18.75 4.97A48.416 48.416 0 0 0 12 4.5c-2.291 0-4.545.16-6.75.47m13.5 0c1.01.143 2.01.317 3 .52m-3-.52 2.62 10.726c.122.499-.106 1.028-.589 1.202a5.988 5.988 0 0 1-2.031.352 5.988 5.988 0 0 1-2.031-.352c-.483-.174-.711-.703-.59-1.202L18.75 4.971Zm-16.5.52c.99-.203 1.99-.377 3-.52m0 0 2.62 10.726c.122.499-.106 1.028-.589 1.202a5.989 5.989 0 0 1-2.031.352 5.989 5.989 0 0 1-2.031-.352c-.483-.174-.711-.703-.59-1.202L5.25 4.971Z" />
        </svg>


    );
}

export const AboutUsIcon = () => {
    return (
        <svg 
            xmlns="http://www.w3.org/2000/svg" 
            fill="none" 
            viewBox="0 0 24 24" 
            strokeWidth={1.5} 
            stroke="currentColor" 
            className="w-6 h-6"
        >
            <path strokeLinecap="round" strokeLinejoin="round" d="M18 18.72a9.094 9.094 0 0 0 3.741-.479 3 3 0 0 0-4.682-2.72m.94 3.198.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0 1 12 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 0 1 6 18.719m12 0a5.971 5.971 0 0 0-.941-3.197m0 0A5.995 5.995 0 0 0 12 12.75a5.995 5.995 0 0 0-5.058 2.772m0 0a3 3 0 0 0-4.681 2.72 8.986 8.986 0 0 0 3.74.477m.94-3.197a5.971 5.971 0 0 0-.94 3.197M15 6.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm6 3a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Zm-13.5 0a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Z" />
        </svg>
    );
}

export const UpdateUsersIcon = () => {
    return (
        <svg 
            xmlns="http://www.w3.org/2000/svg" 
            fill="none" 
            viewBox="0 0 24 24" 
            strokeWidth={1.5} 
            stroke="currentColor" 
            className="w-6 h-6"
        >
            <path strokeLinecap="round" strokeLinejoin="round" d="M15 19.128a9.38 9.38 0 0 0 2.625.372 9.337 9.337 0 0 0 4.121-.952 4.125 4.125 0 0 0-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 0 1 8.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0 1 11.964-3.07M12 6.375a3.375 3.375 0 1 1-6.75 0 3.375 3.375 0 0 1 6.75 0Zm8.25 2.25a2.625 2.625 0 1 1-5.25 0 2.625 2.625 0 0 1 5.25 0Z" />
        </svg>
    );
}

export const FeedbackIcon = () => {
    return (
        <svg 
            xmlns="http://www.w3.org/2000/svg" 
            fill="none" 
            viewBox="0 0 24 24" 
            strokeWidth={1.5} 
            stroke="currentColor" 
            className="w-6 h-6"
        >
            <path strokeLinecap="round" strokeLinejoin="round" d="M7.5 8.25h9m-9 3H12m-9.75 1.51c0 1.6 1.123 2.994 2.707 3.227 1.129.166 2.27.293 3.423.379.35.026.67.21.865.501L12 21l2.755-4.133a1.14 1.14 0 0 1 .865-.501 48.172 48.172 0 0 0 3.423-.379c1.584-.233 2.707-1.626 2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0 0 12 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018Z" />
        </svg>
    );
}

export const DisclaimerIcon = () => {
    return (
        <svg 
            xmlns="http://www.w3.org/2000/svg" 
            fill="none" 
            viewBox="0 0 24 24" 
            strokeWidth={1.5} 
            stroke="currentColor" 
            className="w-6 h-6"
        >
            <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75 11.25 15 15 9.75m-3-7.036A11.959 11.959 0 0 1 3.598 6 11.99 11.99 0 0 0 3 9.749c0 5.592 3.824 10.29 9 11.623 5.176-1.332 9-6.03 9-11.622 0-1.31-.21-2.571-.598-3.751h-.152c-3.196 0-6.1-1.248-8.25-3.285Z" />
        </svg>
    );
}

export const HamburgerMenuIcon = () => {
    return (
        <svg 
            xmlns="http://www.w3.org/2000/svg" 
            fill="none" 
            viewBox="0 0 24 24" 
            strokeWidth={1.5} 
            stroke="#000000" 
            className="w-6 h-6"
        >
            <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 5.25h16.5m-16.5 4.5h16.5m-16.5 4.5h16.5m-16.5 4.5h16.5" />
        </svg>
    );
}

export const DairyFarmingIcon = () => {
    return (
        <svg
            version="1.0"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 1280.000000 692.000000" 
            strokeWidth="1.5"
            stroke="currentColor"
            className="w-6 h-8"
            preserveAspectRatio="xMidYMid meet"
        >
            <metadata>
                Created by potrace 1.15, written by Peter Selinger 2001-2017
            </metadata>
            <g 
                transform="translate(0.000000,792.000000)
                scale(0.100000,-0.100000)"
                fill="#404040"
                stroke="none"
            >
                <path d="M10804 7871 c-37 -36 -62 -51 -90 -56 -21 -3 -55 -12 -74 -19 -32
                -11 -35 -15 -36 -55 -1 -39 -6 -48 -78 -120 -45 -45 -76 -85 -74 -92 3 -8 1
                -22 -4 -32 -10 -20 -22 -19 -213 20 -149 29 -243 38 -326 29 l-64 -7 -44 -66
                c-42 -62 -53 -101 -41 -143 4 -11 -13 -21 -60 -37 -123 -41 -211 -55 -388 -60
                -185 -6 -292 2 -552 43 -515 81 -603 88 -878 69 -359 -25 -708 -35 -1582 -45
                -831 -10 -1094 -17 -1355 -40 -113 -10 -127 -9 -231 14 -248 57 -517 91 -1019
                131 -607 49 -832 79 -1120 151 -77 19 -177 48 -222 63 -72 25 -88 27 -135 19
                -29 -5 -233 -11 -453 -12 -571 -4 -639 -18 -917 -182 -110 -65 -150 -96 -233
                -179 -209 -209 -307 -464 -355 -925 -6 -58 -18 -152 -26 -210 -19 -125 -20
                -379 -4 -505 7 -49 13 -209 15 -355 2 -162 11 -358 24 -505 33 -386 26 -672
                -24 -858 -8 -32 -33 -106 -54 -165 -113 -305 -191 -768 -191 -1126 0 -310 57
                -611 157 -840 30 -66 36 -92 37 -155 1 -105 -14 -310 -26 -346 -5 -16 -22 -97
                -38 -180 -16 -82 -32 -160 -36 -172 -6 -21 -3 -23 29 -23 26 0 41 -7 56 -25
                24 -32 29 -52 40 -186 5 -59 14 -116 20 -128 14 -26 72 -56 154 -81 98 -29
                156 -39 334 -55 93 -9 195 -20 227 -26 74 -13 92 0 82 56 -6 28 -37 68 -150
                188 -78 84 -169 180 -201 215 l-58 63 7 87 c67 850 182 1359 409 1807 67 131
                77 146 171 240 95 95 171 148 245 170 l23 7 -4 -103 c-4 -98 -8 -116 -78 -319
                -70 -204 -74 -220 -78 -322 l-5 -108 62 -125 c115 -235 184 -434 326 -935 99
                -351 150 -511 211 -663 33 -82 45 -127 50 -188 l7 -81 31 5 c64 9 91 -20 130
                -140 39 -119 183 -190 430 -213 184 -18 299 -30 322 -36 38 -10 42 50 4 64
                -16 7 -69 69 -265 311 -129 160 -180 275 -190 433 -4 59 -13 85 -64 187 -245
                487 -319 885 -226 1206 27 92 46 135 103 230 30 50 37 70 37 117 0 99 43 134
                213 178 59 14 118 32 131 39 31 17 37 7 46 -77 5 -49 13 -71 30 -88 71 -71
                146 28 130 172 -9 87 3 94 145 82 137 -11 162 -26 172 -95 11 -72 48 -148 73
                -148 31 0 58 66 65 161 5 62 13 92 33 127 27 46 41 54 151 88 28 8 75 47 172
                141 188 182 239 200 346 122 52 -39 122 -57 380 -99 429 -69 1103 -100 1156
                -52 38 34 101 47 236 48 72 0 156 5 186 11 30 5 132 11 225 13 94 2 240 9 325
                15 221 16 501 14 613 -5 128 -20 156 -30 193 -66 19 -18 39 -29 47 -26 24 9
                28 -64 21 -368 -6 -216 -4 -297 6 -350 17 -89 18 -321 1 -445 -7 -52 -37 -220
                -67 -373 -72 -376 -77 -418 -77 -597 1 -116 6 -178 21 -244 14 -64 17 -97 11
                -115 -40 -103 145 -319 328 -382 89 -30 186 -41 311 -33 l114 7 -7 34 c-9 48
                -36 109 -113 253 -83 155 -129 258 -142 315 -13 59 4 568 25 713 19 137 54
                262 101 362 40 85 94 170 109 170 6 0 23 -10 38 -23 25 -19 34 -43 67 -172 42
                -164 98 -433 159 -765 31 -168 46 -228 70 -275 24 -47 34 -86 46 -180 17 -131
                35 -193 65 -220 17 -16 43 -16 325 -10 231 6 308 11 311 20 9 22 -16 138 -42
                194 -28 62 -68 119 -180 257 -121 148 -149 191 -134 209 7 8 8 15 3 15 -21 0
                -92 325 -118 532 -19 162 -19 469 0 607 15 101 14 105 -11 214 -26 116 -25
                150 6 190 17 21 32 26 97 32 296 27 542 127 726 295 48 43 188 220 275 347 46
                66 97 165 186 359 51 110 111 227 133 260 65 96 145 246 246 459 143 303 229
                453 340 590 33 41 70 101 88 145 86 207 195 380 298 473 58 51 59 51 144 37
                32 -5 163 -14 290 -20 326 -16 484 -42 601 -102 38 -19 48 -20 270 -9 251 11
                313 23 408 73 51 27 59 36 75 82 12 34 18 79 17 136 0 76 -3 92 -33 150 -35
                66 -76 111 -343 365 -120 113 -165 177 -193 268 -12 41 -12 73 -2 209 l11 161
                101 75 c199 149 255 222 255 331 0 58 -1 60 -46 100 -73 64 -151 86 -309 86
                -110 0 -140 -4 -197 -23 -39 -14 -76 -21 -88 -17 -20 6 -76 61 -92 90 -13 22
                29 61 85 79 77 24 97 41 97 80 0 41 8 39 -281 61 -228 17 -265 23 -390 65
                -113 37 -210 50 -384 50 l-130 0 -51 -49z"/>  
            </g>
        </svg>
    );

}


export const FaqIcon = () => {
    return (
        <svg 
            xmlns="http://www.w3.org/2000/svg" 
            fill="none" viewBox="0 0 24 24" 
            strokeWidth={1.5} 
            stroke="currentColor" 
            className="w-6 h-6"
        >
            <path strokeLinecap="round" strokeLinejoin="round" d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 5.25h.008v.008H12v-.008Z" />
        </svg>
    );
}





























