import React, { useState, useEffect, useMemo } from 'react';
import { QUERY_FILTER_LISTED_ITEMS } from '../constants';
import { getResource } from '../services/farmApi';
import { ToastContainer } from 'react-toastify';
import { useTranslation } from "react-i18next";
import 'react-toastify/dist/ReactToastify.css';
import { useUser, useEnv } from '../contexts';
import { useHandleSubmit } from '../hooks';
import { Link } from 'react-router-dom';

import { 
    getCategoryMappingRequests
} from '../components/Mappings';

const SupplyForm = () => {

    const { t } = useTranslation(); // for language translation
    // Use the mapping functions with `t` for translation
    const categoryMappingRequests = getCategoryMappingRequests(t);

    const { user } = useUser(); // Get currently logged-in user
    const { envState, setEnvState } = useEnv(); // Get Environment context
    const [createNotice, setCreateNotice] = useState({ showNotice: false, succ: false, msg: "" });
    const [reportData, setReportData] = useState([]);
    const { handleGetResource } = useHandleSubmit(); // Shared submit handler

    const [searchTerm, setSearchTerm] = useState('');
    const [selectedCategory, setSelectedCategory] = useState(null);

    const [loading, setLoading] = useState(false); // Loading state
    const [errorMessage, setErrorMessage] = useState(null); // Error message state
    
    // Fetch data and update reportData
    useEffect(() => {
        // Clear the previously set 'allrequests' in localStorage
        // localStorage.removeItem('allrequests'); 
        localStorage.removeItem(`requests_${user.id}`);
        
        getListProduct();
    }, [envState.online]);

    const getListProduct = async () => {
        setLoading(true);  // Start loading

        const reportQuery = "/?filter=" + QUERY_FILTER_LISTED_ITEMS;

        try {
            handleGetResource(
                null,
                getResource,
                envState.online,
                process.env.REACT_APP_MARKET_PLACE + reportQuery,
                setCreateNotice,
                null,
                setReportData, // This will update reportData
                null,
                false
            );
            setErrorMessage(null); // Clear any previous errors
        } catch (error) {
            // console.error('Error fetching report data:', error);
            setErrorMessage(t('market_place_page.failed_fetch_data'));
        } finally {
            setLoading(false); // Stop loading
        }
    };

    // Update localStorage whenever reportData changes
    useEffect(() => {
    }, [reportData]);  // This effect runs whenever reportData changes

    const products = Array.isArray(reportData.returned_records) ? reportData.returned_records : [];
    const filteredProducts = useMemo(() => {
        const searchLower = searchTerm.toLowerCase();
        return products.filter((product) => {
            return (
                product.category?.toLowerCase().includes(searchLower) ||
                product.region?.toLowerCase().includes(searchLower) 
            ) && (!selectedCategory || product.category === selectedCategory);
        });
    }, [products, searchTerm, selectedCategory]);
    
    // Memoize filtered products and categories to avoid recalculating them on every render unless necessary.
    const categories = useMemo(() => {
        return [...new Set(products.map((product) => product.category))];
    }, [products]);

    // Reusable function for handling JSX handling of images and videos or other media types.
    const MediaDisplay = ({ media }) => {
        if (media.startsWith('data:image')) {
            return <img src={media} className="card-img-top w-full h-auto" alt="Media" style={{ objectFit: 'contain' }} />;
        } else if (media.startsWith('data:video')) {
            return <video src={media} className="card-video-top w-full h-auto" controls style={{ objectFit: 'contain' }} />;
        }
        return null;
    };

    // Convert product object to an array then pass it without image_base
    const handleViewDetails = (product) => {
        // Ensure existingProducts is always an array
        // const existingProducts = JSON.parse(localStorage.getItem('requests')) || [];
        const existingProducts = JSON.parse(localStorage.getItem(`requests_${user.id}`)) || [];

        // Remove image_base from the new product before adding it
        const { image_base, ...productWithoutImageBase } = product;

        // Make sure existingProducts is an array before adding new products
        if (Array.isArray(existingProducts)) {
            // Add the modified product to the array
            // localStorage.setItem('requests', JSON.stringify([...existingProducts, productWithoutImageBase]));
            localStorage.setItem(`requests_${user.id}`, JSON.stringify([...existingProducts, productWithoutImageBase]));
            
        } else {
            // If somehow it's not an array, start fresh with the new product in an array
            // localStorage.setItem('requests', JSON.stringify([productWithoutImageBase]));
            localStorage.setItem(`requests_${user.id}`, JSON.stringify([productWithoutImageBase]));
        }
    };

    return (
        <div className='container mt-2'>
            {/* <div className="mb-2"></div> */}
            <div>{t('market_place_page.needed_face_title')}</div>
            {loading && <div>{t('validation_messages.loading_requests')}</div>}
            {errorMessage && <div className="text-red-500">{errorMessage}</div>}
            <div className="flex flex-wrap justify-start mb-2">
                {/* 'All' Button */}
                <button
                    type="button"
                    className={`min-w-[60px] h-[40px] mb-2 mr-2 inline-flex justify-center px-2 py-2 rounded ${selectedCategory === null ? 'bg-blue-500 text-white' : 'bg-gray-300 text-black'}`}
                    onClick={() => setSelectedCategory(null)}
                >
                    {t('market_place_page.all_category')}
                </button>
                {/* Category Buttons */}
                {categories.map((category) => (
                    <button
                        key={category}
                        type="button"
                        className={`min-w-[40px] h-[40px] mb-2 mr-2 inline-flex justify-center px-2 py-2 rounded ${selectedCategory === category ? 'bg-blue-500 text-white' : 'bg-gray-300 text-black'}`}
                        onClick={() => setSelectedCategory(category)}
                    >
                        {categoryMappingRequests[category] || category}
                    </button>
                ))}
            </div>

            {/* Search Field */}
            <input
                type='text'
                placeholder={t('market_place_page.needed_face_search_tip')}
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className='form-control mb-3 w-full'
            />

            <div className='row'>
                {filteredProducts.length === 0 && (
                    <div className="w-full text-center">
                       {t('market_place_page.no_requests_listed')}
                    </div>
                )}
                
                {filteredProducts.map((product) => (
                    <div key={product.list_id} className='col-md-3 mb-4'>
                        <div className='card' style={{ width: '100%' }}>
                            {/* Image for the sale item */}
                            <MediaDisplay media={product.image_base} altText={product.category || 'Media'} />
                            <div className='card-body'>
                                {/* <Link to={`request-detail/${product.list_id}`}> */}
                                <Link to={`/fe/marketplace/request-detail/${product.list_id}`}>
                                    <h5 className='card-title' style={{ fontSize: '1.25rem', fontWeight: 'bold' }}>
                                    {t('market_place_page.manage_face_cattle_side_category')}: {categoryMappingRequests[product.category] || 'Unknown Category'}
                                    </h5>
                                    <p className='card-title' style={{ fontSize: '1rem', color: 'gray' }}>
                                        <span style={{ fontWeight: 'bold' }}> {t('market_place_page.manage_face_item_side_item_description')}:</span> { product.description }
                                    </p>
                                    <p className='card-text' style={{ fontSize: '1rem', color: 'gray' }}>
                                        <span style={{ fontWeight: 'bold' }}>{t('market_place_page.manage_face_item_side_item_location')}:</span> { product.region }
                                    </p>
                                    <p className='card-text' style={{ fontSize: '1rem', color: 'gray' }}>
                                        <span style={{ fontWeight: 'bold' }}>{t('market_place_page.needed_face_first_advertised')}:</span> 
                                            {/* { product.list_date } */}
                                            {new Date(product.list_date).toISOString().slice(0, 10)}
                                    </p>
                                    <p className='card-text' style={{ fontSize: '1rem', color: 'gray' }}>
                                    <span style={{ fontWeight: 'bold' }}>{t('market_place_page.needed_face_request_number')}:</span> { product.list_id }
                                    </p>
                                    <button className='btn btn-primary'
                                         onClick={() => handleViewDetails(product)} 
                                    >
                                        {t('market_place_page.advertise_face_view_details')}
                                    </button>
                                </Link>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            <ToastContainer />
        </div>
    );

    
};

export { SupplyForm };
