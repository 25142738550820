// This is a generic form textarea input
// The idea is to use this wherever a text input is required

// export const TextareaInput = (props) => {
//     return (
//         <textarea
//             name={props.inputName}
//             autoFocus={props.focus}
//             id={props.inputId}
//             placeholder={props.hint}
//             rows={props.inputRows}
//             autoComplete="off"
//             required={props.isRequired ?? true}
//             disabled={props.isDisabled ?? false}
//             pattern={props.inputPattern ?? null}
//             className={props.inputStyle}
//             value={props.inputValue}
//             onChange={(e) => { props.handleChange(props.inputName, e.target.value) }}
//         />
//     );
// }


export const TextareaInput = (props) => {
    return (
        <textarea
            name={props.inputName}
            autoFocus={props.focus}
            id={props.inputId}
            placeholder={props.hint ?? ""}
            autoComplete="off"
            required={props.isRequired ?? false}
            disabled={props.isDisabled ?? false}
            rows={props.inputRows ?? 3}
            className={props.inputStyle}
            value={props.inputValue}
            maxLength={props.maxLength}
            onChange={(e) => { props.handleChange(props.inputName, e.target.value) }}
        />
    );
};
