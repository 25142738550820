// This is a generic form number input
// The idea is to use this wherever a number input is required

// export const NumberInput = (props) => {
//     return (
//         <input
//             autoFocus={props.focus}
//             type="number"
//             name={props.inputName}
//             id={props.inputId}
//             placeholder={props.hint ?? ""}
//             autoComplete="off"
//             required={props.isRequired ?? true}
//             disabled={props.isDisabled ?? false}
//             pattern={props.inputPattern ?? null}
//             step={props.inputStep ?? null}
//             min={props.min ?? 0}
//             className={props.inputStyle}
//             value={props.inputValue}
//             onChange={e => {
//                 props.handleChange(state => ({
//                     ...state,
//                     [props.inputName]: e.target.value === "" ? "" : Number(e.target.value)
//                 })
//             )}}
//       />
//     );
// }

// Modified the value to solve a warning issue on console
// "Warning: A component is changing an uncontrolled input to be controlled,"
// the value prop being set to an empty string ("") causes this warning
// I have made it to be undefined if the value is not provided

export const NumberInput = (props) => {
    return (
        <input
            autoFocus={props.focus}
            type="number"
            name={props.inputName}
            id={props.inputId}
            placeholder={props.hint ?? ""}
            autoComplete="off"
            required={props.isRequired ?? true}
            disabled={props.isDisabled ?? false}
            pattern={props.inputPattern ?? null}
            step={props.inputStep ?? null}
            min={props.min ?? 0}
            className={props.inputStyle}
            value={props.inputValue !== undefined ? props.inputValue : ""}
            onChange={(e) => {
                const inputValue = e.target.value === "" ? "" : Number(e.target.value);
                props.handleChange((state) => ({
                    ...state,
                    [props.inputName]: inputValue,
                }));
            }}
        />
    );
};
