// This is a generic form select input
// The idea is to use this wherever a select input is required

// export const SelectInput = (props) => {
//     const options = props.choices.map(choice => (
//       <option key={choice.value} value={choice.key}>
//         {choice.label}
//       </option>
//     ));

//     return (
//         <select
//           autoFocus={props.focus}
//           name={props.inputName}
//           id={props.inputId}
//           autoComplete="off"
//           className={props.inputStyle}
//           value={props.inputValue}
//           required={props.isRequired ?? true}
//           disabled={props.isDisabled ?? false}
//           onChange={(e) => {
//             props.handleChange(props.inputName, e.target.value, props.inputId);
//           }}
//         >
//           {options}
//         </select>
//       );
//     };


// I have corrected by setting key attribute to choice.key, instead of choice.value
// ensuring that each option has a unique identifier within the mapping. Otherwise
// it was throwing a warning on console "Each child in a list should have a unique 'key' prop"

export const SelectInput = (props) => {
  const options = props.choices.map(choice => (
    <option key={choice.key} value={choice.key}>
      {choice.label}
    </option>
  ));

  return (
    <select
      autoFocus={props.focus}
      name={props.inputName}
      id={props.inputId}
      autoComplete="off"
      className={props.inputStyle}
      value={props.inputValue}
      required={props.isRequired ?? true}
      disabled={props.isDisabled ?? false}
      onChange={(e) => {
        props.handleChange(props.inputName, e.target.value, props.inputId);
      }}
    >
      {options}
    </select>
  );
};




    