import React, { useState, useEffect } from "react";
import { useEnv } from "../contexts";
import logo from "../icons/gdfproject.png";
import { useTranslation } from 'react-i18next';

import { sendMail } from '../services/farmApi';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Landing Auth component - Login Dialog
const Feedback = () => {

  const { setEnvState } = useEnv(); // Get Environment context Setter

  // Notifiy the App of this location and Header Nav requirements
  useEffect(() => {
      setEnvState(state => ({...state, whereAbout: t('navigation_element.nav_item_title_feedback'), backPath: "", homePath: "/fe"}));
      return () => {
          setEnvState(state => ({...state, whereAbout: "", backPath: "", homePath: ""}));
      }
     // eslint-disable-next-line
   }, []);

  // --------------------- start language matter --------------------------
  // for language
  const { t } = useTranslation();
  // --------------------- end language matter --------------------------


  // --------------------- start processing feedback form ----------------
  const [feedback, setFeedback] = useState('');
  const MAX_CHARACTERS = 256;

  const handleFeedbackChange = (e) => {
    const inputText = e.target.value;
    const characterCount = inputText.length - 1;
    if ( characterCount <= MAX_CHARACTERS) {
      setFeedback(inputText);
    }
  };
  
  const isCharacterLimitExceeded = feedback.length > MAX_CHARACTERS;

  // to toasting
  const notifySuccess = (toastMessage) => {
    toast.success(toastMessage, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };

  const notifyError = (message) => {
    toast.error(message, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };

  const notifyMessage = (message) => {
    toast(message, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };

  const handleSubmit = () => {
    // Use an API or service to send the feedback to WhatsApp
    sendFeedbackToGDFProject(feedback);
    // Optionally, you can clear the feedback input
    setFeedback('');
  };

  const sendFeedbackToGDFProject = async (feedbackMessage) => {
    // Let us send feedback to GDF email address habari@gdf.co.tz
    
    // Let us obtain email address from the local storage
    const userData = JSON.parse(localStorage.getItem(process.env.REACT_APP_AUTH_STORAGE_KEY));
    const userEmail = userData.email;

    // email subject: Feedback
    const emailSubject      = "Feedback";
    const emailContent      = feedbackMessage;
    const emailToAddress    = "habari@gdf.co.tz";
    const emailFromAddress  = userEmail;
   

    // we can't sent if email content is empty
    if (emailContent === "") {
        // inform user - very unlikely as the send button will be disabled.
        notifyMessage(t('feedback_form.feedback_email_content_is_empty'));
    }else{
        const reqResp = await sendMail(
            process.env.REACT_APP_EMAIL_SENDING,
            { emailToAddress, emailFromAddress, emailSubject, emailContent },
            t
        );
        
        if (reqResp.success) {
            notifySuccess(t('feedback_form.feedback_feedback_submitted'));
        } else {
            // Show error on register dialog
            notifyError(t('feedback_form.feedback_email_sending_failed'));
            
        }
    }

    // Use an API or service to send the feedback to WhatsApp
    // Here, you can integrate with a service like Twilio or use the WhatsApp Business API
    // Example: Twilio WhatsApp API
    // const phoneNumber = 'whatsapp:+2559993'; // Replace with your WhatsApp number
    // const accountSid = 'yourTwilioAccountSid';
    // const authToken = 'yourTwilioAuthToken';
    // const twilioNumber = 'yourTwilioPhoneNumber';

    // const url = `https://api.twilio.com/2010-04-01/Accounts/${accountSid}/Messages.json`;

    // const body = new URLSearchParams({
    //   To: phoneNumber,
    //   From: twilioNumber,
    //   Body: message,
    // });

    // fetch(url, {
    //   method: 'POST',
    //   headers: {
    //     'Content-Type': 'application/x-www-form-urlencoded',
    //     Authorization: `Basic ${btoa(`${accountSid}:${authToken}`)}`,
    //   },
    //   body: body.toString(),
    // })
    //   .then((response) => response.json())
    //   .then((data) => {
    //     console.log('Message sent successfully:', data);
    //     // Optionally handle success, e.g., show a success message to the user
    //   })
    //   .catch((error) => {
    //     console.error('Error sending message:', error);
    //     // Optionally handle error, e.g., show an error message to the user
    //   });
  };
  // --------------------- end processing feedback form ----------------



  return (
      <div className="flex min-h-full items-center mt-14 md:mt-4 justify-center py-12 px-4 sm:px-6 lg:px-8">
        <div className="w-full max-w-md space-y-8">
            {/* Header */}
            <div>
                {/* logo */}
                <div className="flex justify-center">
                <img
                    src={logo}
                    className="w-[80px] h-auto"
                    alt="GDF 512x512 sized logo"
                />
                </div>
            </div>

            {/* Start Content */}
            <div className="text-gray-600/90 text-lg col-span-2 flex flex-col justify-center items-center px-6 bg-gray-50">
                <div>
                    <h2 className="text-xl font-bold mb-2">{t('feedback_form.feedback_title')}</h2>
                    <p>{t('feedback_form.feedback_title_info')}</p>
                </div>

                <div>
                    <h3 className="text-xl font-semibold mt-6">{t('feedback_form.feedback_overview')}</h3>
                    <p>{t('feedback_form.feedback_overview_info')}</p>
                </div>
                <div>
                    <h3 className="text-xl font-semibold mt-6">{t('feedback_form.feedback_form_textarea_title')}</h3>
                    <textarea
                        rows="4"
                        cols="34"
                        value={feedback}
                        onChange={handleFeedbackChange}
                        placeholder={t('feedback_form.feedback_form_textarea_placeholder')}
                    />
                </div>
                    <div className="flex justify-between items-center mt-2">
                        <span
                            className={`text-${isCharacterLimitExceeded ? 'red-500 text-sm' : 'gray-500 text-sm'}`}
                        >
                            {isCharacterLimitExceeded
                                ? `${t('feedback_form.feedback_characters_exceeded')} ${feedback.length}/${MAX_CHARACTERS}`
                                : `${t('feedback_form.feedback_characters_remaining')} ${feedback.length}/${MAX_CHARACTERS}`}
                        </span>
                        <button
                            type="submit"
                            className={`group relative flex-shrink-0 ml-4 px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-slate-500 hover:bg-slate-600 focus:outline-none focus:ring-2 focus:ring-slate-500 focus:ring-offset-2 uppercase ${
                                isCharacterLimitExceeded && 'cursor-not-allowed opacity-50'
                            }`}
                            onClick={handleSubmit}
                            disabled={isCharacterLimitExceeded}
                        >
                            {t('feedback_form.feedback_form_textarea_submit_button')}
                        </button>
                    </div>
                </div>
                {/* End Content */}
                <ToastContainer
                    position="top-center"
                    autoClose={5000}
                    hideProgressBar={false}
                    closeOnClick
                    pauseOnHover
                    draggable
                    pauseOnFocusLoss
                    theme="light"
                />
                <div className="flex flex-col justify-center items-center mt-14 md:mt-8 text-sm text-gray-400/90 font-semibold border-t-[3px] border-gray-200 pt-3">
                        <span>{t('home_page.courtesy')}</span>
                        <span>{t('home_page.web')}</span>
                        <span>{t('home_page.email')}</span>
                        <span>{t('home_page.copyright')}</span>
                </div>
            </div>
        </div>

  );
  
};


export {Feedback};
