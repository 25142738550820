// This is a generic form time input
// The idea is to use this wherever a time input is required

export const TimeInput = (props) => {
    return (
        <input
            autoFocus={props.focus}
            type="time"
            name={props.inputName}
            id={props.inputId}
            autoComplete="off"
            required={props.isRequired ?? true}
            disabled={props.isDisabled ?? false}
            pattern={props.inputPattern ?? null}
            className={props.inputStyle}
            value={props.inputValue}
            onChange={e => { props.handleChange(props.inputName, e.target.value) }}
      />
    );
}