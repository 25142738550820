import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import './i18n'; 
import { A2HSProvider } from './components/a2hsContext';
import { ConsoleLogEvent } from './utils';
import { NotificationProvider } from "./components/NotificationContext";

// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//   <React.StrictMode>
//     <A2HSProvider>
//       <App />
//     </A2HSProvider>
//   </React.StrictMode>
// );

// ----------- start of FCM part ------------------------------------------

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <A2HSProvider>
      <NotificationProvider>
        <App />
      </NotificationProvider>
    </A2HSProvider>
  </React.StrictMode>
);

// ----------- end of FCM part ------------------------------------------

let deferredPrompt;

window.addEventListener('beforeinstallprompt', (event) => {
  // Prevent the default mini-infobar from appearing
  event.preventDefault();

  // Store the event for triggering later
  deferredPrompt = event;

  // Example: Show a custom install button
  const installButton = document.getElementById('install-button');
  if (installButton) {
    installButton.style.display = 'block';

    installButton.addEventListener('click', async () => {
      // Trigger the prompt
      deferredPrompt.prompt();

      // Check the user's choice
      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          ConsoleLogEvent('log', 'User accepted the install prompt');
        } else {
          ConsoleLogEvent('log', 'User dismissed the install prompt');
        }
        // Clear the deferred prompt
        deferredPrompt = null;
        
        // Optionally, remove the install button
        installButton.remove();
      });
    });
  }
});


const serviceWorkerConfig = {
  onUpdate: (registration) => {
    ConsoleLogEvent('log', 'A new service worker is available:', registration);
    // Optional: Prompt user to refresh the app
  },
  onSuccess: (registration) => {
    ConsoleLogEvent('log', 'Service worker registered successfully:', registration);
  },
};

// serviceWorkerRegistration.register();
serviceWorkerRegistration.register(serviceWorkerConfig)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
