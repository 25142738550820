import { useLayoutEffect } from "react";
import { useTranslation } from "react-i18next";

import { useUser, useEnv } from "../contexts";

import { MenuItem } from "./MenuItem";
import {
    CleanEnvIcon,
    WeightKgIcon,
    UsersPeopleIcon,
    StopIcon,
    ShieldCheckIcon,
    TransactionsIcon,
    PasswordRestIcon,
} from "../icons";
import { DummyMenuItem } from "./DummyMenuItem";


const ManageMenu = () => {
    const { user } = useUser();  // Get currently loggedIn user
    const { setEnvState } = useEnv(); // Get Environment context Setter

    // Notifiy the App of this location and Header Nav requirements
    useLayoutEffect(() => {
        setEnvState(state => ({...state, whereAbout: t('navigation_element.nav_item_title_manage'), backPath: "", homePath: "/fe"}));
       return () => {
          setEnvState(state => ({...state, whereAbout: "", backPath: "", homePath: ""}));
       }
       // eslint-disable-next-line
     }, []);

    // for language translation
    const { t } = useTranslation();
         
    return(
        // <div className="mt-[140px] mb-[100px] p-2 md:p-0 ">
        <div className="mt-[100px] mb-0 p-2 md:p-0">
            <div className="grid grid-cols-6">
                {/** Left spacer */}
                <div className="col-span-1"></div>
                <div className="col-span-4 flex flex-col gap-y-4">
                    {/** Menu Item row */}
                    <div className="flex justify-center items-center gap-x-3">
                        <MenuItem urlSegment="/fe/dips" itemTitle={t('navigation_element.nav_item_title_dip')}>
                            <ShieldCheckIcon />
                        </MenuItem>
                        <MenuItem urlSegment="/fe/washes" itemTitle={t('navigation_element.nav_item_title_wash')}>
                            <CleanEnvIcon />
                        </MenuItem>
                    </div>
                    <div className="flex justify-center items-center gap-x-3">
                        <MenuItem urlSegment="/fe/weighings" itemTitle={t('navigation_element.nav_item_title_weigh')}>
                            <WeightKgIcon />
                        </MenuItem>
                        { user.role === 'spr'?
                            <MenuItem urlSegment="/fe/users" itemTitle={t('navigation_element.nav_item_title_users')}>
                                <UsersPeopleIcon />
                            </MenuItem> :
                            <DummyMenuItem itemTitle={t('navigation_element.nav_item_title_tbc')}>
                                <StopIcon />
                            </DummyMenuItem>
                        }
                    </div>
                    <div className="flex justify-center items-center gap-x-3">
                        { user.role === 'spr'?
                            <MenuItem urlSegment="/fe/transactions" itemTitle={t('navigation_element.nav_item_title_transactions')}>
                                <TransactionsIcon />
                            </MenuItem> :
                            <DummyMenuItem itemTitle={t('navigation_element.nav_item_title_tbc')}>
                                <StopIcon />
                            </DummyMenuItem>
                        }
                        {/* <MenuItem urlSegment="/fe/passwordreset" itemTitle='Password Rest'>  */}
                         <MenuItem urlSegment="/fe/manageprofile" itemTitle={t('navigation_element.nav_item_title_manage_profile')}> 
                            <PasswordRestIcon />
                        </MenuItem>                       
                        {/* <DummyMenuItem itemTitle={t('navigation_element.nav_item_title_tbc')}>
                            <StopIcon />
                        </DummyMenuItem> */}
                    </div>
                </div>
                {/** Left spacer */}
                <div className="col-span-1"></div>
            </div>
        </div>
    );
}

export { ManageMenu };