import React, { useState }  from 'react';
import { useTranslation } from "react-i18next";
import PropagateLoader from "react-spinners/PropagateLoader";

// some good visuals for react-spinners
// https://www.npmjs.com/package/react-spinners
// https://www.davidhu.io/react-spinners/


export function Loading() {
  // for language translation
  const { t } = useTranslation();
  let [color, setColor] = useState("#ff0000");

  return (
    <div className="loader-container">
      <div className="loader">
        {t('user_handle_notice.page_loading')}

        <PropagateLoader
          color={color}
        />

      </div>
    </div>
  );
};