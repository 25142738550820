
//-------------------------------------------
// userLogout function
import { logout } from "../services/auth";
import { useTranslation } from 'react-i18next';

// This hook attempts to DRY logout, so that it's conveniently available from
// multiple components. Note: no state is passed to this hook.

const useLogout = () => {
  const { t } = useTranslation();

  const logoutUser = async (setUser) => {
    const logoutResp = await logout();
    
    // Delete local auth data, regardless of API response.
    // remove entry from local storage
    localStorage.removeItem(process.env.REACT_APP_AUTH_STORAGE_KEY);
    setUser(null);

    if (logoutResp.success) {
      return {
        success: true,
        message: t('user_handle_notice.successful_user_logged_out')
      };
    }

    return {
      success: false,
      message: t('user_handle_notice.failed_user_log_out')
    };
  };

  return { logoutUser };
};

export { useLogout };

