import { createContext, useContext, useState, useEffect } from "react";

// Environment context
const envContext = createContext(undefined);

// Environment context provider
const EnvProvider = ({ children }) => {
  const [envState, setEnvState] = useState({
    online: navigator.onLine,
    darkMode:
      window.matchMedia &&
      window.matchMedia("(prefers-color-scheme: dark)").matches,
    whereAbout: "",
    homePath: "",
    backPath: "",
  });

  // Handle online event
  const handleOnline = () => {
    setEnvState((state) => ({ ...state, online: true }));
  };
  // Handle online event
  const handleOffline = () => {
    setEnvState((state) => ({ ...state, online: false }));
  };
  // Handle darkmode status
  const handleDarkMode = (e) => {
    const isDarkMode = e.matches ? true : false;
    setEnvState((state) => ({ ...state, darkMode: isDarkMode }));
  };

  useEffect(() => {
    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);
    window
      .matchMedia("(prefers-color-scheme: dark)")
      .addEventListener("change", handleDarkMode);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
      window
        .matchMedia("(prefers-color-scheme: dark)")
        .removeEventListener("change", handleDarkMode);
    };
  }, []);

  return (
    <envContext.Provider value={{ envState, setEnvState }}>
      {children}
    </envContext.Provider>
  );
};

// Use env hook
const useEnv = () => useContext(envContext);

export { useEnv, EnvProvider };
