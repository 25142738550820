import { useState } from "react";
import { Landing, Register, ForgotPass, TermsAndConditions } from "./";


// Auth Dialog - defaulting to landing
const Login = () => {
  const [activeDialog, setActiveDialog] = useState("landing");

  return (
    <>
      {/* Show active Dialog */}
      {activeDialog === "landing" ? (
        <Landing onChangeDialog={setActiveDialog} />
      ) : activeDialog === "register" ? (
        <Register onChangeDialog={setActiveDialog} />
      ) : activeDialog === "forgot" ? (
        <ForgotPass onChangeDialog={setActiveDialog} />
      ) :  activeDialog === "termsconditions" ? (
        <TermsAndConditions onChangeDialog={setActiveDialog} />
      ) : (
        <Landing onChangeDialog={setActiveDialog} />
      )}
    </>
  );
};

export { Login };
