import { useState, useLayoutEffect, useEffect } from "react";
import { createResource } from "../services/farmApi";
import { useTranslation } from "react-i18next";
import { useUser, useEnv } from "../contexts";

import {
  useEnaDisableStyle,
  useErrorParser,
  useEvents,
  useHandleSubmit,
} from "../hooks";

import {
  TextInput,
  InputLabel,
  SelectInput,
  NumberInput,
  Footer,
} from "../components";


export const MilkingForm = (props) => {
  // for language translation
  const { t } = useTranslation();

  const { user } = useUser(); // Get currently loggedIn user
  const { envState, setEnvState } = useEnv(); // Get Environment context
  const { publish } = useEvents(); // publish - dispatches events
  const { handleSubmit } = useHandleSubmit(); // Shared submit handler
  const today = new Date().toISOString().split("T")[0]; // Todays' date



    // --------------------- start deciding how to handle queued record if any -------------------

    const [queuedRecord, setFarmRecord] = useState(null);
    const [initFormState, setInitFormState] = useState(null);
  
    useEffect(() => {
        // Retrieve record from local storage using the special key if was a failed queue
        // const queuedFarmRecord = localStorage.getItem('queuedFarmRecord');
        const queuedFarmRecord = localStorage.getItem(`queuedFarmRecord_${user.id}`);
        
        if (queuedFarmRecord) {
            const parsedFarmRecord = JSON.parse(queuedFarmRecord);
            setFarmRecord(parsedFarmRecord);
  
            // Delete the special key and its corresponding data from local storage
            // localStorage.removeItem('queuedFarmRecord');
            localStorage.removeItem(`queuedFarmRecord_${user.id}`);
            
        }
    }, []);

    useEffect(() => {
      if (queuedRecord) {
        // If queueRecord exists, initialize initFormState with its data
        setInitFormState({ ...queuedRecord, user_id: user.id });
      } else if (props?.milking) {
        // If props.milking exists, initialize initFormState with its data
        setInitFormState({
          cattle_id: props.milking.cattle_id,
          date: props.milking.date,
          session_type: props.milking.session_type,
          volume: props.milking.volume,
          bulk_single: props.milking.bulk_single,
          user_id: user.id,
        });
      } else  {
        // If neither queueRecord nor props.milking exists, initialize initFormState with default values
        const defaultInitFormState = {
          cattle_id: "",
          date: today,
          session_type: "AM",
          volume: "",
          bulk_single: "Single",
          user_id: user.id,
        };
        setInitFormState(defaultInitFormState);
      } 
    }, [queuedRecord, props?.milking, user.id, today]);
    
    // ensuring initFormState is initialized before rendering a page
    useEffect(() => {
          if (initFormState !== null) {
            setFormState(initFormState);
          }
    }, [initFormState]);
  
    const resetFormState = () => { 
      setFormState(initFormState);
      setFarmRecord(null);
    }
  
    const [formState, setFormState] = useState(initFormState);
  
    // --------------------- end deciding how to handle queued record if any -------------------

  const [responseErrors, setResponseErrors] = useState({});
  const [createNotice, setCreateNotice] = useState("");
  //  see if you can safely remove unused setFormMode
  const [formMode, setFormMode] = useState(props.formMode ? "edit" : "new");

  //const sessionTypes = useRef([['AM', t('milking_page.am')], ['Noon', t('milking_page.noon')], ['PM', t('milking_page.pm')]]);

  const textInputStyle = useEnaDisableStyle();
  const showFieldErrors = useErrorParser(); // Takes in array off validation errors and returns jsx.

  // Notifiy the App of this location and Header Nav requirements
  useLayoutEffect(() => {
    if (formMode === "new") {
      setEnvState((state) => ({
        ...state,
        whereAbout: t("navigation_element.nav_item_title_milking"),
        backPath: "",
        homePath: "/fe",
      }));
    } else if (formMode === "edit") {
      setEnvState((state) => ({
        ...state,
        whereAbout: t("navigation_element.nav_item_title_edit_milking"),
        backPath: "/fe/reports",
        homePath: "/fe", 
      }));
    }

    return () => {
      setEnvState((state) => ({
        ...state,
        whereAbout: "",
        backPath: "",
        homePath: "",
      }));
    };
    // eslint-disable-next-line
  }, [formMode]);

  // I want to handle Bulk seeded cattle in this selected. this is a new version that handles Bulk option
  const handleChange = (inputKey, inputValue) => {
    if (inputKey === 'bulk_single') {
      if (inputValue === 'Bulk') {

        setFormState({ ...formState, [inputKey]: inputValue, cattle_id: 'Bulkcattle' });

      } else if (inputValue === 'Single') {
        
        setFormState({ ...formState, [inputKey]: inputValue, cattle_id: '' });

      }
    } else {
      
      setFormState({ ...formState, [inputKey]: inputValue });

    }
  };
  

  if (initFormState === null) {
    return <div>{t("error_message.loading")}</div>; // or any loading indicator
  }

  return (
    <div
      className={
        props.formMode !== "edit"
          ? "mt-[100px] mb-[100px]"
          : "mt-[30px] mb-[100px]"
      }
    >
      <div className="md:grid md:grid-cols-2 md:gap-6">
        <div className="mt-5 md:col-span-2 md:mt-0">
          <form
            onSubmit={(e) => {
              // Experimental: Tagging how a form gets submitted
              const clonedState = { ...formState };
              clonedState.post_channel = envState.online ? 0 : 1;

              handleSubmit(
                e,
                clonedState,
                createResource,
                envState.online,
                process.env.REACT_APP_MILKINGS,
                setResponseErrors,
                setCreateNotice,
                resetFormState,
                publish
              );
            }}
            
            autoComplete="off"
          >
            <div className="overflow-hidden sm:rounded-md">
              <div
                className={
                  props.formMode !== "edit"
                    ? "bg-white px-4 py-5 sm:p-6"
                    : "bg-white px-0 py-5 sm:p-2"
                }
              >
                <div
                  className={
                    props.formMode !== "edit"
                      ? "grid grid-cols-6 gap-6"
                      : "grid grid-cols-6 gap-6 px-4 md:px-0"
                  }
                >
                {/** Select Bulk or Single data capture */}
                {props.formMode !== "edit" && (  
                  <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                    <InputLabel
                      inputId="bulk_single_label"
                      inputStyle="block text-sm font-medium text-gray-700"
                      labelText={t("navigation_element.nav_item_title_select_bulk_single")} 
                    />
                    <SelectInput
                      focus={false}
                      inputName="bulk_single"
                      inputId="bulk_single"
                      inputStyle={textInputStyle.current.enabledInput}
                      inputValue={formState?.bulk_single || 'Single'}
                      handleChange={handleChange}
                      choices={[
                        { key: "Single", label: t("navigation_element.nav_item_title_single") }, 
                        { key: "Bulk", label: t("navigation_element.nav_item_title_bulk")  }, 
                      ]}
                    />
                  </div>
                )}

                  {/** // I want to handle Bulk seeded cattle in this selected. this is a new version that handles Bulk option */}
                  {/* Cattle Id field - text*/}
                  {formState?.bulk_single === 'Single' && props.formMode !== "edit" && (
                    <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                      <InputLabel
                        inputId="cattle_id"
                        inputStyle="block text-sm font-medium text-gray-700"
                        labelText={t("page_elements.tag")}
                      />
                      <TextInput
                        focus={true}
                        inputType="text"
                        inputName="cattle_id"
                        inputId="cattle_id"
                        hint={t("milking_page.enter_cattle_tag")}
                        inputPattern="^[a-zA-Z0-9]{1,10}$"
                        isAutoComplete="on"
                        inputStyle={textInputStyle.current.enabledInput}
                        inputValue={formState?.cattle_id || ''}
                        handleChange={handleChange}
                      />
                      {showFieldErrors(responseErrors.cattle_id)}
                    </div>
                  )}


                  {/* Always render the Cattle Id field - text if in edit mode */}
                  {props.formMode === "edit" && (
                  <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                    <InputLabel
                      inputId="cattle_id"
                      inputStyle="block text-sm font-medium text-gray-700"
                      labelText={t("page_elements.tag")}
                    />
                    <TextInput
                      focus={true}
                      inputType="text"
                      inputName="cattle_id"
                      inputId="cattle_id"
                      hint={t("milking_page.enter_cattle_tag")}
                      inputPattern="^[a-zA-Z0-9]{1,10}$"
                      isAutoComplete="on"
                      inputStyle={textInputStyle.current.enabledInput}
                      inputValue={formState?.cattle_id || ''}
                      handleChange={handleChange}
                    />
                    {showFieldErrors(responseErrors.cattle_id)}
                  </div>
                )}               

                  {/* Session Date - date */}
                  <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                    <InputLabel
                      inputId="date"
                      inputStyle="block text-sm font-medium text-gray-700"
                      labelText={t("milking_page.milking_date")}
                    />
                    <TextInput
                      focus={false}
                      inputType="date"
                      inputName="date"
                      inputId="date"
                      inputStyle={textInputStyle.current.enabledInput}
                      inputValue={formState?.date}
                      handleChange={handleChange}
                    />
                    {showFieldErrors(responseErrors.date)}
                  </div>
                  {/* Session Period type - select*/}
                  <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                    <InputLabel
                      inputId="session_type"
                      inputStyle="block text-sm font-medium text-gray-700"
                      labelText={t("milking_page.milking_period")}
                    />
                    <SelectInput
                      focus={false}
                      inputName="session_type"
                      inputId="session_type"
                      inputStyle={textInputStyle.current.enabledInput}
                      inputValue={formState?.session_type}
                      handleChange={handleChange}
                      choices={[
                        { key: "AM", label: t("milking_page.am") },
                        { key: "Noon", label: t("milking_page.noon") },
                        { key: "PM", label: t("milking_page.pm") },
                      ]}
                    />
                    {showFieldErrors(responseErrors.session_type)}
                  </div>
                  {/* Milk Volume - Number */}
                  <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                    <InputLabel
                      inputId="volume"
                      inputStyle="block text-sm font-medium text-gray-700"
                      labelText={t("milking_page.milking_volume_litre")}
                    />
                    <NumberInput
                      focus={false}
                      inputName="volume"
                      inputId="volume"
                      hint="0"
                      inputStyle={textInputStyle.current.enabledInput}
                      inputValue={formState?.volume}
                      inputStep="0.01"
                      handleChange={setFormState}
                    />
                    {showFieldErrors(responseErrors.volume)}
                  </div>
                </div>
              </div>
              <Footer
                respNotice={createNotice}
                setRespNotice={setCreateNotice}
                footerType={formMode === "edit" ? "edit" : "create"}
                onCancel={props.onCancel}
                onEdit={props.onEdit}
                editableRecord={formState}
                editableRecordId={props.milking?.id}
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
